import React, { useEffect } from 'react'
import { RoutePermissionProps } from '../containers/RoutePermission'
import { Permissions } from '../../UserLogged/permissions'
import { Route } from 'react-router-dom'
import { useHistory } from 'react-router'
import { sendToastNotificationError } from '../../../utils/notifications'

type RoutePermissionComponentProps = RoutePermissionProps & {
  permissions: Permissions[]
}

const RoutePermission = (props: RoutePermissionComponentProps) => {
  const { permission, permissions, ...routeProps } = props
  const history = useHistory()

  useEffect(() => {
    if (!permissions.includes(permission)) {
      history.replace('/')
      sendToastNotificationError('Usuario sin permisos para acceder a esta página')
    }
  }, [permission])

  if (!permissions.includes(permission)) {
    return null
  }

  return <Route {...routeProps} />
}

export default RoutePermission
