export enum NavIconsEnum {
  WRENCH = 'icon-wrench',
  SOCIAL_DROPBOX = 'icon-social-dropbox',
  GRID = 'icon-grid',
  FLAG = 'icon-flag',
  LIST = 'icon-list',
  CALENDAR = 'icon-calendar',
  USER = 'icon-user',
  ENVELOPE = 'icon-envelope',
  PHONE = 'icon-phone',
  SPEECH = 'icon-speech',
  EARPHONES_ALT = 'icon-earphones-alt',
  MAGNET = 'icon-magnet',
  DIAMOND = 'icon-diamond',
  HOURGLASS = 'icon-hourglass',
  NOTE = 'icon-note',
  CAMERA = 'icon-camera',
  CALCULATOR = 'icon-calculator',
  PLANE = 'icon-plane',
  LOCATION_PIN = 'icon-location-pin',
  BOOK_OPEN = 'icon-book-open',
  WALLET = 'icon-wallet',
  DOCS = 'icon-docs',
  TAG = 'icon-tag',
  NOTEBOOK = 'icon-notebook',
  MENU = 'icon-menu',
  GAME_CONTROLLER = 'icon-game-controller',
  CHART = 'icon-chart',
  DIRECTION = 'icon-direction'
}
