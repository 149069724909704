import { apiPrivate } from '@/api'
import { URL_BASE_OBJECTS } from '@/endpoints'
import snakecaseKeys from 'snakecase-keys'

interface Params {
  objectId: number
  description: string
  deletedReasonId: number
}

export function deleteIt({ objectId, ...params }: Params): Promise<void> {
  const formattedParams = snakecaseKeys(params, { deep: true })

  return apiPrivate
    .delete(`${URL_BASE_OBJECTS}/assets/${objectId}`, { data: { ...formattedParams } })
    .then(() => {})
    .catch((err) => {
      throw err
    })
}
