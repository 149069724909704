import { Moment } from 'moment'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import {
  setToastErrorUpdate,
  setToastLoading,
  setToastSuccessUpdate,
  sendToastNotificationError
} from '../../../utils/notifications'

import { searchFiltersToParams, dateRangeFiltersToParams, Pagination } from '../../../utils/searchFilterUtils'
import {
  BilingsTableDateRangeFilter,
  BillingsSearchFilter,
  CLOSE_DEBT_MODAL,
  CloseDebtModal,
  BillingsTableCategoryFilter,
  GET_BILLING_DATA_FAILURE,
  GET_BILLING_DATA_REQUEST,
  GET_BILLING_DATA_SUCCESS,
  GET_METRICS_FAILURE,
  GET_METRICS_REQUEST,
  GET_METRICS_SUCCESS,
  GetMetricsRequest,
  GetMetricsSuccess,
  GET_RETRY_CHARGE_DAYS_REQUEST,
  GET_RETRY_CHARGE_DAYS_SUCCESS,
  GetAuthorizationAfipRequest,
  GetAuthorizationAfipSuccess,
  GetBillingRequest,
  GetBillingSuccess,
  GetRetryChargeDaysRequest,
  GetRetryChargeDaysSuccess,
  OPEN_DEBT_MODAL,
  OpenDebtModal,
  RECALCULATE_BILLING_FAILURE,
  RECALCULATE_BILLING_REQUEST,
  RECALCULATE_BILLING_SUCCESS,
  RecalculateBillingRequest,
  RecalculateBillingSuccess,
  SEND_DEBT_REMINDER_FAILURE,
  SEND_DEBT_REMINDER_REQUEST,
  SEND_DEBT_REMINDER_SUCCESS,
  SendDebtReminderRequest,
  SendDebtReminderSuccess,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS,
  REFRESH_BILLING_FAILURE,
  REFRESH_BILLING_REQUEST,
  REFRESH_BILLING_SUCCESS,
  RefreshBillingRequest,
  RefreshBillingSuccess,
  GET_AUTHORIZATION_AFIP_REQUEST,
  GET_AUTHORIZATION_AFIP_SUCCESS,
  GET_AUTHORIZATION_AFIP_FAILURE,
  GetCreditNoteRequest,
  GetCreditNoteSuccess,
  GET_CREDIT_NOTE_REQUEST,
  GET_CREDIT_NOTE_SUCCESS,
  GET_CREDIT_NOTE_FAILURE,
  GetBillingByClientSuccess,
  GET_BILLING_BY_CLIENT_SUCCESS,
  GET_BILLING_BY_CLIENT_FAILURE,
  GET_BILLING_BY_CLIENT_REQUEST,
  GetBillingByClientRequest,
  GetBillingWarningsTypes,
  GetBillingWarningsRequest,
  GetBillingWarningsSuccess,
  ExportPaymentsTypes,
  OpenModalTxtImport,
  OPEN_MODAL_TXT_IMPORT
} from '../types/newBillingTable'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { Flag } from '../../../projectApi/ObjectAdministration/Value/getValue'
import { Flag as FlagOfList } from '../../../projectApi/ObjectAdministration/Config/flags'
import { BillingData } from '../../../projectApi/TransactionHandler/BillingData/list'
import Emitter from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'

const NewBillingTableActionCreator = {
  refreshBillingData:
    (billingId: number, periodFrom: Moment, periodTo: Moment): AppThunk =>
    async (dispatch) => {
      const request: RefreshBillingRequest = {
        type: REFRESH_BILLING_REQUEST,
        payload: {
          billingId
        }
      }

      dispatch(request)

      try {
        await API.TransactionHandler.BillingData.update({
          billingId
        })
        const { billings } = await API.TransactionHandler.BillingData.list({
          id: billingId,
          periodFrom,
          periodTo
        })
        const success: RefreshBillingSuccess = {
          type: REFRESH_BILLING_SUCCESS,
          payload: {
            billing: billings[0]
          }
        }
        dispatch(success)
      } catch (err) {
        sendToastNotificationError('Hubo un error al refrescar el abono.')
        handleErrorsWithAction(err, REFRESH_BILLING_FAILURE, dispatch, { billingId })
      }
    },
  recalculateBilling:
    (params: { userId: number; billingStart: Moment; countryCode: CountryIdCode }): AppThunk =>
    async (dispatch) => {
      const request: RecalculateBillingRequest = {
        type: RECALCULATE_BILLING_REQUEST
      }
      dispatch(request)

      const toastId = setToastLoading('Recalculando abono...')

      try {
        await API.TransactionHandler.Billings.Recalculate.manual({ ...params })

        const success: RecalculateBillingSuccess = {
          type: RECALCULATE_BILLING_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Se recalculó correctamente el abono' })
        return true
      } catch (error) {
        setToastErrorUpdate(toastId, { render: handleErrorsWithAction(error, RECALCULATE_BILLING_FAILURE, dispatch) })
        return false
      }
    },

  authorizationAfip:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: GetAuthorizationAfipRequest = {
        type: GET_AUTHORIZATION_AFIP_REQUEST
      }
      dispatch(request)

      const toastId = setToastLoading('Facturando abono...')

      try {
        await API.TransactionHandler.Billings.authorizationAfip({
          billingId
        })

        const success: GetAuthorizationAfipSuccess = {
          type: GET_AUTHORIZATION_AFIP_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Se facturó correctamente el abono' })
        return true
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, GET_AUTHORIZATION_AFIP_FAILURE, dispatch)
        })
        return false
      }
    },
  getBillingData:
    ({
      pagination,
      searchFilters,
      dateRangeFilters,
      categoryFilter,
      warningId
    }: {
      pagination: Pagination
      searchFilters?: BillingsSearchFilter[]
      dateRangeFilters?: BilingsTableDateRangeFilter[]
      categoryFilter?: BillingsTableCategoryFilter
      warningId?: number
    }): AppThunk =>
    async (dispatch) => {
      const request: GetBillingRequest = {
        type: GET_BILLING_DATA_REQUEST,
        payload: {
          categoryFilter,
          dateRangeFilters,
          searchFilters,
          warningId,
          pageSize: pagination.pageSize,
          newPage: pagination.page
        }
      }

      dispatch(request)

      try {
        const { billings, total } = await API.TransactionHandler.BillingData.list({
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          warningId,
          status: categoryFilter?.status[0],
          excludedUsers: categoryFilter?.excludedUsers[0],
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })
        const success: GetBillingSuccess = {
          type: GET_BILLING_DATA_SUCCESS,
          payload: {
            billings,
            total
          }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_BILLING_DATA_FAILURE, dispatch)
      }
    },
  getRetryChargeDays: (): AppThunk => async (dispatch) => {
    const request: GetRetryChargeDaysRequest = {
      type: GET_RETRY_CHARGE_DAYS_REQUEST
    }
    dispatch(request)

    try {
      const value = await API.ObjectAdministration.Value.getValue({ flag: Flag.RETRY_CHARGE_DAYS })
      const result = value.split(',').pop()

      const success: GetRetryChargeDaysSuccess = {
        type: GET_RETRY_CHARGE_DAYS_SUCCESS,
        payload: { retryChargeDays: Number(result) }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, RECALCULATE_BILLING_FAILURE, dispatch)
    }
  },
  openDebtModal: (billingData: BillingData): OpenDebtModal => ({
    type: OPEN_DEBT_MODAL,
    payload: { billingData }
  }),
  closeDebtModal: (): CloseDebtModal => ({
    type: CLOSE_DEBT_MODAL
  }),
  SendDebtReminder:
    (billingId: number): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
      const request: SendDebtReminderRequest = {
        type: SEND_DEBT_REMINDER_REQUEST
      }
      const toastId = setToastLoading('Enviando recordatorio de deuda...')

      dispatch(request)

      try {
        await API.TransactionHandler.Debts.sendReminder(billingId)

        const success: SendDebtReminderSuccess = {
          type: SEND_DEBT_REMINDER_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Se envió correctamente.' })
        return true
      } catch (error) {
        setToastErrorUpdate(toastId, { render: handleErrorsWithAction(error, SEND_DEBT_REMINDER_FAILURE, dispatch) })
        return false
      }
    },
  sendCSV:
    ({
      searchFilters,
      dateRangeFilters,
      categoryFilter,
      pagination,
      warningId
    }: {
      pagination: Pagination
      searchFilters?: BillingsSearchFilter[]
      dateRangeFilters?: BilingsTableDateRangeFilter[]
      categoryFilter?: BillingsTableCategoryFilter
      warningId?: number
    }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Solicitando reporte, por favor espere...')

      dispatch({ type: SEND_CSV_REQUEST })
      try {
        await API.TransactionHandler.BillingData.sendCSV({
          limit: pagination.total,
          offset: 0,
          warningId,
          status: categoryFilter?.status[0],
          excludedUsers: categoryFilter?.excludedUsers[0],
          ...searchFiltersToParams(searchFilters),
          ...dateRangeFiltersToParams(dateRangeFilters)
        })

        dispatch({ type: SEND_CSV_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(error, SEND_CSV_FAILURE, dispatch)
      }
    },
  getMetrics:
    (period: string): AppThunk =>
    async (dispatch) => {
      const request: GetMetricsRequest = {
        type: GET_METRICS_REQUEST
      }
      dispatch(request)
      const toastId = setToastLoading('Obteniendo métricas, por favor espere...')

      try {
        const metrics = await API.TransactionHandler.Billings.metrics(period)
        const success: GetMetricsSuccess = {
          type: GET_METRICS_SUCCESS,
          payload: { billingMetrics: metrics }
        }
        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Datos obtenidos correctamente.' })
      } catch (err) {
        setToastErrorUpdate(toastId, {
          render:
            handleErrorsWithAction(err, GET_METRICS_FAILURE, dispatch) ?? 'Error al intentar obtener las métricas.'
        })
      }
    },
  getCreditNote:
    (billingId: number): AppThunk =>
    async (dispatch) => {
      const request: GetCreditNoteRequest = {
        type: GET_CREDIT_NOTE_REQUEST
      }
      dispatch(request)
      const toastId = setToastLoading('Descargando nota de crédito...')
      try {
        const url = await API.TransactionHandler.AuthDocument.creditNote(billingId)

        const success: GetCreditNoteSuccess = {
          type: GET_CREDIT_NOTE_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: '¡Se descargó nota de crédito con éxito!' })
        Emitter.emit(Events.Billing.CREDIT_NOTE_EXIST, { pdfUrl: url })
      } catch (error) {
        setToastErrorUpdate(toastId, { render: handleErrorsWithAction(error, GET_CREDIT_NOTE_FAILURE, dispatch) })
        Emitter.emit(Events.Billing.CREDIT_NOTE_DOESNT_EXIST)
      }
    },
  getBillingByClient:
    ({
      pagination,
      userId,
      startDate,
      endDate,
      sourceTypes,
      withoutDetails,
      withoutApplication
    }: {
      pagination: Pagination
      userId: number
      startDate?: string
      endDate?: string
      sourceTypes?: string
      withoutDetails?: boolean
      withoutApplication?: boolean
    }): AppThunk =>
    async (dispatch) => {
      const request: GetBillingByClientRequest = {
        type: GET_BILLING_BY_CLIENT_REQUEST,
        payload: {
          userId,
          pageSize: pagination.pageSize,
          newPage: pagination.page
        }
      }

      dispatch(request)

      try {
        const { billings, total } = await API.TransactionHandler.Billings.list({
          userId,
          limit: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          authorizationEnabled: true,
          startDate,
          endDate,
          sourceTypes,
          withoutDetails,
          withoutApplication
        })
        const success: GetBillingByClientSuccess = {
          type: GET_BILLING_BY_CLIENT_SUCCESS,
          payload: {
            billingsByClient: billings.map((b) => ({
              ...b,
              objects: b.objects.map((obj) => ({
                ...obj,
                discount: null,
                objectApplications: null
              }))
            })),
            total
          }
        }
        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_BILLING_BY_CLIENT_FAILURE, dispatch)
      }
    },

  getBillingWarnings: ({ periodFrom }: { periodFrom?: Moment }): AppThunk => {
    return async (dispatch) => {
      const request: GetBillingWarningsRequest = {
        type: GetBillingWarningsTypes.GET_BILLING_WARNINGS_REQUEST
      }
      dispatch(request)

      try {
        const { flags } = await API.ObjectAdministration.Config.listFlags({})
        const { warnings } = await API.TransactionHandler.BillingData.warnings({
          periodFrom,
          descriptionVariables: formatFlags(flags)
        })

        const success: GetBillingWarningsSuccess = {
          type: GetBillingWarningsTypes.GET_BILLING_WARNINGS_SUCCESS,
          payload: { billingWarnings: warnings }
        }

        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Hubo un error al obtener las advertencias de los abonos.')
        handleErrorsWithAction(error, GetBillingWarningsTypes.GET_BILLING_WARNINGS_FAILURE, dispatch)
      }
    }
  },

  exportPayments:
    ({
      dateRangeFilters,
      countryCode
    }: {
      dateRangeFilters: BilingsTableDateRangeFilter[]
      countryCode: CountryIdCode
    }): AppThunk =>
    async (dispatch) => {
      const toastId = setToastLoading('Exportando pagos, por favor espere...')

      dispatch({ type: ExportPaymentsTypes.EXPORT_PAYMENTS_REQUEST })
      try {
        await API.TransactionHandler.BillingData.exportPayments({
          ...dateRangeFiltersToParams(dateRangeFilters),
          countryCode
        })

        dispatch({ type: ExportPaymentsTypes.EXPORT_PAYMENTS_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Pagos exportados`
        })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, ExportPaymentsTypes.EXPORT_PAYMENTS_FAILURE, dispatch)
        })
      }
    },
  openModalTxtImport: (IsTxtImportModalOpen: boolean): OpenModalTxtImport => ({
    type: OPEN_MODAL_TXT_IMPORT,
    payload: { IsTxtImportModalOpen }
  })
}

export default NewBillingTableActionCreator

const formatFlags = (flags: FlagOfList[]): { [key: string]: string } => {
  return flags.reduce((acc, flag) => {
    return { ...acc, [flag.name]: flag.value }
  }, {})
}
