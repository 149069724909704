import { Reducer } from 'redux'
import {
  DeleteObjectModalActions,
  DeleteObjectModalState,
  DeleteObjectTypes,
  GetDeleteObjectReasonsTypes,
  SettersDeleteObjectModalTypes
} from '../types/deleteObject'

const initialState: DeleteObjectModalState = {
  reasons: [],
  explanation: '',
  visible: false,
  deletingObject: false,
  fetchingDeleteReasons: false
}

const deleteObjectModalReducer: Reducer<DeleteObjectModalState, DeleteObjectModalActions> = (
  state = initialState,
  action
): DeleteObjectModalState => {
  switch (action.type) {
    case SettersDeleteObjectModalTypes.SET_MODAL_VISIBILITY: {
      const { visible, objectId } = action.payload

      if (!visible) return initialState
      return {
        ...state,
        visible,
        objectId
      }
    }
    case SettersDeleteObjectModalTypes.SET_REASON_ID:
      return {
        ...state,
        selectedReason: state.reasons?.find((reason) => reason.id === action.payload.reasonId) || state.selectedReason
      }
    case SettersDeleteObjectModalTypes.SET_EXPLANATION:
      return {
        ...state,
        explanation: action.payload.explanation
      }

    case GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_REQUEST:
      return {
        ...state,
        fetchingDeleteReasons: true
      }
    case GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_SUCCESS:
      return {
        ...state,
        fetchingDeleteReasons: false,
        reasons: action.payload.reasons
      }
    case GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_FAILURE:
      return {
        ...state,
        fetchingDeleteReasons: false
      }

    case DeleteObjectTypes.DELETE_OBJECT_REQUEST:
      return {
        ...state,
        deletingObject: true
      }
    case DeleteObjectTypes.DELETE_OBJECT_SUCCESS:
      return initialState
    case DeleteObjectTypes.DELETE_OBJECT_FAILURE:
      return {
        ...state,
        deletingObject: false
      }
    default:
      return state
  }
}

export default deleteObjectModalReducer
