import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { APIIdentificationCode, APIPhoto, IdentificationCode, Photo } from './common'

export type ListDetailsParams = {
  id: number
}

export type APITransactionObject = {
  RealId: number
  ObjectStatus: { Description: string }
  Product: { Description: string; RealID: number; ID: string }
  Photos: APIPhoto[]
  IdentificationCodes: APIIdentificationCode[]
  HeightInCm: number
  LengthInCm: number
  WidthInCm: number
}

interface APIResponse {
  Transaction: {
    Objects: APITransactionObject[]
  }
}

export type RemovalsDetailObject = {
  realId: number
  objectStatus: string
  product: { description: string; realId: number; guid: string }
  photos: Photo[]
  identificationCodes: IdentificationCode[]
  m3: number
}

export type Response = {
  transaction: {
    objects: RemovalsDetailObject[]
  }
}

export default function details(params: ListDetailsParams): Promise<Response> {
  const { id } = params
  return apiPrivate.get(`${URL_BASE_TRANSACTIONS}/removals/${id}`).then((response: AxiosResponse<APIResponse>) => {
    const camelResponse: Response = {
      transaction: {
        objects: camelcaseKeys(response.data.Transaction.Objects).map((obj) => ({
          objectStatus: obj.objectStatus.Description,
          realId: obj.realId,
          identificationCodes: obj.identificationCodes.map((code) => ({
            actual: code.Actual,
            code: code.Code,
            id: code.ID
          })),
          photos: obj.photos.map((photo) => ({
            id: photo.ID,
            mainPhoto: photo.MainPhoto,
            url: photo.Url
          })),
          product: {
            description: obj.product.Description,
            realId: obj.product.RealID,
            guid: obj.product.ID
          },
          m3: (obj?.heightInCm / 100) * (obj?.lengthInCm / 100) * (obj?.widthInCm / 100)
        }))
      }
    }
    return camelResponse
  })
}
