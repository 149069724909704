import { AxiosError } from 'axios'
import { apiPrivate } from '../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'

type FileBody = {
  file: File
}

export async function uploadTxt(body: FileBody): Promise<boolean> {
  const formData = new FormData()
  formData.append('txt_file', body.file)

  return apiPrivate
    .post(`${URL_BASE_TRANSACTIONS}/cbu-payments/import-txt`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((_) => true)
    .catch((err: AxiosError) => {
      throw err
    })
}
