import { Reducer } from 'redux'
import {
  BillingActions,
  BillingState,
  BillingsSearchFilter,
  BillingsTableCategoryFilter,
  BillingsTableDateRangeKey,
  CLOSE_DEBT_MODAL,
  GET_BILLING_DATA_FAILURE,
  GET_BILLING_DATA_REQUEST,
  GET_BILLING_DATA_SUCCESS,
  GET_METRICS_FAILURE,
  GET_METRICS_REQUEST,
  GET_METRICS_SUCCESS,
  GET_RETRY_CHARGE_DAYS_FAILURE,
  GET_RETRY_CHARGE_DAYS_REQUEST,
  GET_RETRY_CHARGE_DAYS_SUCCESS,
  OPEN_DEBT_MODAL,
  SEND_DEBT_REMINDER_FAILURE,
  SEND_DEBT_REMINDER_REQUEST,
  SEND_DEBT_REMINDER_SUCCESS,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS,
  REFRESH_BILLING_FAILURE,
  REFRESH_BILLING_REQUEST,
  REFRESH_BILLING_SUCCESS,
  GET_CREDIT_NOTE_REQUEST,
  GET_CREDIT_NOTE_SUCCESS,
  GET_CREDIT_NOTE_FAILURE,
  GET_BILLING_BY_CLIENT_REQUEST,
  GET_BILLING_BY_CLIENT_SUCCESS,
  GET_BILLING_BY_CLIENT_FAILURE,
  GetBillingWarningsTypes,
  ExportPaymentsTypes,
  OPEN_MODAL_TXT_IMPORT
} from '../types/newBillingTable'
import moment from 'moment'

export const CURRENT_PERIOD = moment().add(1, 'month')
export const DEFAULT_BIILING_CATEGORY_FILTERS: BillingsTableCategoryFilter = { status: [], excludedUsers: [] }
export const DEFAULT_BILLING_SEARCH_FILTERS: BillingsSearchFilter[] = [
  {
    key: 'userId',
    text: ''
  }
]

const initialState: BillingState = {
  billings: [],
  billingMetrics: null,
  billingWarnings: [],
  categoryFilter: DEFAULT_BIILING_CATEGORY_FILTERS,
  searchFilters: DEFAULT_BILLING_SEARCH_FILTERS,
  loadingRows: [],
  dateRangeFilters: [
    {
      key: BillingsTableDateRangeKey.PERIOD,
      startDate: CURRENT_PERIOD.startOf('month').clone(),
      endDate: CURRENT_PERIOD.endOf('month').clone()
    }
  ],
  loadingBillings: false,
  fetchingBillingWarnings: false,
  sendingCSV: false,
  exportingPayments: false,
  error: '',
  pagination: {
    page: 1,
    total: 0,
    pageSize: 10
  },
  retryChargeDays: 0,
  isLoadingCharge: false,
  IsDebtModalOpen: false,
  selectedBilling: null,
  isLoadingDownload: false,
  billingsByClient: [],
  clientSelected: 0,
  paginationByClient: {
    page: 1,
    total: 0,
    pageSize: 5
  },
  isLoadingClient: false,
  IsTxtImportModalOpen: false
}

const BillingByClientReducer: Reducer<BillingState, BillingActions> = (state = initialState, action): BillingState => {
  switch (action.type) {
    case GET_BILLING_DATA_REQUEST: {
      const { searchFilters, dateRangeFilters, categoryFilter, warningId } = action.payload
      return {
        ...state,
        loadingBillings: true,
        pagination: {
          ...state.pagination,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        },
        searchFilters: searchFilters || state.searchFilters,
        dateRangeFilters: dateRangeFilters || state.dateRangeFilters,
        categoryFilter: categoryFilter || state.categoryFilter,
        warningId
      }
    }
    case GET_BILLING_DATA_SUCCESS:
      return {
        ...state,
        billings: action.payload.billings,
        loadingBillings: false,
        pagination: {
          ...state.pagination,
          total: action.payload.total
        }
      }

    case GET_BILLING_DATA_FAILURE:
      return { ...state, loadingBillings: false, error: action.payload.error }
    case GET_METRICS_REQUEST:
      return {
        ...state,
        loadingBillings: true
      }
    case GET_METRICS_SUCCESS:
      return {
        ...state,
        loadingBillings: false,
        billingMetrics: action.payload.billingMetrics
      }
    case GET_METRICS_FAILURE:
      return {
        ...state,
        loadingBillings: false,
        error: action.payload.error
      }
    case GET_RETRY_CHARGE_DAYS_REQUEST:
      return {
        ...state,
        isLoadingCharge: true
      }
    case GET_RETRY_CHARGE_DAYS_SUCCESS:
      return {
        ...state,
        isLoadingCharge: false,
        retryChargeDays: action.payload.retryChargeDays
      }
    case GET_RETRY_CHARGE_DAYS_FAILURE:
      return {
        ...state,
        isLoadingCharge: false
      }
    case SEND_DEBT_REMINDER_REQUEST:
      return {
        ...state,
        isLoadingCharge: true
      }
    case SEND_DEBT_REMINDER_SUCCESS:
      return {
        ...state,
        isLoadingCharge: false,
        IsDebtModalOpen: false,
        selectedBilling: null
      }
    case SEND_DEBT_REMINDER_FAILURE:
      return {
        ...state,
        isLoadingCharge: false
      }
    case OPEN_DEBT_MODAL:
      return {
        ...state,
        IsDebtModalOpen: true,
        selectedBilling: action.payload.billingData
      }
    case CLOSE_DEBT_MODAL:
      return {
        ...state,
        IsDebtModalOpen: false,
        selectedBilling: null
      }
    case SEND_CSV_REQUEST: {
      return {
        ...state,
        sendingCSV: true
      }
    }
    case SEND_CSV_SUCCESS:
    case SEND_CSV_FAILURE: {
      return {
        ...state,
        sendingCSV: false
      }
    }
    case REFRESH_BILLING_REQUEST:
      return { ...state, loadingRows: [...state.loadingRows, action.payload.billingId] }
    case REFRESH_BILLING_SUCCESS:
      return {
        ...state,
        billings: state.billings.map((billing) =>
          billing.billingId === action.payload.billing.billingId ? action.payload.billing : billing
        ),
        loadingRows: state.loadingRows.filter((billingId) => billingId !== action.payload.billing.billingId)
      }
    case REFRESH_BILLING_FAILURE:
      return {
        ...state,
        loadingRows: state.loadingRows.filter((billingId) => billingId !== action.payload.billingId)
      }
    case GET_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        isLoadingDownload: true
      }
    case GET_CREDIT_NOTE_SUCCESS:
    case GET_CREDIT_NOTE_FAILURE:
      return {
        ...state,
        isLoadingDownload: false
      }
    case GET_BILLING_BY_CLIENT_REQUEST:
      return {
        ...state,
        isLoadingClient: true,
        clientSelected: action.payload.userId,
        paginationByClient: {
          ...state.paginationByClient,
          page: action.payload.newPage,
          pageSize: action.payload.pageSize
        }
      }
    case GET_BILLING_BY_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingClient: false,
        billingsByClient: action.payload.billingsByClient,
        paginationByClient: {
          ...state.paginationByClient,
          total: action.payload.total
        }
      }
    case GET_BILLING_BY_CLIENT_FAILURE:
      return {
        ...state,
        isLoadingClient: false,
        error: action.payload.error
      }
    case GetBillingWarningsTypes.GET_BILLING_WARNINGS_REQUEST:
      return {
        ...state,
        fetchingBillingWarnings: true
      }
    case GetBillingWarningsTypes.GET_BILLING_WARNINGS_SUCCESS:
      return {
        ...state,
        fetchingBillingWarnings: false,
        billingWarnings: action.payload.billingWarnings
      }
    case GetBillingWarningsTypes.GET_BILLING_WARNINGS_FAILURE:
      return {
        ...state,
        fetchingBillingWarnings: false
      }

    case ExportPaymentsTypes.EXPORT_PAYMENTS_REQUEST:
      return {
        ...state,
        exportingPayments: true
      }
    case ExportPaymentsTypes.EXPORT_PAYMENTS_SUCCESS:
    case ExportPaymentsTypes.EXPORT_PAYMENTS_FAILURE:
      return {
        ...state,
        exportingPayments: false
      }
    case OPEN_MODAL_TXT_IMPORT:
      return {
        ...state,
        IsTxtImportModalOpen: action.payload.IsTxtImportModalOpen
      }
    default:
      return { ...state }
  }
}
export default BillingByClientReducer
