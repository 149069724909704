import React from 'react'
import { Modal } from 'antd'
import Select from '@/components/Select'
import styles from './DeleteObject.module.scss'
import { Reason } from '@/projectApi/ObjectAdministration/ObjectDeleteReasons/search'
import { BaseOption } from '@/components/Select/Select'
import TextAreaInput from '@/components/TextAreaInput'
import { useLocalization } from '@/packages/localization'

type ReasonList = BaseOption<number>[]

export type DeleteObjectModalProps = {
  visible: boolean
  fetchingReasons: boolean
  deletingObject: boolean
  reasons: Reason[]
  selectedReason?: Reason
  explanation: string
  onCancel: () => void
  onOk: () => void
  setExplanation: (explanation: string) => void
  setSelectedReasonId: (reasonId: number) => void
}

const DeleteObjectModal: React.FC<DeleteObjectModalProps> = ({
  visible,
  fetchingReasons,
  deletingObject,
  reasons,
  selectedReason,
  explanation,
  onCancel,
  onOk,
  setExplanation,
  setSelectedReasonId
}) => {
  const { strings } = useLocalization()
  const formattedReasons: ReasonList = reasons.map((reason) => ({ label: reason.reason, value: reason.id }))
  const formattedSelectedReason = selectedReason
    ? { label: selectedReason.reason, value: selectedReason.id }
    : undefined

  const disableOkButton = !selectedReason || !explanation || deletingObject

  return (
    <Modal
      title={strings.Corporate.Objects.deleteObject.title}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText={strings.Corporate.Objects.deleteObject.confirm}
      okButtonProps={{ disabled: disableOkButton }}
      cancelButtonProps={{ disabled: deletingObject }}
      cancelText={strings.Corporate.Objects.deleteObject.cancel}>
      <div className={styles.container}>
        <div>
          <label className={styles.label}>{strings.Corporate.Objects.deleteObject.reason}:</label>
          <Select
            name="deletedReason"
            placeholder={strings.Corporate.Objects.deleteObject.reasonPlaceHolder}
            selected={formattedSelectedReason}
            onSelect={(reason) => setSelectedReasonId(reason.value)}
            options={formattedReasons}
            loading={fetchingReasons}
            disabled={deletingObject}
          />
        </div>
        <div>
          <label className={styles.label}>{strings.Corporate.Objects.deleteObject.explanation}:</label>
          <TextAreaInput
            disabled={deletingObject}
            maxLength={200}
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteObjectModal
