import { Moment } from 'moment'
import { BaseOption } from '../../../components/Select/Select'
import { GetRemovalDataActions } from './editMode'
import { TimeIntervalsByHourEntity } from '@/projectApi/BFF/gqlTypes'
import { CalendarDay } from '@/components/Calendar/calendar.types'

type InputProps<T, U> = {
  list: T[]
  selected: U
  loading: boolean
  errorMessage: string
}

export type Option = BaseOption<number>

export type Timeslot = {
  hour: number
  id: number
  minute: number
}

export type TimeInterval = TimeIntervalsByHourEntity

export interface CalendarState {
  datetime: Moment | null
  unavailableDates: InputProps<Moment, Moment | null>
  timeIntervals: InputProps<TimeInterval, TimeInterval | undefined> & { fullString?: string }
  selectedRemovalDate: CalendarDay | null
}

export const GET_UNAVAILABLE_DATES_REQUEST = 'REMOVALS/EDIT_REMOVAL/GET_UNAVAILABLE_DATES_REQUEST'
export const GET_UNAVAILABLE_DATES_SUCCESS = 'REMOVALS/EDIT_REMOVAL/GET_UNAVAILABLE_DATES_SUCCESS'
export const GET_UNAVAILABLE_DATES_FAILURE = 'REMOVALS/EDIT_REMOVAL/GET_UNAVAILABLE_DATES_FAILURE'

export interface GetUnavailableDatesRequest {
  type: typeof GET_UNAVAILABLE_DATES_REQUEST
}

export interface GetUnavailableDatesSuccess {
  type: typeof GET_UNAVAILABLE_DATES_SUCCESS
  payload: { unavailableDates: Moment[] }
}

export interface GetUnavailableDatesFailure {
  type: typeof GET_UNAVAILABLE_DATES_FAILURE
}

export type GetUnavailableDatesActions =
  | GetUnavailableDatesRequest
  | GetUnavailableDatesSuccess
  | GetUnavailableDatesFailure

export const SET_AVAILABLE_DATE = 'REMOVALS/EDIT_REMOVAL/SET_AVAILABLE_DATE'
export const SET_TIMESLOT = 'REMOVALS/EDIT_REMOVAL/SET_TIMESLOT'
export const SET_TIME_INTERVAL = 'REMOVALS/EDIT_REMOVAL/SET_TIMEINTERVAL'
export const SET_TIME_INTERVAL_LIST = 'REMOVALS/EDIT_REMOVAL/SET_TIME_INTERVAL_LIST'
export const SET_CALENDAR_COLLECT_MODE = 'REMOVALS/EDIT_REMOVAL/SET_CALENDAR_COLLECT_MODE'
export const SET_SELECTED_REMOVAL_DATE = 'REMOVALS/EDIT_REMOVAL/SET_SELECTED_REMOVAL_DATE'

export interface SetAvailableDate {
  type: typeof SET_AVAILABLE_DATE
  payload: { availableDate: Moment }
}

export interface SetTimeslot {
  type: typeof SET_TIMESLOT
  payload: { timeslot: number }
}

export interface SetTimeInterval {
  type: typeof SET_TIME_INTERVAL
  payload: { timeInterval?: TimeInterval }
}

export interface SetTimeIntervalList {
  type: typeof SET_TIME_INTERVAL_LIST
  payload: { timeIntervalList: TimeInterval[] }
}

export interface SetSelectedRemovalDate {
  type: typeof SET_SELECTED_REMOVAL_DATE
  payload: { selectedRemovalDate: CalendarDay | null }
}

export type SetTimesActions =
  | SetAvailableDate
  | SetTimeslot
  | SetTimeInterval
  | SetTimeIntervalList
  | SetSelectedRemovalDate

export type CalendarActions = GetUnavailableDatesActions | SetTimesActions | GetRemovalDataActions
