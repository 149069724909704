import { Moment } from 'moment'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { formatDates } from '../../utils'

interface MetricsParams {
  dateFrom?: Moment
  dateTo?: Moment
}

type APIResponse = {
  total: number
  m3_full_removals: number
  m3_returned: number
  quantity_programmed_removals: number
  quantity_programmed_m_removals: number
  quantity_returned_removals: number
  quantity_authorized_removals: number
  quantity_in_trip_removals: number
}

type Response = {
  returnedQuantity: number
  programmedQuantity: number
  authorizedQuantity: number
  onTripQuantity: number
  total: number
}

export function metrics({ dateFrom, dateTo }: MetricsParams): Promise<Response> {
  const params = {
    ...formatDates({
      dates: {
        date_from: dateFrom,
        date_to: dateTo
      }
    })
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/removals/metrics`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      const data = response.data
      if (!data) throw new Error('Hubo un error al obtener las metricas')
      return {
        total: data.total,
        programmedQuantity: data.quantity_programmed_m_removals + data.quantity_programmed_removals,
        authorizedQuantity: data.quantity_authorized_removals,
        onTripQuantity: data.quantity_in_trip_removals,
        returnedQuantity: data.quantity_returned_removals,
        m3FullRemovals: data.m3_full_removals,
        m3Returned: data.m3_returned
      }
    })
    .catch((err) => {
      throw err
    })
}
