import React, { useState } from 'react'
import ColorBadge from '../../../components/ColorBadge'
import styles from '../../SalesRequest/components/SalesRequestView.module.scss'
import { Donation } from '../../../projectApi/Sales/DonationRequest/common'
import notAvailableImage from '../../Objects/images/notAvailableImage.jpg'
import ImageTooltip from '../../DepositsValidation/components/ImageTooltip'
import { formatNumber } from '../../../utils/formatNumber'
import { Button, Modal } from 'antd'
import { STATUS_OPTIONS } from '../utils'
import stylesModal from '../../SalesRequest/components/SaleModal.module.scss'

export type DonationsViewProps = {
  donation: Donation | null
  error: string
  isLoading: boolean
  handleAcceptDonation: () => void
  handleRejectDonation: () => void
  handleCancel: () => void
  handleDonate: () => void
}

export const DonationsView: React.FC<DonationsViewProps> = ({
  donation,
  error,
  isLoading,
  handleAcceptDonation,
  handleRejectDonation,
  handleCancel,
  handleDonate
}) => {
  const obj = donation?.request.object
  const totalM3 = obj && (obj.lengthInCm * obj?.widthInCm * obj?.heightInCm) / 1_000_000
  const statusFound = STATUS_OPTIONS.find((sf) => sf.value === donation?.status)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [textModal, setTextModal] = useState<string>('')
  const [type, setType] = useState<'DONATE' | 'ACCEPT' | 'REJECT' | ''>('')

  const handleCloseModal = () => {
    setTextModal('')
    setIsModalVisible(false)
  }

  const handleSubmit = () => {
    if (type === 'ACCEPT') handleAcceptDonation()
    if (type === 'DONATE') handleDonate()
    if (type === 'REJECT') handleRejectDonation()
  }

  return (
    <>
      <div className={styles.container}>
        <>
          <div className={styles.requestInfo}>
            <div className={styles.info}>
              <div>
                <span style={{ fontWeight: 600 }}>ID Donación:</span> {donation?.id}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Cliente:</span> {donation?.request.user.id} -{' '}
                {donation?.request.user.name} {donation?.request.user.lastName}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Email:</span> {donation?.request.user.email}
              </div>
            </div>
            {statusFound ? (
              <ColorBadge extraStyles={{ minWidth: '151px' }} {...statusFound?.styleProps}>
                {statusFound.text}
              </ColorBadge>
            ) : (
              <div>{donation?.request.status}</div>
            )}
          </div>
          <>
            <div className={styles.rejectedContainer}>
              <div className={styles.productDescription}>
                <div className={styles.product}>
                  {!obj?.photo?.url ? (
                    <img src={notAvailableImage} />
                  ) : (
                    <div className={styles.img}>
                      <ImageTooltip
                        src={obj?.photo.url}
                        alt={''}
                        maxWidth={'50vw'}
                        maxHeight={'50vh'}
                        imgWidth={'130px'}
                        imgHeight={'130px'}
                      />
                    </div>
                  )}
                  <div className={styles.description}>
                    <p>ID: {donation?.id}</p>
                    <span style={{ fontWeight: 600 }}>{obj?.description}</span>
                    <span>
                      <b>Producto:</b> {obj?.product.name}
                    </span>
                    <span>
                      {obj?.widthInCm}x{obj?.heightInCm}x{obj?.lengthInCm}(cm) = {totalM3 && formatNumber(totalM3)}m3
                    </span>
                    <span>Coef. Multp. x{obj?.product.multiplier}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <Button onClick={handleCancel} disabled={isLoading} className={styles.modalButtonCancel}>
                Cancelar
              </Button>
              {statusFound?.value === 'RECEIVED' && (
                <Button
                  onClick={() => {
                    setTextModal('¿Estás seguro de que querés rechazar la donación?')
                    setIsModalVisible(true)
                    setType('REJECT')
                  }}
                  disabled={isLoading}
                  className={styles.modalButtonOk}>
                  Rechazar
                </Button>
              )}
              {statusFound?.value === 'RECEIVED' ? (
                <Button
                  onClick={() => {
                    setTextModal('¿Estás seguro de que querés aceptar el objeto a donar?')
                    setIsModalVisible(true)
                    setType('ACCEPT')
                  }}
                  disabled={isLoading}
                  className={styles.modalButtonOk}>
                  Aceptar
                </Button>
              ) : statusFound?.value === 'ACCEPTED' ? (
                <Button
                  onClick={() => {
                    setTextModal('¿Estás seguro de que querés hacer la donación?')
                    setIsModalVisible(true)
                    setType('DONATE')
                  }}
                  disabled={isLoading}
                  className={styles.modalButtonOk}>
                  Donar
                </Button>
              ) : null}
            </div>
          </>
        </>
      </div>
      <Modal
        width={697}
        title="Solicitud de donación"
        visible={isModalVisible}
        closable={true}
        keyboard={false}
        onOk={handleSubmit}
        onCancel={handleCloseModal}
        okButtonProps={{
          className: stylesModal.modalButtonOk,
          disabled: isLoading
        }}
        okText={'Confirmar'}
        cancelButtonProps={{
          className: stylesModal.modalButtonCancel,
          disabled: isLoading
        }}
        cancelText={'Cancelar'}
        className={stylesModal.modal}>
        <p className={stylesModal.description}>{textModal}</p>
        {error && (
          <div className={stylesModal.errorContainer}>
            <span className={stylesModal.error}>{error}</span>
          </div>
        )}
      </Modal>
    </>
  )
}
