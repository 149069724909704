import React from 'react'
import 'moment/locale/es'
import styles from './RemovalDate.module.scss'
import { Col } from 'antd'
import Select from '../../../components/Select'
import InputGroup from '../../../components/InputGroup'
import CalendarTextField from '../../../components/Calendar/CalendarTextField'
import { useGetTimeIntervalsQuery } from '@/projectApi/BFF/NewRemoval/getTimeIntervals.query.generated'
import { TimeIntervalOperationType } from '@/projectApi/BFF/gqlTypes'
import { CalendarProps } from '@/components/Calendar/calendar.types'
import { TimeInterval } from '../types/calendar'
import CalendarActionCreators from '../actions/calendar'
import { useLocalization } from '@/packages/localization'

export interface RemovalDateProps {
  selectedTimeInterval?: TimeInterval
  calendarTextField: CalendarProps
  calendarActions: typeof CalendarActionCreators
}

const RemovalDate = ({ calendarActions, calendarTextField, selectedTimeInterval }: RemovalDateProps) => {
  const { strings } = useLocalization()
  const { setTimeInterval, setTimeIntervalList } = calendarActions

  return (
    <InputGroup className={styles.container}>
      <h2 className={styles.containerTitle}>{strings.RemovalFlow.OperationDate.title}</h2>
      <div className={styles.inputContainer}>
        <Col sm={24} md={8}>
          <p className={styles.inputTitle}>{strings.RemovalFlow.OperationDate.returnDay}</p>
          <CalendarTextField {...calendarTextField} />
        </Col>
        <ReturnTimeSelector
          setTimeInterval={setTimeInterval}
          setTimeIntervalList={setTimeIntervalList}
          selected={selectedTimeInterval}
        />
        <div className={styles.deliveryInfoContainer}>{strings.RemovalFlow.OperationDate.timeIntervalInfo}</div>
      </div>
    </InputGroup>
  )
}

export default RemovalDate

const ReturnTimeSelector = ({
  selected,
  setTimeInterval,
  setTimeIntervalList
}: {
  selected?: TimeInterval
  setTimeInterval: (timeInterval?: TimeInterval) => void
  setTimeIntervalList: (list: TimeInterval[]) => void
}) => {
  const { strings } = useLocalization()
  const { data, loading } = useGetTimeIntervalsQuery({
    onCompleted: (data) => {
      setTimeIntervalList(filteredIntervals(data.getTimeIntervalsByHour))
    }
  })
  const removalIntervals = filteredIntervals(data?.getTimeIntervalsByHour ?? [])

  const options = removalIntervals.map((interval) => ({
    value: interval.id,
    label: `${interval.start}:00 - ${interval.end}:00`
  }))

  return (
    <Col xs={24} sm={12} md={8}>
      <div className={styles.timeIntervalContainer}>
        <p className={styles.timeIntervalTitle}>{strings.RemovalFlow.OperationDate.timeInterval}</p>
      </div>

      <Select
        placeholder="Horario"
        selected={formattedSelectedInterval(selected)}
        options={options}
        loading={loading}
        onSelect={(option) => setTimeInterval(removalIntervals.find((ti) => ti.id === option.value))}
        className={styles.selectReturnIntervals}
      />
    </Col>
  )
}

const filteredIntervals = (timeIntervals: TimeInterval[]) =>
  timeIntervals.filter((interval) => interval.operationType === TimeIntervalOperationType.RemovalOperation)

const formattedSelectedInterval = (selectedInterval?: TimeInterval) => {
  if (!selectedInterval) return undefined

  return {
    value: selectedInterval?.id,
    label: `${selectedInterval?.start}:00 - ${selectedInterval?.end}:00`
  }
}
