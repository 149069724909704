import { DatePicker } from 'antd'
import Select from '../../../components/Select'
import React from 'react'
import moment from '../../../moment'
import { map } from 'ramda'
import locale from 'antd/es/date-picker/locale/es_ES'
import { CalendarState } from '../types/calendar'
import calendarActions from '../actions/calendar'
import { Moment } from 'moment'
import { Timeslot } from '../../../projectApi/Timeslot/Timeslot/timeSlot'
import { BaseOption } from '../../../components/Select/Select'
import { TimeInterval } from '../../../projectApi/Timeslot/Timeslot/timeInterval'
import { SHOW_MONTHS } from '../constants'
import styles from './DepositDate.module.scss'
import { CollapsablePanel, ProposalHeader } from '../../../components/CollapsablePanel/CollapsablePanel'

function pad(string: string | number): string {
  const str = '' + string
  const pad = '00'
  return pad.substring(0, pad.length - str.length) + str
}

export const filterTimeslots = (
  selectedDate: Moment,
  anticipatedTimeInHours: number,
  timeslots: Timeslot[]
): Timeslot[] => {
  const nowWithAnticipatedTime = moment().add(anticipatedTimeInHours, 'hours')
  return timeslots.filter((timeslot) => {
    const selectedDateWithTimeslot = moment(selectedDate).hours(timeslot.hour).minute(timeslot.minute)
    return nowWithAnticipatedTime.isBefore(selectedDateWithTimeslot)
  })
}

type SelectOption = BaseOption<number>

export const getTimeslotOptions = (timeslots: Timeslot[]): SelectOption[] => {
  return map(
    (timeslot) => ({
      value: timeslot.id,
      label: timeslot.hour + ':' + pad(timeslot.minute)
    }),
    timeslots
  )
}

const getTimeIntervalOptions = (timeIntervals: TimeInterval[]): SelectOption[] => {
  return map(
    (timeInterval) => ({
      value: timeInterval.id,
      label: timeInterval.start + ':' + pad('') + ' - ' + timeInterval.end + ':' + pad('')
    }),
    timeIntervals
  )
}

type OperationDateProps = {
  calendar: CalendarState
  calendarActions: typeof calendarActions
  anticipatedTimeInHours: number
  customDateComponent?: React.ReactNode
}

const OperationDate = ({
  calendar,
  calendarActions,
  anticipatedTimeInHours = 0,
  customDateComponent
}: OperationDateProps) => {
  const { unavailableDates, timeslots, timeIntervals } = calendar

  const filteredTimeslots = filterTimeslots(unavailableDates.selected, anticipatedTimeInHours, timeslots.list)
  const timeslotsOptions = getTimeslotOptions(filteredTimeslots)
  const selectedTimeslotOption = timeslotsOptions.find((timeslot) => timeslot.value === timeslots.selected)

  const timeIntervalsOptions = getTimeIntervalOptions(timeIntervals.list)
  const selectedTimeIntervalOption = timeIntervalsOptions.find(
    (timeInterval) => timeInterval.value === timeIntervals.selected
  )

  const disabledDate = (current: Moment | null): boolean =>
    // Date not before today or anticipated date
    (current && current < moment().add(anticipatedTimeInHours, 'hour').startOf('day')) ||
    // Date is not available
    !!unavailableDates.list.find((date) => date.isSame(current, 'day')) ||
    // Show month that can be selected
    (current || moment()) > moment().add(SHOW_MONTHS, 'month') ||
    // disabled all Sundays
    moment(current).day() === 0

  return (
    <CollapsablePanel header={<ProposalHeader title="4. Fecha y Hora de Colecta" />}>
      <div className={styles.container}>
        <div className={styles.item}>
          <div className={styles.labelWithMargin}>Día de depósito</div>
          {customDateComponent || (
            <DatePicker
              className={styles.datePicker}
              placeholder="Día de depósito"
              value={unavailableDates.selected}
              locale={locale}
              disabledDate={disabledDate}
              format="DD/MM/YYYY"
              popupStyle={{ zIndex: 10000 }}
              allowClear={false}
              onChange={(date) => {
                if (date) {
                  calendarActions.setAvailableDate(date)
                }
              }}
            />
          )}
        </div>
        <div className={styles.item}>
          <div className={styles.labelWithMargin}>Horario</div>
          <Select
            position="top"
            placeholder="Horario"
            selected={selectedTimeslotOption}
            options={timeslotsOptions}
            loading={timeslots.loading}
            onSelect={(option) => calendarActions.setTimeslot(option.value)}
          />
        </div>
        <div className={styles.item}>
          <div className={styles.labelWithMargin}>Franja Horaria</div>
          <Select
            position="top"
            placeholder="Franja Horaria"
            selected={selectedTimeIntervalOption}
            options={timeIntervalsOptions}
            loading={timeIntervals.loading}
            onSelect={(option) => calendarActions.setTimeInterval(option.value)}
            disabled={timeIntervalsOptions.length === 1}
          />
        </div>
      </div>
    </CollapsablePanel>
  )
}

export default OperationDate
