import React, { useEffect, useState } from 'react'
import { Home, Homeprops } from '../components/Home'
import { useSelector } from 'react-redux'
import { usePermissions } from '@/sections/UserLogged/hooks/usePermissions'
import { Permissions } from '@/sections/UserLogged/permissions'

export type Visit = {
  url: string
  name: string
  permission: Permissions
}
export default function HomeContainer() {
  const { role } = useSelector((state) => state.UserLogged.userProfile)
  const permissions = usePermissions()
  const [recentVisits, setRecentVisits] = useState<Visit[]>([])
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  useEffect(() => {
    const visits = localStorage.getItem('recentVisits')

    if (visits) {
      const recentVisitList = JSON.parse(visits) as Visit[]
      const listFiltered = recentVisitList.filter((visit) => permissions.includes(visit.permission))
      setRecentVisits(listFiltered)
    }

    const image = new Image()
    image.src = '/logo-complete.png'

    image.onload = () => {
      setImageSrc(image.src)
    }

    if (localStorage.getItem('token') === null) {
      window.location.assign('/login')
    }
  }, [])

  const homeProps: Homeprops = {
    role: role?.name || '',
    recentVisits,
    imageSrc
  }
  return <Home {...homeProps} />
}
