import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import RemovalsActionCreator from '../actionCreators/removalsTable'
import RemovalsTable, { RemovalsTableProps } from '../components/RemovalsTable'
import CreateRemovalModal from '../../NewRemoval/containers/FindUser'
import NewCancelModal from './Cancel'
import CreditNoteWarning from './CreditNoteWarning'
import CancelModal from '../CancelOld/containers/cancel'
import userActionsCreators from '../../NewRemoval/actions/users'
import QRModalActionCreators from '../../../components/QRModal/QRModalActions'
import newCancelActionCreators from '../actionCreators/cancel'
import editModeActionCreators from '../../NewRemoval/actions/editMode'
import editDateServiceActionCreators from '../../../common/operations/editDateService/actions/editDateService'
import {
  Pagination,
  RemovalsTableCategoryFilter,
  RemovalsTableDateRangeFilter,
  RemovalsTableDateRangeKey,
  RemovalsTableSearchFilter,
  RemovalsTableSearchFilterKey,
  RemovalsTableSearchKey,
  RemovalsTableSort,
  RemovalsTableSortKey,
  ViewTab
} from '../types/removalsTable'
import moment, { Moment } from 'moment'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  removeNullishValues,
  updateMultipleDateRangeFilters,
  updateMultipleSearchFilters
} from '../../../components/DynamicTable/queryParamsUtils'
import { Events } from '../../../utils/eventEmitter/events'
import { useEvents } from '../../../utils/eventEmitter'
import commonActions from '../../NewRemoval/actions/common'
import ModalReason from '../../NewRemoval/containers/ModalReasons'
import EditDateServiceModal from '../../../common/operations/editDateService/containers/EditDateService'
import { EnabledFormats } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import RemovalsMetricsActionCreator from '../actionCreators/metrics'
import { RemovalMetrics } from '../components/RemovalMetrics'
import { CountryIdCode } from '@/components/CountrySelector/constants/constants'
import CreateComplaintModalActions from '@/sections/Complaints/actionCreators/CreateComplaintModal'
import CreateComplaintModalContainer from '@/sections/Complaints/containers/CreateComplaintModal'
import actions from '@/components/Address/actions'

const RemovalsTableContainer = () => {
  const dispatch = useDispatch()
  const removalsActions = bindActionCreators(RemovalsActionCreator, dispatch)
  const newRemovalsUserActions = bindActionCreators(userActionsCreators, dispatch)
  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)
  const newCancelModalActions = bindActionCreators(newCancelActionCreators, dispatch)
  const editModeActions = bindActionCreators(editModeActionCreators, dispatch)
  const editDateServiceModalActions = bindActionCreators(editDateServiceActionCreators, dispatch)
  const complaintModalActions = bindActionCreators(CreateComplaintModalActions, dispatch)
  const { cleanAddress } = bindActionCreators(actions, dispatch)

  const { getMetrics } = bindActionCreators(RemovalsMetricsActionCreator, dispatch)

  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((state) => state.Removals.table)
  const removalsMetrics = useSelector((state) => state.Removals.metrics)
  const removalReasonsList = useSelector((state) => state.Removals.table.reasonsList)

  const QueryTypes = {
    page: NumberParam,
    id: StringParam,
    clientId: NumberParam,
    sortField: StringParam,
    sortDirection: StringParam,
    createdAtFrom: StringParam,
    createdAtTo: StringParam,
    transportDatetimeFrom: StringParam,
    transportDatetimeTo: StringParam,
    status: StringParam,
    origin: StringParam,
    userId: StringParam
  }

  const [query, setQuery] = useQueryParams(QueryTypes)

  const emptyPagination = { ...tableState.pagination, page: 1 }

  const handleGetRemovals = (newParams: {
    pagination?: Pagination
    sort?: RemovalsTableSort
    dateRangeFilters?: RemovalsTableDateRangeFilter[]
    categoryFilter?: RemovalsTableCategoryFilter
    searchFilters?: RemovalsTableSearchFilter[]
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      sort: newParams.sort || tableState.sort,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    }
    removalsActions.getRemovals(actualParams)

    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      sortDirection: actualParams.sort.direction,
      sortField: actualParams.sort.field,
      status: actualParams.categoryFilter.status[0],
      origin: actualParams.categoryFilter.origin.join(','),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilters, undefined, true)
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const refreshView = () => {
    handleGetRemovals({
      pagination: { ...emptyPagination, page: query.page || emptyPagination.page },
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [
        { key: RemovalsTableSearchKey.ID, text: query.id },
        { key: RemovalsTableSearchKey.USER_ID, text: query.userId }
      ]),
      categoryFilter: {
        ...tableState.categoryFilter,
        status: query.status ? [query.status] : tableState.categoryFilter.status,
        origin: query.origin ? query.origin.split(',') : tableState.categoryFilter.origin
      },
      sort:
        query.sortField && query.sortDirection
          ? {
              ...tableState.sort,
              field: query.sortField as RemovalsTableSortKey,
              direction: query.sortDirection as SortDirection
            }
          : tableState.sort,
      dateRangeFilters: updateMultipleDateRangeFilters(tableState.dateRangeFilters, [
        {
          key: RemovalsTableDateRangeKey.CREATED_AT,
          startDate: query.createdAtFrom,
          endDate: query.createdAtTo
        },
        {
          key: RemovalsTableDateRangeKey.TRANSPORT_DATETIME,
          startDate: query.transportDatetimeFrom,
          endDate: query.transportDatetimeTo
        }
      ])
    })
    getMetrics(query.createdAtFrom ? { dateFrom: moment(query.createdAtFrom), dateTo: moment(query.createdAtTo) } : {})
    removalsActions.getRemovalReasons()
    return () => {
      removalsActions.clearState()
    }
  }

  useEvents(
    [Events.Removal.REMOVAL_CANCELLED, Events.Operations.DATE_SERVICE_EDITED, Events.Removal.ADDRESS_CREATED],
    () => refreshView()
  )

  useEffect(() => {
    refreshView()
  }, [countryCode])

  useEffect(() => {
    dispatch(commonActions.finish())
  }, [])

  const handleUpdateAddress = ({
    userId,
    removalId,
    transportCost
  }: {
    userId: number
    removalId: number
    transportCost: number
  }) => {
    removalsActions.createAddress(userId, removalId, transportCost)
  }

  const handleGetRemovalDetails = (id: number) => {
    removalsActions.getRemovalDetails(id)
  }

  const handlePageChange = (newPage: number) => {
    handleGetRemovals({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSort = (newSort: RemovalsTableSort) => {
    handleGetRemovals({ sort: newSort })
  }

  const handleCategoryFilter = (newCategoryFilter: RemovalsTableCategoryFilter) => {
    handleGetRemovals({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleGetRemovalsMetrics = ({ newRangeFilters }: { newRangeFilters?: RemovalsTableDateRangeFilter[] }) => {
    const currentPeriod = newRangeFilters || tableState.dateRangeFilters
    const createdAt = currentPeriod.find((dateRange) => dateRange.key === 'createdAt') ?? undefined

    getMetrics({
      dateFrom: createdAt?.startDate ?? undefined,
      dateTo: createdAt?.endDate ?? undefined
    })
  }

  const handleRangePicker = (key: RemovalsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )

    handleGetRemovals({ dateRangeFilters: newRangeFilters, pagination: emptyPagination })
    if (key === 'createdAt') handleGetRemovalsMetrics({ newRangeFilters })
  }

  const handleSearchFilters = (key: RemovalsTableSearchFilterKey, newValue: string) => {
    const newSearchFilter = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: key === RemovalsTableSearchKey.IS_FULL_REMOVAL ? (filter.text === 'true' ? '' : 'true') : newValue
          }
        : filter
    )

    handleGetRemovals({ searchFilters: newSearchFilter, pagination: emptyPagination })
  }

  const handleTransportDatetime = (newValue: ViewTab | null) => {
    removalsActions.setViewButtonActive(newValue)

    if (newValue === null) return handleRangePicker(RemovalsTableDateRangeKey.TRANSPORT_DATETIME, undefined, undefined)

    const TODAY = moment()
    const TOMORROW = moment().add(1, 'days')
    if (newValue === ViewTab.TODAY) {
      handleRangePicker(RemovalsTableDateRangeKey.TRANSPORT_DATETIME, TODAY, TODAY)
    } else if (newValue === ViewTab.TOMORROW) {
      handleRangePicker(RemovalsTableDateRangeKey.TRANSPORT_DATETIME, TOMORROW, TOMORROW)
    }
  }

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetRangeFilters = tableState.dateRangeFilters.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))
    const resetSort: RemovalsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination

    handleGetRemovals({
      pagination: resetPagination,
      sort: resetSort,
      searchFilters: resetSearchFilters,
      dateRangeFilters: resetRangeFilters,
      categoryFilter: { status: [], origin: [], removalReasons: [] }
    })

    getMetrics({})
  }

  const handleSendRemovalsReport = () => {
    const actualParams = {
      pagination: tableState.pagination,
      sort: tableState.sort,
      dateRangeFilters: tableState.dateRangeFilters,
      categoryFilter: tableState.categoryFilter,
      searchFilters: tableState.searchFilters,
      format: 'csv' as EnabledFormats
    }
    removalsActions.sendReport(actualParams)
  }

  const handleOpenAddressModal = ({ userId, isOpen }: { userId?: number; isOpen: boolean }) => {
    removalsActions.setOpenModalAddress({ openModalAddress: isOpen })
    if (userId) {
      removalsActions.getAddresses(userId)
    }
  }

  const handleCleanAddress = () => {
    cleanAddress()
  }

  const paging = {
    currentPage: tableState.pagination.page,
    pageSize: tableState.pagination.limit,
    total: tableState.pagination.total,
    onPageChange: handlePageChange
  }

  const cancelRemoval = ({
    operationId,
    user,
    transportCost,
    isDraft,
    isNotCreateCreditNote
  }: {
    operationId: number
    user: { id: number; lastName: string; name: string }
    transportCost: number
    isDraft?: boolean
    isNotCreateCreditNote?: boolean
  }) => {
    if (!isDraft) {
      newCancelModalActions.openCreditNoteModal({
        isOpen: true,
        operationId,
        selectedUser: { amount: transportCost, lastName: user.lastName, name: user.name, id: user.id },
        isNotCreateCreditNote
      })
    } else {
      newCancelModalActions.openModal({ isOpen: true, operationId })
    }
  }

  const handleGetCostByAddress = (address: string, countryId: CountryIdCode) => {
    removalsActions.fetchCost(address, countryId)
  }

  const handleGetUser = (userId: number) => {
    newRemovalsUserActions.getUser(userId)
  }

  const metricsProps = {
    metrics: removalsMetrics.quantityMetrics,
    fetchingMetrics: removalsMetrics.fetchingMetrics,
    quantityClientsWithFullRemovals: tableState.quantityClientsWithFullRemovals,
    handleSearchFilters,
    searchFilters: tableState.searchFilters,
    loadingRemovals: tableState.loading
  }

  const props: RemovalsTableProps = {
    removals: tableState.removals,
    details: tableState.details,
    loading: tableState.loading,
    loadingObjects: tableState.loadingObjects,
    sendingCSV: tableState.sendingCSV,
    pagination: paging,
    sort: tableState.sort,
    dateRangeFilters: tableState.dateRangeFilters,
    activeViewButton: tableState.activeViewButton,
    categoryFilter: tableState.categoryFilter,
    searchFilters: tableState.searchFilters,
    handleGetRemovalDetails,
    handleSort,
    handleOpenAddressModal,
    handleRangePicker,
    getUser: handleGetUser,
    OpenModal: () => newRemovalsUserActions.toggleUserModalOpen(true),
    handleOpenQRCodeModal: openQRModal,
    cancelRemovalAction: ({
      operationId,
      user,
      transportCost,
      isDraft,
      isNotCreateCreditNote
    }: {
      operationId: number
      user: { id: number; lastName: string; name: string }
      transportCost: number
      isDraft?: boolean
      isNotCreateCreditNote?: boolean
    }) => cancelRemoval({ operationId, transportCost, user, isDraft, isNotCreateCreditNote }),
    handleCategoryFilter,
    handleSearchFilters,
    handleTransportDatetime,
    handleSendRemovalsReport,
    handleResetFilters,
    handleGetCostByAddress,
    newTransportCost: tableState.transportCost,
    handleUpdateAddress,

    openEditMode: ({ userId }: { userId: number }) => editModeActions.openEditMode({ active: true, userId }),
    openEditDateServiceModal: ({ operationId, datetime }: { operationId: number; datetime: string }) =>
      editDateServiceModalActions.openModal({ isOpen: true, operationId, currentDateTime: datetime }),
    openModalAddress: tableState.openModalAddress,
    openCreateComplaint: complaintModalActions.setOpen,
    handleCleanAddress,
    removalReasonsList
  }

  return (
    <>
      <RemovalMetrics {...metricsProps} />
      <RemovalsTable {...props} />
      <CreateRemovalModal />
      <CancelModal />
      <NewCancelModal />
      <ModalReason />
      <EditDateServiceModal />
      <CreditNoteWarning />
      <CreateComplaintModalContainer />
    </>
  )
}

export default RemovalsTableContainer
