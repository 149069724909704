import React from 'react'
import { Modal } from 'antd'
import UploadFiles from '../../../components/UploadFiles/container'
import { useEvents } from '../../../utils/eventEmitter'
import { UploadFilesEnum } from '../../../utils/eventEmitter/events/uploadFiles'
import { useLocalization } from '@/packages/localization'
import styles from './modalTxtImport.module.scss'

export type ModalTxtImportProps = {
  open: boolean
  loading: boolean
  onCancel: () => void
}

export const ModalTxtImportComponent: React.FC<ModalTxtImportProps> = ({ open, onCancel, loading }) => {
  useEvents(UploadFilesEnum.UPLOAD_FILE_SUCCESS, onCancel)

  const { strings } = useLocalization()

  return (
    <Modal
      title={strings.Billings.modalImportTxt.title}
      visible={open}
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{
        className: styles.cancelBtn
      }}
      okButtonProps={{
        className: styles.okBtn,
        disabled: loading
      }}
      okText={strings.generic.accept}
      cancelText={strings.generic.cancel}>
      <UploadFiles fileType="txt" />
    </Modal>
  )
}
