import moment from '../../../moment'
import { Reducer } from 'redux'
import {
  CalendarActions,
  CalendarState,
  GET_UNAVAILABLE_DATES_FAILURE,
  GET_UNAVAILABLE_DATES_REQUEST,
  GET_UNAVAILABLE_DATES_SUCCESS,
  SET_AVAILABLE_DATE,
  SET_SELECTED_REMOVAL_DATE,
  SET_TIME_INTERVAL,
  SET_TIME_INTERVAL_LIST
} from '../types/calendar'
import { GetRemovalData } from '../types/editMode'
import commonTypes from '../types/common'
import { getTimeIntervalLabel } from '../utils/calculateDaysAgo'

export const DEFAULT_ANTICIPATION_DATE = moment().add(1, 'day')

const CalendarInitialState: CalendarState = {
  datetime: null,
  selectedRemovalDate: null,
  unavailableDates: {
    list: [],
    selected: null,
    loading: false,
    errorMessage: ''
  },
  timeIntervals: {
    list: [],
    selected: undefined,
    loading: false,
    errorMessage: ''
  }
}

const CalendarReducer: Reducer<CalendarState, CalendarActions> = (
  state = CalendarInitialState,
  action
): CalendarState => {
  switch (action.type) {
    case commonTypes.FINISH:
      return CalendarInitialState
    case GetRemovalData.GET_REMOVAL_DATA_REQUEST:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: true
        },
        timeIntervals: {
          ...state.timeIntervals,
          loading: true
        }
      }
    case GetRemovalData.GET_REMOVAL_DATA_SUCCESS:
      return {
        ...state,
        datetime: moment(action.payload.operation.datetime),
        unavailableDates: {
          ...state.unavailableDates,
          selected: moment(action.payload.operation.datetime),
          loading: false
        },
        timeIntervals: {
          ...state.timeIntervals,
          fullString: action.payload.operation.transaction.timeInterval,
          loading: false
        }
      }
    case GetRemovalData.GET_REMOVAL_DATA_FAILURE:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: false
        },
        timeIntervals: {
          ...state.timeIntervals,
          loading: false
        }
      }
    case GET_UNAVAILABLE_DATES_REQUEST:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: true
        }
      }
    case GET_UNAVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          list: action.payload.unavailableDates,
          loading: false
        }
      }
    case GET_UNAVAILABLE_DATES_FAILURE: {
      return {
        ...state,
        unavailableDates: {
          ...state.unavailableDates,
          loading: false
        }
      }
    }
    case SET_AVAILABLE_DATE:
      return {
        ...state,
        datetime: action.payload.availableDate,
        unavailableDates: {
          ...state.unavailableDates,
          selected: action.payload.availableDate
        }
      }
    case SET_TIME_INTERVAL:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          selected: action.payload.timeInterval,
          fullString: getTimeIntervalLabel(action.payload.timeInterval)
        }
      }
    case SET_TIME_INTERVAL_LIST:
      return {
        ...state,
        timeIntervals: {
          ...state.timeIntervals,
          list: action.payload.timeIntervalList,
          selected: action.payload.timeIntervalList[0]
        }
      }
    case SET_SELECTED_REMOVAL_DATE:
      return {
        ...state,
        selectedRemovalDate: action.payload.selectedRemovalDate
      }
    default:
      return state
  }
}

export default CalendarReducer
