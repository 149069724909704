import { ProductCatalogue } from '@/projectApi/CategoryCreation/Catalog/searchAll'
import { BaseOption } from '../../../components/Select/Select'
import { CatalogCategory, Product } from '../../../projectApi/CategoryCreation/Product/getById'
import { Solution } from '../../../projectApi/CategoryCreation/Solutions/list'
import { LeafCategory } from '../../../projectApi/CategoryCreation/getCategories'
import { CountryOptions } from '../components/subcomponents/ProductCosts'
import { CreateNewProductActions, SetEditMode, UpdateProductAction } from './common'

export type GeneralDataState = {
  categories: LeafCategory[]
  catalogCategory: CatalogCategory | null
  solutions: Solution[]
  multiplier: string
  disassembleTime: string
  packagingTime: string
  dimensionsWeight: string
  dimensionsLength: string
  dimensionsWidth: string
  dimensionsHeight: string
  minDeviation: string
  maxDeviation: string
  minPhotos: string
  error: string
  isProductSearchable: boolean
  categorySelected: CategoryOption | null
  solutionsSelected: SolutionOption[]
  generalCountriesFiltered: CountryOptions[]
  isProductCreatedSuccessfully: boolean
  isEditMode: boolean
  guid: string
  loading: boolean
  loadingSaveProduct: boolean
  productSuggestions: string[]
  selectedProduct: string
  isLoadingCatalogProducts: boolean
  products: ProductCatalogue[]
  total: number
  openDetailModal: boolean
  productDetail: ProductCatalogue | null
  isProductDetailLoading: boolean
}

export type CategoryOption = BaseOption<number>
export type SolutionOption = BaseOption<number>

export const GET_CATEGORIES_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_CATEGORIES_FAILURE'

export interface GetCategoriesRequest {
  type: typeof GET_CATEGORIES_REQUEST
}

export interface GetCategoriesSuccess {
  type: typeof GET_CATEGORIES_SUCCESS
  payload: { categories: LeafCategory[] }
}

export interface GetCategoriesFailure {
  type: typeof GET_CATEGORIES_FAILURE
  payload: { error: string }
}

export type GetCategoriesActions = GetCategoriesRequest | GetCategoriesSuccess | GetCategoriesFailure

export const GET_SOLUTIONS_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_SOLUTION_REQUEST'
export const GET_SOLUTIONS_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_SOLUTION_SUCCESS'
export const GET_SOLUTIONS_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_SOLUTION_FAILURE'

export interface GetSolutionsRequest {
  type: typeof GET_SOLUTIONS_REQUEST
}

export interface GetSolutionsSuccess {
  type: typeof GET_SOLUTIONS_SUCCESS
  payload: {
    solutions: Solution[]
  }
}
export interface GetSolutionsFailure {
  type: typeof GET_SOLUTIONS_FAILURE
  payload: { error: string }
}

export type GetServicesActions = GetSolutionsRequest | GetSolutionsSuccess | GetSolutionsFailure

export const SET_MULTIPLIER = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_MULTIPLIER'
export const SET_DISASSEMBLE_TIME = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_DISASSEMBLE_TIME'
export const SET_PACKAGING_TIME = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_PACKAGING_TIME'
export const SET_DIMENSIONS_WEIGHT = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_DIMENSIONS_WEIGHT'
export const SET_DIMENSIONS_LENGTH = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_DIMENSIONS_LENGTH'
export const SET_DIMENSIONS_WIDTH = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_DIMENSIONS_WIDTH'
export const SET_DIMENSIONS_HEIGHT = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_DIMENSIONS_HEIGHT'
export const SET_MIN_DEVIATION = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_MIN_DEVIATION'
export const SET_MAX_DEVIATION = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_MAX_DEVIATION'
export const SET_MIN_PHOTOS = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_MIN_PHOTOS'
export const SET_IS_PRODUCT_SEARCHABLE = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_IS_PRODUCT_SEARCHABLE'
export const SET_CATEGORY_SELECTED = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_CATEGORY_SELECTED'
export const SET_SOLUTIONS_SELECTED = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_SOLUTIONS_SELECTED'

export interface SetCategorySelected {
  type: typeof SET_CATEGORY_SELECTED
  payload: { categorySelected: CategoryOption | null }
}

export interface SetSolutionSelected {
  type: typeof SET_SOLUTIONS_SELECTED
  payload: { solutionsSelected: SolutionOption[] }
}

export interface SetMultiplier {
  type: typeof SET_MULTIPLIER
  payload: { multiplier: string }
}

export interface SetDisassembleTime {
  type: typeof SET_DISASSEMBLE_TIME
  payload: { disassembleTime: string }
}

export interface SetPackagingTime {
  type: typeof SET_PACKAGING_TIME
  payload: { packagingTime: string }
}

export interface SetDimensionsWeight {
  type: typeof SET_DIMENSIONS_WEIGHT
  payload: { dimensionsWeight: string }
}

export interface SetDimensionsLength {
  type: typeof SET_DIMENSIONS_LENGTH
  payload: { dimensionsLength: string }
}

export interface SetDimensionsWidth {
  type: typeof SET_DIMENSIONS_WIDTH
  payload: { dimensionsWidth: string }
}

export interface SetDimensionsHeight {
  type: typeof SET_DIMENSIONS_HEIGHT
  payload: { dimensionsHeight: string }
}

export interface SetMinDeviation {
  type: typeof SET_MIN_DEVIATION
  payload: { minDeviation: string }
}

export interface SetMaxDeviation {
  type: typeof SET_MAX_DEVIATION
  payload: { maxDeviation: string }
}

export interface SetMinPhotos {
  type: typeof SET_MIN_PHOTOS
  payload: { minPhotos: string }
}

export interface SetIsProductSearchable {
  type: typeof SET_IS_PRODUCT_SEARCHABLE
  payload: { isProductSearchable: boolean }
}

export const GET_PRODUCT_BY_ID_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_BY_ID_REQUEST'
export const GET_PRODUCT_BY_ID_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_BY_ID_SUCCESS'
export const GET_PRODUCT_BY_ID_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_BY_ID_FAILURE'

export interface GetProductByIdRequest {
  type: typeof GET_PRODUCT_BY_ID_REQUEST
}

export interface GetProductByIdSuccess {
  type: typeof GET_PRODUCT_BY_ID_SUCCESS
  payload: { product: Product }
}

export interface GetProductByIdFailure {
  type: typeof GET_PRODUCT_BY_ID_FAILURE
  payload: { error: string }
}

export type GetProductByIdActions = GetProductByIdRequest | GetProductByIdSuccess | GetProductByIdFailure

export const GENERAL_DATA_CLEAN_STATE = 'PRODUCTS/CREATE_NEW_PRODUCT/GENERAL_DATA_CLEAN_STATE'

export interface GeneralDataCleanState {
  type: typeof GENERAL_DATA_CLEAN_STATE
}

export enum GetProductSuggestionEnum {
  GET_PRODUCT_SUGGESTIONS_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_SUGGESTIONS_REQUEST',
  GET_PRODUCT_SUGGESTIONS_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_SUGGESTIONS_SUCCESS',
  GET_PRODUCT_SUGGESTIONS_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_SUGGESTIONS_FAILURE'
}

export interface GetProductSuggestionsRequest {
  type: GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_REQUEST
}

export interface GetProductSuggestionsSuccess {
  type: GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_SUCCESS
  payload: { productSuggestions: string[] }
}

export interface GetProductSuggestionsFailure {
  type: GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_FAILURE
  payload: { error: string }
}

export const SET_PRODUCT_SUGGESTED = 'PRODUCTS/CREATE_NEW_PRODUCT/SET_PRODUCT_SUGGESTED'

export interface SetProductSeggested {
  type: typeof SET_PRODUCT_SUGGESTED
  payload: { selectedProduct: string }
}

export enum GetCatalogByProductEnum {
  GET_CATALOG_BY_PRODUCT_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_CATALOG_BY_PRODUCT_REQUEST',
  GET_CATALOG_BY_PRODUCT_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_CATALOG_BY_PRODUCT_SUCCESS',
  GET_CATALOG_BY_PRODUCT_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_CATALOG_BY_PRODUCT_FAILURE'
}

export interface GetCatalogByProductRequest {
  type: GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_REQUEST
}

export interface GetCatalogByProductSuccess {
  type: GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_SUCCESS
  payload: { products: ProductCatalogue[]; total: number }
}

export interface GetCatalogByProductFailure {
  type: GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_FAILURE
  payload: { error: string }
}

export const SET_OPEN_DETAIL_MODAL = 'PRODUCTS/CATALOG/SET_OPEN_DETAIL_MODAL'

export interface SetOpenDetailModal {
  type: typeof SET_OPEN_DETAIL_MODAL
  payload: { openDetailModal: boolean }
}

export enum GetProductByDescriptionEnum {
  GET_PRODUCT_BY_DESCRIPTION_REQUEST = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_BY_DESCRIPTION_REQUEST',
  GET_PRODUCT_BY_DESCRIPTION_SUCCESS = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_BY_DESCRIPTION_SUCCESS',
  GET_PRODUCT_BY_DESCRIPTION_FAILURE = 'PRODUCTS/CREATE_NEW_PRODUCT/GET_PRODUCT_BY_DESCRIPTION_FAILURE'
}

export interface GetProductByDescriptionRequest {
  type: GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_REQUEST
}

export interface GetProductByDescriptionSuccess {
  type: GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_SUCCESS
  payload: { product: ProductCatalogue }
}

export interface GetProductByDescriptionFailure {
  type: GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_FAILURE
  payload: { error: string }
}

export type GeneralDataAction =
  | SetMultiplier
  | SetDisassembleTime
  | SetPackagingTime
  | SetDimensionsWeight
  | SetDimensionsLength
  | SetDimensionsWidth
  | SetDimensionsHeight
  | SetMinDeviation
  | SetMaxDeviation
  | SetIsProductSearchable
  | SetSolutionSelected
  | SetCategorySelected
  | SetMinPhotos
  | GetServicesActions
  | GetCategoriesActions
  | GeneralDataCleanState
  | SetEditMode
  | GetProductByIdActions
  | CreateNewProductActions
  | UpdateProductAction
  | GetProductSuggestionsRequest
  | GetProductSuggestionsSuccess
  | GetProductSuggestionsFailure
  | SetProductSeggested
  | GetCatalogByProductRequest
  | GetCatalogByProductSuccess
  | GetCatalogByProductFailure
  | SetOpenDetailModal
  | GetProductByDescriptionRequest
  | GetProductByDescriptionSuccess
  | GetProductByDescriptionFailure
