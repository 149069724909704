import React from 'react'
import { useHistory } from 'react-router'
import PathIcon from '../../../icons/arrowToBracket'
import styles from './Home.module.scss'
import ChartIcon from '@/icons/chartIcon'
import { RecientVisitNameEnum, UsersRolesEnum } from '../types'
import { historyIconManager } from '../utils'
import { truncateText } from '@/utils/truncateText'
import { Tooltip } from 'antd'
import { Visit } from '../container/Home'
import { DepositIcon } from '@/icons'
import { RecientVisitsData } from '../constants'
import { Link } from 'react-router-dom'

export interface Homeprops {
  role: string
  imageSrc: string | null
  recentVisits: Visit[]
}

export const Home: React.FC<Homeprops> = ({ role, imageSrc, recentVisits }) => {
  return (
    <>
      <div className="animated fadeIn">
        <div className={styles.container}>
          <div>
            <img src={imageSrc || ''} alt="logo" className={styles.image} />
            <h1 className={styles.title}>Bienvenido al Dashboard</h1>
          </div>

          <div>
            <h2 className={styles.visitTitle} style={{ textAlign: role !== UsersRolesEnum.ROOT ? 'center' : 'start' }}>
              Vistas recientes
            </h2>
            <div className={styles.shortcutsContainer}>
              <div className={styles.visitContent}>
                {recentVisits.length ? (
                  <div className={`${styles.cardContainer} fadeInUp`}>
                    {recentVisits.map((visit: Visit) => (
                      <Card title={visit.name} path={visit.url} key={visit.url} />
                    ))}
                  </div>
                ) : (
                  <div className={styles.createShortcut}>
                    <p>No hay visitas recientes</p>
                  </div>
                )}
              </div>
              {role === UsersRolesEnum.ROOT && (
                <div className={styles.metricsSectionBox}>
                  <div className={styles.divider}></div>
                  <Card title="Métricas" path="Ir a Métricas" isMetric />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface CardProps {
  title: string
  path: string
  isMetric?: boolean
}

const Card: React.FC<CardProps> = ({ title, path, isMetric }) => {
  const history = useHistory()

  const renderIconBasedOnContext = () => {
    if (isMetric) {
      return <ChartIcon />
    }
    if (title === RecientVisitNameEnum.STORAGE_DEPOSITS_TABLE) {
      return <DepositIcon stroke="grey" />
    }
    if (
      title === RecientVisitNameEnum.VEHICLES_TABLE ||
      title === RecientVisitNameEnum.PROVIDERS_TABLE ||
      title === RecientVisitNameEnum.SHIPPERS_TABLE
    ) {
      const TruckIcon = RecientVisitsData.vehicles.icon
      return <TruckIcon className={styles.truckIcon} />
    }
    return <i className={historyIconManager(title)} />
  }

  return (
    <Link
      to={isMetric ? '/dashboard' : path}
      className={isMetric ? styles.metricCardcontainer : styles.eachCardcontainer}>
      <div className={isMetric ? styles.metricCard : styles.card}>
        <div className={styles.content}>
          {renderIconBasedOnContext()}
          <Tooltip title={title.length > 20 ? title : ''}>
            <h6 className={styles.currentTitle}>{truncateText(title, 20)}</h6>
          </Tooltip>
          <div className={styles.hoverContent}>
            <div className={styles.pathIcon}>
              <PathIcon />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
