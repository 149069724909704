import { apiPrivate } from '@/api'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'

type UpdateParams = {
  addressId: number
  operationId: number
  transportCost: number
}

export function update({ operationId, addressId, transportCost }: UpdateParams): Promise<void> {
  const parsedParams = {
    address_id: addressId,
    transport_cost: transportCost
  }

  return apiPrivate
    .put(`${URL_BASE_TRANSACTIONS}/operations/address-service/${operationId}`, parsedParams)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}
