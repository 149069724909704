import { Tooltip } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect } from 'react'
import ColorBadge from '../../../components/ColorBadge'
import { CopyButton } from '../../../components/CopyButton'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import {
  getFilterProps,
  getRangePickerProps,
  getSearchProps,
  getSortProps
} from '../../../components/DynamicTable/utils'
import ButtonGroup, { Tab } from '../../../components/ButtonGroup'
import {
  Camera,
  CubeCompass,
  CubeLocation,
  CubeReturn,
  Edit,
  FastTruck,
  History,
  List,
  QRIcon,
  Trash,
  Truck
} from '../../../icons'
import { RemovalsDetailObject } from '../../../projectApi/TransactionHandler/Operation/Removals/details'
import { OperationStatus } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import { formatNumber } from '../../../utils/formatNumber'
import { OBJECT_STATUS_OPTIONS, STATUS_FILTERS } from '../constants'
import {
  RemovalsTableCategoryFilter,
  RemovalsTableCategoryFilterKey,
  RemovalsTableDateRangeFilter,
  RemovalsTableDateRangeKey,
  RemovalsTableSearchFilter,
  RemovalsTableSearchFilterKey,
  RemovalsTableSort,
  ViewTab
} from '../types/removalsTable'
import { getIdentificationCode } from '../utils'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { formatDate } from '../../../utils/formatDate'
import styles from './RemovalsTable.module.scss'
import { useHistory } from 'react-router'
import { Removal } from '../../../projectApi/TransactionHandler/Operation/Removals/common'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'
import { Permissions } from '../../UserLogged/permissions'
import { REMOVAL_ORIGIN_FILTERS } from '../../../common/operationsTable/constants'
import { TableActionBar } from '../../../components/TableActionBar'
import { ChangeAddressModal } from './ChangeAddressModal'
import { CountryIdCode } from '@/components/CountrySelector/constants/constants'
import ComplaintIcon from '@/icons/complaint'
import { ComplaintType } from '@/sections/Complaints/types/createComplaintModal'
import { RemovalReason } from '@/projectApi/TransactionHandler/Operation/Removals/reasons'

export type RemovalsTableProps = {
  removals: Removal[]
  details: RemovalsDetailObject[]
  loading: boolean
  loadingObjects: boolean
  sendingCSV: boolean
  pagination: Pagination
  sort: RemovalsTableSort
  dateRangeFilters: RemovalsTableDateRangeFilter[]
  activeViewButton: ViewTab | null
  categoryFilter: RemovalsTableCategoryFilter
  searchFilters: RemovalsTableSearchFilter[]
  handleSearchFilters: (key: RemovalsTableSearchFilterKey, text: string) => void
  handleCategoryFilter: (newCategoryFilter: RemovalsTableCategoryFilter) => void
  getUser: (userId: number) => void
  cancelRemovalAction: ({
    operationId,
    user,
    transportCost,
    isDraft,
    isNotCreateCreditNote
  }: {
    operationId: number
    user: { id: number; lastName: string; name: string }
    transportCost: number
    isDraft?: boolean
    isNotCreateCreditNote?: boolean
  }) => void
  handleOpenAddressModal: ({ userId, isOpen }: { userId?: number; isOpen: boolean }) => void
  handleRangePicker: (key: RemovalsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => void
  handleSort: (newSort: RemovalsTableSort) => void
  OpenModal: () => void
  handleGetRemovalDetails: (id: number) => void
  handleOpenQRCodeModal: (code: string) => void
  handleTransportDatetime: (newValue: ViewTab | null) => void
  handleSendRemovalsReport: () => void
  handleResetFilters: () => void
  openEditMode: ({ userId }: { userId: number }) => void
  openEditDateServiceModal: ({ operationId, datetime }: { operationId: number; datetime: string }) => void
  openModalAddress: boolean
  handleGetCostByAddress: (address: string, countryId: CountryIdCode) => void
  newTransportCost: number
  handleUpdateAddress: ({
    userId,
    removalId,
    transportCost
  }: {
    userId: number
    removalId: number
    transportCost: number
  }) => void
  openCreateComplaint: ({
    open,
    operationId,
    type,
    userId
  }: {
    open: boolean
    operationId: number
    type: keyof typeof ComplaintType
    userId: number
  }) => void
  handleCleanAddress: () => void
  removalReasonsList: RemovalReason[]
}

const formattedRemovalReasons = (reasons: RemovalReason[]) =>
  reasons.map((reason) => ({ value: reason.id, text: reason.description }))

const RemovalsTable: React.FC<RemovalsTableProps> = ({
  removals,
  details,
  loading,
  loadingObjects,
  sendingCSV,
  sort,
  pagination,
  categoryFilter,
  activeViewButton,
  searchFilters,
  dateRangeFilters,
  openModalAddress,
  handleGetRemovalDetails,
  OpenModal,
  getUser,
  handleSort,
  handleCategoryFilter,
  handleOpenQRCodeModal,
  cancelRemovalAction,
  handleSearchFilters,
  handleRangePicker,
  handleTransportDatetime,
  handleSendRemovalsReport,
  handleResetFilters,
  openEditMode,
  handleOpenAddressModal,
  handleGetCostByAddress,
  newTransportCost,
  handleUpdateAddress,
  openEditDateServiceModal,
  openCreateComplaint,
  removalReasonsList,
  handleCleanAddress
}) => {
  const history = useHistory()
  const permissions = usePermissions()
  const [currentRemoval, setCurrentRemoval] = React.useState<Removal | null>(null)

  const columns: Column<Removal>[] = [
    {
      key: 'id',
      label: 'ID',
      sort: getSortProps('id', sort, handleSort),
      search: getSearchProps('id', 'ID', handleSearchFilters, searchFilters)
    },
    {
      key: 'transaction.id',
      label: 'TX'
    },
    {
      key: 'fullName',
      label: 'Cliente',
      minWidth: '120px',
      alignment: 'left',
      search: getSearchProps('userId', 'ID Cliente', handleSearchFilters, searchFilters),
      renderDataCell: ({ user }) => {
        return (
          <ClientIDLinkComponent id={user.id} noPadding={true}>
            <div style={{ textAlign: 'left', width: '100%' }}>
              <div>{user.id}</div>
              {user.name} {user.lastName}
            </div>
          </ClientIDLinkComponent>
        )
      }
    },
    {
      key: 'fullAddressString',
      label: 'Dirección'
    },
    {
      key: 'copy',
      label: '',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      renderDataCell: ({ fullAddressString }) => <CopyButton textToCopy={fullAddressString} tooltipText="Copiado!" />
    },
    {
      key: 'createdAt',
      label: 'Creación',
      renderDataCell: ({ createdAt }) => formatDate(createdAt),
      rangePicker: getRangePickerProps(RemovalsTableDateRangeKey.CREATED_AT, dateRangeFilters, handleRangePicker)
    },
    {
      key: 'datetime',
      label: 'Servicio',
      renderDataCell: ({ datetime }) => formatDate(datetime),
      rangePicker: getRangePickerProps(
        RemovalsTableDateRangeKey.TRANSPORT_DATETIME,
        dateRangeFilters,
        handleRangePicker
      )
    },
    {
      key: 'removalAuth',
      label: 'Autorización',
      renderDataCell: ({ removalAuth }) => {
        const authorizedBy = `${removalAuth.agentName} (${removalAuth.agentId})`

        return <Tooltip title={authorizedBy}>{formatDate(removalAuth.dateAuthRemoval)}</Tooltip>
      }
    },
    {
      key: 'origin',
      label: 'Origen',
      renderDataCell: ({ origin }) => {
        if (!origin) return '-'
        return origin
      },
      filters: getFilterProps<RemovalsTableCategoryFilterKey>(
        REMOVAL_ORIGIN_FILTERS,
        categoryFilter,
        'origin',
        handleCategoryFilter,
        true
      )
    },
    {
      key: 'transportCost',
      label: 'Costo transporte',
      renderDataCell: ({ transportCost, tollCostInCents }) => formatNumber(transportCost + tollCostInCents / 100)
    },
    {
      key: 'agentName',
      label: 'Agente',
      renderDataCell: ({ agentName }) => {
        if (!agentName) return '-'
        return agentName
      }
    },
    {
      key: 'objectToReturn',
      tooltip: 'Objetos a devolver',
      label: <CubeReturn />,
      renderDataCell: ({ objectToReturnCount, objectToReturnM3 }) => (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start' }}>
          <span>{objectToReturnCount}</span>
          {formatNumber(objectToReturnM3)} m3
        </div>
      )
    },
    {
      key: 'objectReturned',
      tooltip: 'Objetos devueltos',
      label: <FastTruck />,
      renderDataCell: ({ objectReturnedCount, objectReturnedM3 }) => (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start' }}>
          <span>{objectReturnedCount}</span>
          {formatNumber(objectReturnedM3)} m3
        </div>
      )
    },
    {
      key: 'objectsNotFound',
      tooltip: 'Objetos No encontrados',
      label: <CubeCompass />,
      renderDataCell: ({ objectNotFoundCount }) => objectNotFoundCount
    },
    {
      key: 'objectFound',
      tooltip: 'Objetos encontrados',
      label: <CubeLocation />,
      renderDataCell: ({ objectFoundCount }) => objectFoundCount
    },
    {
      key: 'reason_id',
      label: 'Razón',
      renderDataCell: ({ reasonId }) => {
        if (!reasonId) return '-'
        return removalReasonsList.find((reason) => reason.id === reasonId)?.description
      },
      filters: getFilterProps<RemovalsTableCategoryFilterKey>(
        formattedRemovalReasons(removalReasonsList),
        categoryFilter,
        'removalReasons',
        handleCategoryFilter,
        true
      )
    },
    {
      key: 'status',
      label: 'Estado',
      minWidth: 'max-content',
      renderDataCell: ({ status, cancelReason }) => {
        const found = STATUS_FILTERS.find((sf) => sf.value === status)
        if (!found) return status
        const props = {
          ...found.styleProps
        }

        if (status === OperationStatus.CANCELLED)
          return (
            <ColorBadge tooltip={cancelReason?.description} {...props}>
              {found.text}
            </ColorBadge>
          )
        else return <ColorBadge {...props}>{found.text}</ColorBadge>
      },
      filters: getFilterProps<RemovalsTableCategoryFilterKey>(
        STATUS_FILTERS,
        categoryFilter,
        'status',
        handleCategoryFilter
      )
    }
  ]

  const viewRemovalDetail = ({ id }: { id: number }) => {
    const path = `/removalDetails/${id}`
    history.push({
      pathname: path
    })
  }

  const viewRemovalHistory = ({ id }: { id: number }) => {
    const path = `/removalHistory/${id}`
    history.push({
      pathname: path
    })
  }

  const editRemovalView = ({ operationId, userId }: { operationId: number; userId: number }) => {
    const path = `/editRemoval/${operationId}`

    openEditMode({ userId })
    history.push({
      pathname: path
    })
  }
  const currentDay = moment().startOf('day')

  const editDateServiceAction: MenuAction<Removal>[] = permissions.includes(Permissions.EditarFechaServicio)
    ? [
        {
          label: 'Editar fecha de servicio',
          icon: <Edit />,
          onClick: (row: Removal) =>
            openEditDateServiceModal({
              operationId: row.id,
              datetime: row.datetime!.format()
            }),
          disabled: (row) =>
            (row?.datetime && moment(row.datetime).isSame(currentDay, 'day')) ||
            row?.status === OperationStatus.IN_TRIP ||
            row?.status === OperationStatus.RETURNED ||
            row?.status === OperationStatus.CANCELLED
        }
      ]
    : []

  const RemovalsTableActions: MenuAction<Removal>[] = [
    {
      label: 'Ver Detalles',
      icon: <List />,
      onClick: ({ id }) => viewRemovalDetail({ id })
    },
    {
      label: 'Ver historial',
      icon: <History />,
      onClick: ({ id }) => viewRemovalHistory({ id }),
      disabled: (row) => row?.status === OperationStatus.DRAFT
    },
    {
      label: 'Editar devolución',
      icon: <Edit />,
      onClick: ({ id, user }) => {
        getUser(user.id)
        editRemovalView({ operationId: id, userId: user.id })
      },
      disabled: (row) =>
        row?.status === OperationStatus.AUTHORIZED ||
        row?.status === OperationStatus.ASSIGNED_TO_TRIP ||
        row?.status === OperationStatus.IN_TRIP ||
        row?.status === OperationStatus.RETURNED ||
        row?.status === OperationStatus.CANCELLED
    },
    {
      label: 'Cambiar dirección',
      icon: <Truck />,
      onClick: (row) => {
        setCurrentRemoval(row)
        handleGetRemovalDetails(row.id)
        handleOpenAddressModal({ userId: row.user.id, isOpen: true })
      },
      disabled: (row) => (row?.status ? row.status !== OperationStatus.AUTHORIZED : false)
    },
    ...editDateServiceAction,
    {
      label: 'Cancelar Devolución',
      icon: <Trash />,
      onClick: ({ id, user, transportCost, status, datetime }) => {
        return cancelRemovalAction({
          operationId: id,
          user,
          transportCost,
          isDraft: status === OperationStatus.DRAFT,
          isNotCreateCreditNote: moment().diff(datetime, 'day') === 0
        })
      },
      disabled: (removal) =>
        !(
          removal?.status === OperationStatus.DRAFT ||
          removal?.status === OperationStatus.PROGRAMMED ||
          removal?.status === OperationStatus.PROGRAMMED_M ||
          removal?.status === OperationStatus.AUTHORIZED
        )
    },
    {
      label: 'Crear reclamo',
      icon: <ComplaintIcon stroke="#595959" width="17" height="14" />,
      onClick: ({ id, user }) => openCreateComplaint({ open: true, operationId: id, type: 'REMOVAL', userId: user.id })
    }
  ]

  return (
    <>
      <TableActionBar customStyles={{ marginBottom: '16px' }}>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction variant="csv" disabled={sendingCSV} onClickButton={handleSendRemovalsReport} />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={handleResetFilters} />
          <TableActionBar.Filters>
            <div style={{ marginRight: 'auto' }}>
              <ButtonGroup activeButtonId={activeViewButton} onChange={(newId) => handleTransportDatetime(newId)}>
                <Tab id={ViewTab.TODAY}>Hoy</Tab>
                <Tab id={ViewTab.TOMORROW}>Mañana</Tab>
              </ButtonGroup>
            </div>
          </TableActionBar.Filters>
        </TableActionBar.Wrapper>

        <TableActionBar.ButtonAction titleButton="Crear devolución" onClickButton={OpenModal} />
      </TableActionBar>
      <DynamicTable
        columns={columns}
        keyExtractor={(entity) => entity.id}
        rows={removals}
        loading={loading}
        pagination={pagination}
        actions={RemovalsTableActions}
        rowExpander={{
          render: ({ id }) => (
            <RemovalDetailsTable
              handleGetRemovalDetails={() => handleGetRemovalDetails(id)}
              details={details}
              loading={loadingObjects}
              handleOpenQRCodeModal={handleOpenQRCodeModal}
            />
          )
        }}
        customHeightOffset={360}
      />
      <ChangeAddressModal
        open={openModalAddress}
        currentRemoval={currentRemoval}
        onClose={() => {
          handleOpenAddressModal({ isOpen: false })
          handleCleanAddress()
        }}
        handleGetCostByAddress={handleGetCostByAddress}
        newTransportCost={newTransportCost}
        handleUpdateAddress={handleUpdateAddress}
      />
    </>
  )
}

export default RemovalsTable

type RemovalDetailsTableProps = {
  handleGetRemovalDetails: () => void
  details: RemovalsDetailObject[]
  loading: boolean
  handleOpenQRCodeModal: (code: string) => void
}
const RemovalDetailsTable: React.FC<RemovalDetailsTableProps> = ({
  details,
  loading,
  handleGetRemovalDetails,
  handleOpenQRCodeModal
}) => {
  useEffect(() => {
    handleGetRemovalDetails()
  }, [])

  const onImageClick = (url: string) => {
    window.open(url, '_blank')
  }

  const columns: Column<RemovalsDetailObject>[] = [
    {
      key: 'photos',
      label: <Camera />,
      renderDataCell: ({ photos }) => {
        const photoUrl = photos.map((p) => p.url)[0]
        return <img src={photoUrl} alt="photo" width="68px" onClick={() => onImageClick(photoUrl)} />
      }
    },
    {
      key: 'realId',
      label: 'ID Objeto'
    },
    {
      key: 'product.realId',
      label: 'ID Producto'
    },
    {
      key: 'product.description',
      label: 'Nombre del producto',
      maxWidth: '200px'
    },
    {
      key: 'identificationCodes',
      label: 'Etiqueta',
      renderDataCell: (object) => {
        const identificationCode = getIdentificationCode(object)
        return (
          <div>
            <Tooltip placement="top" title={'Ver código QR'}>
              <i
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleOpenQRCodeModal(identificationCode)}>
                <QRIcon width="17px" height="17px" />
              </i>
            </Tooltip>
            {identificationCode}
          </div>
        )
      }
    },
    {
      key: 'm3',
      label: 'm3',
      renderDataCell: ({ m3 }) => formatNumber(m3, 3)
    },
    {
      key: 'objectStatus',
      label: 'Estado',
      renderDataCell: ({ objectStatus }) => {
        const found = OBJECT_STATUS_OPTIONS.find((sf) => sf.value === objectStatus)
        if (!found) return objectStatus
        const props = {
          ...found?.styleProps
        }

        return <ColorBadge {...props}>{found.text}</ColorBadge>
      }
    }
  ]

  return (
    <>
      <DynamicTable
        columns={columns}
        keyExtractor={(entity) => entity.realId}
        customClassNames={{
          table: styles.customTableProps
        }}
        rows={details}
        rowHeight={100}
        loading={loading}
      />
    </>
  )
}
