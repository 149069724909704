import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import NewBillingTableActionCreators from '../actionCreators/newBillingTable'
import CancelSubscriptionModalActionCreator from '../actionCreators/cancelSubscriptionModal'
import { Billing } from '../../../projectApi/TransactionHandler/Billings/common'
import BillingWarnings, { BillingWarningsProps } from '../components/BillingWarnings'
import { Pagination, searchFiltersToParams, dateRangeFiltersToParams } from '../../../utils/searchFilterUtils'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import {
  removeNullishValues,
  updateMultipleSearchFilters,
  updateMultipleDateRangeFilters
} from '../../../components/DynamicTable/queryParamsUtils'
import {
  BilingsTableDateRangeFilter,
  BillingTableSearchKey,
  BillingsSearchFilter,
  BillingsTableCategoryFilter,
  BillingsTableDateRangeKey
} from '../types/newBillingTable'
import { DEFAULT_BIILING_CATEGORY_FILTERS, DEFAULT_BILLING_SEARCH_FILTERS } from '../reducers/newBillingTable'
import NewBillingTableComponent, { BillingsTableProps } from '../components/newBillingTable'
import moment, { Moment } from 'moment'
import BillingByClientActionCreator from '../actionCreators/billingByClientTable'
import UserExclusionActionCreator from '../actionCreators/userExclusionModals'
import BillPeriodModalActionCreator from '../actionCreators/billPeriod'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { BillingData } from '../../../projectApi/TransactionHandler/BillingData/list'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { useHistory } from 'react-router'
import { Buffer } from 'buffer'
import { BillingStatusValue } from '../constants'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import EmailBilledModalActionCreator from '../actionCreators/emailBilledModal'
import BillingMetricsComponent from '../components/BillingMetrics'
import BillingDiscountModal from './BillingDiscountsModal'
import billingDiscountsModalActionCreator from '../actionCreators/billingDiscountsModal'
import { useLocalization } from '../../../packages/localization'
import { ModalTxtImportComponent } from '../components/modalTxtImport'

const isPaid = (status: string) => status === BillingStatusValue.PAID
const isBilled = (status: string) => status === BillingStatusValue.BILLED
const isProcessed = (status: string) => status === BillingStatusValue.PROCESSED
const isAnnulled = (status: string) => status === BillingStatusValue.ANNULLED
const isRemovalOrRemittance = (type: string) => type === 'removal' || type === 'remittance'
const STATUS_TO_SEND_REMINDER = BillingStatusValue.BILLED

const NewBillingTableContainer = () => {
  const countryCode = useSelector((root) => root.CountrySelector.countrySelected.code)
  const tableState = useSelector((root) => root.BillingV2.NewBillingTable)
  const { modalVisibility } = useSelector((state) => state.BillingV2.discountsModal)
  const { loadingFile } = useSelector((state) => state.UploadFiles)

  const dispatch = useDispatch()
  const { strings } = useLocalization()
  const history = useHistory()
  const {
    getBillingData,
    recalculateBilling,
    authorizationAfip,
    sendCSV,
    getRetryChargeDays,
    openDebtModal,
    refreshBillingData,
    getMetrics,
    getCreditNote,
    getBillingByClient,
    getBillingWarnings,
    exportPayments,
    openModalTxtImport
  } = bindActionCreators(NewBillingTableActionCreators, dispatch)

  const { getPdfWithBillingId } = bindActionCreators(BillingByClientActionCreator, dispatch)
  const { setModalOpen: setExclusionUserModalOpen, setEnableModalOpen } = bindActionCreators(
    UserExclusionActionCreator,
    dispatch
  )
  const setEmailBilledModalOpen = bindActionCreators(EmailBilledModalActionCreator.openEmailBilledModal, dispatch)
  const { openBillPeriodModal } = bindActionCreators(BillPeriodModalActionCreator, dispatch)
  const { setModalVisibility } = bindActionCreators(billingDiscountsModalActionCreator, dispatch)

  const QueryTypes = {
    page: NumberParam,
    periodFrom: StringParam,
    periodTo: StringParam,
    status: StringParam,
    excludedUsers: StringParam,
    userId: StringParam
  }

  const openCancelSubscriptionModal = bindActionCreators(
    CancelSubscriptionModalActionCreator.openCancelSubscriptionModal,
    dispatch
  )

  const [query, setQuery] = useQueryParams(QueryTypes)

  const emptyPagination = { ...tableState.pagination, page: 1 }
  const emptyPaginationClient = { ...tableState.paginationByClient, page: 1 }

  const handleSendCSV = () => {
    const actualParams = {
      pagination: tableState.pagination,
      searchFilters: tableState.searchFilters,
      categoryFilter: tableState.categoryFilter,
      dateRangeFilters: tableState.dateRangeFilters,
      warningId: tableState.warningId
    }

    sendCSV(actualParams)
  }

  const handleExportPayments = () => {
    const actualParams = {
      dateRangeFilters: tableState.dateRangeFilters,
      countryCode: countryCode === CountryIdCode.ALL ? CountryIdCode.ARGENTINA : countryCode
    }
    exportPayments(actualParams)
  }

  const handlePdfDownload = (billingId: number) => {
    getPdfWithBillingId(billingId)
  }

  const handleGetBillingData = (newParams: {
    pagination?: Pagination
    searchFilters?: BillingsSearchFilter[]
    categoryFilter?: BillingsTableCategoryFilter
    dateRangeFilters?: BilingsTableDateRangeFilter[]
    warningId?: number
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      dateRangeFilters: newParams.dateRangeFilters || tableState.dateRangeFilters,
      warningId: newParams.warningId ?? tableState.warningId
    }
    getBillingData(actualParams)

    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilters, undefined, true),
      status: actualParams.categoryFilter.status[0],
      excludedUsers: actualParams.categoryFilter.excludedUsers[0],
      userId: actualParams.searchFilters.find((searchFilter) => searchFilter.key === 'userId')?.text
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const handleGetBillingWarnings = ({ newRangeFilters }: { newRangeFilters?: BilingsTableDateRangeFilter[] }) => {
    const currentPeriod = newRangeFilters || tableState.dateRangeFilters

    getBillingWarnings({
      periodFrom:
        currentPeriod.find((dateRange) => dateRange.key === BillingsTableDateRangeKey.PERIOD)?.startDate ?? undefined
    })
  }

  useEffect(() => {
    handleGetBillingWarnings({
      newRangeFilters: updateMultipleDateRangeFilters(
        tableState.dateRangeFilters,
        [
          {
            key: BillingsTableDateRangeKey.PERIOD,
            startDate: query.periodFrom,
            endDate: query.periodTo
          }
        ],
        true
      )
    })
    getRetryChargeDays()
    handleGetBillingData({
      warningId: 0,
      pagination: { ...emptyPagination, page: query.page || tableState.pagination.page },
      searchFilters: updateMultipleSearchFilters(tableState.searchFilters, [{ key: 'userId', text: query.userId }]),
      categoryFilter: {
        status: query.status ? [BillingStatusValue[query.status as BillingStatusValue]] : [],
        excludedUsers: query.excludedUsers ? [query.excludedUsers] : []
      },
      dateRangeFilters: updateMultipleDateRangeFilters(
        tableState.dateRangeFilters,
        [
          {
            key: BillingsTableDateRangeKey.PERIOD,
            startDate: query.periodFrom,
            endDate: query.periodTo
          }
        ],
        true
      )
    })
  }, [countryCode])

  useEffect(() => {
    if (query.periodFrom) getMetrics(moment(query.periodFrom).format('YYYY-MM-DD'))
  }, [query.periodFrom])

  useEvents(Events.Billing.AUTHORIZATION_EXISTS, ({ pdfUrl }) => window.open(pdfUrl))
  useEvents(Events.Billing.AUTHORIZATION_DOESNT_EXIST, () => {
    alert('No existe factura para ese abono')
  })
  useEvents(Events.Billing.CREDIT_NOTE_EXIST, ({ pdfUrl }) => window.open(pdfUrl))
  useEvents(Events.Billing.CREDIT_NOTE_DOESNT_EXIST, () => {
    alert('No existe nota de crédito para ese abono')
  })
  useEvents([Events.Billing.USER_EXCLUSION, Events.Billing.USER_BILLING_ENABLE], () => handleGetBillingData({}))

  const getNewDataFromClient = ({
    userId,
    startDate,
    endDate
  }: {
    userId: number
    startDate: string
    endDate: string
  }) => {
    getBillingByClient({
      pagination: { ...emptyPaginationClient },
      userId,
      startDate,
      endDate,
      sourceTypes: 'remittance,removal',
      withoutApplication: true,
      withoutDetails: true
    })
  }

  const handlePageChange = (newPage: number) => {
    handleGetBillingData({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handlePageChangeClient = (newPage: number) => {
    getBillingByClient({
      pagination: { ...emptyPaginationClient, page: newPage },
      userId: tableState.clientSelected,
      startDate: tableState.dateRangeFilters[0].startDate?.format('YYYY-MM-DD'),
      endDate: tableState.dateRangeFilters[0].endDate?.format('YYYY-MM-DD'),
      sourceTypes: 'remittance,removal',
      withoutApplication: true,
      withoutDetails: true
    })
  }

  const handleSearch = (key: BillingTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetBillingData({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handleResetFilters = () => {
    const resetPagination = emptyPagination

    handleGetBillingData({
      pagination: resetPagination,
      searchFilters: DEFAULT_BILLING_SEARCH_FILTERS,
      categoryFilter: DEFAULT_BIILING_CATEGORY_FILTERS
    })
  }

  const handleCategoryFilter = (newCategoryFilter: BillingsTableCategoryFilter) => {
    handleGetBillingData({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const handleRangePicker = (key: BillingsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = tableState.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetBillingData({ dateRangeFilters: newRangeFilters, pagination: emptyPagination })
    handleGetBillingWarnings({ newRangeFilters })
  }

  const onRecalculateBilling = async (billing: BillingData) => {
    await recalculateBilling({
      userId: billing.userResponse.id,
      billingStart: billing.startDate || moment(),
      countryCode: countryCode === CountryIdCode.ALL ? CountryIdCode.ARGENTINA : countryCode
    })
    handleGetBillingData({})
  }

  const onShowDetails = ({ userResponse: { id, name, lastName } }: BillingData) => {
    const uriEncode = Buffer.from(escape(`userId=${id}&name=${name}&lastName=${lastName}`)).toString('base64')
    history.push('billingByClient?' + uriEncode)
  }

  const onAuthorization = async ({ billingId }: { billingId: number }) => {
    await authorizationAfip(billingId)
    handleGetBillingData({})
  }

  const billingActions: MenuAction<BillingData>[] = [
    {
      label: strings.Billings.Table.actions.discounts,
      onClick: ({ userResponse }) => setModalVisibility(true, userResponse.id),
      disabled: (billing) => billing?.status !== BillingStatusValue.PROCESSED
    },
    {
      label: strings.Billings.Table.actions.details,
      onClick: onShowDetails,
      disabled: false
    },
    {
      label: strings.Billings.Table.actions.downloadPdf,
      onClick: ({ billingId }) => handlePdfDownload(billingId!),
      disabled: (billing) => !billing?.billingId
    },
    {
      label: strings.Billings.Table.actions.recalculate,
      onClick: onRecalculateBilling,
      disabled: (billing) => !isProcessed(billing?.status || '')
    },
    {
      label: strings.Billings.Table.actions.authorization,
      onClick: (billing: BillingData) => onAuthorization({ billingId: billing.billingId as number }),
      disabled: (billing) => !isProcessed(billing?.status || '')
    },
    {
      label: (billing) =>
        billing?.excludedUserResponse.id
          ? strings.Billings.Table.actions.enableClient
          : strings.Billings.Table.actions.excludeClient,
      onClick: ({ excludedUserResponse, userResponse }) =>
        excludedUserResponse.id ? setEnableModalOpen(true, userResponse) : setExclusionUserModalOpen(true, userResponse)
    },
    {
      label: strings.Billings.Table.actions.sendReminder,
      onClick: (billing) => openDebtModal(billing),
      disabled: (billing) => {
        if (!billing) return true
        const date = moment(billing.createdAt)
        return (
          !(moment().diff(date, 'days') > tableState.retryChargeDays && billing.status === STATUS_TO_SEND_REMINDER) ||
          !billing?.billingId
        )
      }
    },
    {
      label: strings.Billings.Table.actions.sendBill,
      onClick: (billing) => setEmailBilledModalOpen({ modalOpen: true, billingId: billing.billingId! }),
      disabled: (billing) => !isBilled(billing?.status || '')
    },
    {
      label: strings.Billings.Table.actions.cancelSubscription,
      onClick: (billing) => openCancelSubscriptionModal({ modalOpen: true, billingId: billing.billingId! }),
      disabled: (billing) => {
        if (!billing) return true
        if (!(isBilled(billing.status) || isPaid(billing.status))) return true
        return false
      }
    },
    {
      label: strings.Billings.Table.actions.downloadNC,
      onClick: (billing) => getCreditNote(billing.billingId!),
      disabled: (billing) => !isAnnulled(billing?.status || '') || tableState.isLoadingDownload
    }
  ]

  const billingByClientActions: MenuAction<Billing>[] = [
    {
      label: 'Facturar',
      onClick: ({ id }) => onAuthorization({ billingId: id }),
      disabled: (billing) => !(isProcessed(billing?.status || '') && isRemovalOrRemittance(billing?.sourceType || ''))
    }
  ]

  const handleBillPeriod = () => openBillPeriodModal({ modalOpen: true })
  const BillingWarningsProps: BillingWarningsProps = {
    warnings: tableState.billingWarnings,
    fetchingBillingWarnings: tableState.fetchingBillingWarnings,
    handleGetBillingData,
    currentWarningId: tableState.warningId
  }

  const props: BillingsTableProps = {
    billings: tableState.billings,
    loadingRows: tableState.loadingRows,
    error: tableState.error,
    billingActions,
    categoryFilter: tableState.categoryFilter,
    dateRangeFilters: tableState.dateRangeFilters,
    handleRangePicker,
    handleCategoryFilter,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    loadingBillings: tableState.loadingBillings,
    sendingCSV: tableState.sendingCSV,
    searchFilters: tableState.searchFilters,
    refreshBillingData,
    handleResetFilters,
    handleSearch,
    handleSendCSV,
    handleBillPeriod,
    billingsByClient: tableState.billingsByClient,
    isLoadingClient: tableState.isLoadingClient,
    getNewDataFromClient,
    paginationClient: {
      currentPage: tableState.paginationByClient.page,
      pageSize: tableState.paginationByClient.pageSize,
      total: tableState.paginationByClient.total,
      onPageChange: handlePageChangeClient
    },
    billingByClientActions,
    handleExportPayments,
    exportingPayments: tableState.exportingPayments,
    openModalTxtImport
  }

  return (
    <>
      <BillingWarnings {...BillingWarningsProps}>
        <BillingMetricsComponent metrics={tableState.billingMetrics} loadingBillings={tableState.loadingBillings} />
      </BillingWarnings>
      <NewBillingTableComponent {...props} />
      {modalVisibility ? <BillingDiscountModal /> : null}
      <ModalTxtImportComponent
        open={tableState.IsTxtImportModalOpen}
        onCancel={() => openModalTxtImport(false)}
        loading={loadingFile}
      />
    </>
  )
}

export default NewBillingTableContainer
