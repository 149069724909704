import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GeneralDataActionCreator } from '../actionCreator/generalData'
import { GeneralData, GeneralDataProps } from '../components/subcomponents/GeneralData'
import { useQueryParam } from 'use-query-params'

const GeneralDataContainer = () => {
  const dispatch = useDispatch()
  const {
    setMultiplier,
    setDimensionsHeight,
    setDimensionsLength,
    setDimensionsWeight,
    setDimensionsWidth,
    setDisassembleTime,
    setMaxDeviation,
    setMinDeviation,
    setMinPhotos,
    setPackagingTime,
    setIsProductSearchable,
    setCategorySelected,
    setSolutionsSelected
  } = bindActionCreators(GeneralDataActionCreator, dispatch)

  const {
    categories,
    solutions,
    dimensionsHeight,
    dimensionsLength,
    dimensionsWeight,
    dimensionsWidth,
    disassembleTime,
    maxDeviation,
    minDeviation,
    minPhotos,
    multiplier,
    packagingTime,
    isProductSearchable,
    categorySelected,
    solutionsSelected,
    productDetail,
    catalogCategory
  } = useSelector((state) => state.NewProduct.generalData)

  const isFromCatalog = useQueryParam('byCatalog')[0] === 'true'

  const GeneralDataProps: GeneralDataProps = {
    catalogCategory,
    setMultiplier,
    setDimensionsHeight,
    setDimensionsLength,
    setDimensionsWeight,
    setDimensionsWidth,
    setDisassembleTime,
    setMaxDeviation,
    setMinDeviation,
    setMinPhotos,
    setPackagingTime,
    setIsProductSearchable,
    setCategorySelected,
    setSolutionsSelected,
    solutions,
    categories,
    dimensionsHeight,
    dimensionsLength,
    dimensionsWeight,
    dimensionsWidth,
    disassembleTime,
    maxDeviation,
    minDeviation,
    minPhotos,
    multiplier,
    packagingTime,
    isProductSearchable,
    categorySelected,
    solutionsSelected,
    isFromCatalog,
    productDetail
  }

  return <GeneralData {...GeneralDataProps} />
}

export default GeneralDataContainer
