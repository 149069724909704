export enum RecientVisitNameEnum {
  OBJECTS_TABLE = 'Objetos',
  SALES_REQUEST_TABLE = 'Sol. de venta',
  REMOVAL_TABLE = 'Devoluciones',
  TRIPS_TABLE = 'Viajes',
  DONATION_TABLE = 'Donaciones',
  ADDRESS_TABLE = 'Direcciones',
  AGENTS_TABLE = 'Agentes',
  ATTRIBUTES_TABLE = 'Atributos',
  BILLING = 'Facturación',
  BILLING_PAYMENTS = 'Fact. Colppy',
  COMPLAINTS_TABLE = 'Reclamos',
  DEPOSIT_VALIDATION = 'Validar ingreso',
  DEPOSITS_TABLE = 'Ingresos',
  LEADS_TABLE = 'Oportunidades',
  LOCATIONS = 'Ubicaciones',
  PRODUCTS_TABLE = 'Productos',
  PROPOSALS_TABLE = 'Cotizaciones',
  REMOVAL_VALIDATION = 'Validar devoluciones',
  SERVICES_TABLE = 'Servicios',
  USERS_TABLE = 'Usuarios',
  CATEGORIES_TABLE = 'Categorías',
  FLAGS_TABLE = 'Flags',
  DISCOUNTS_TABLE = 'Descuentos',
  DISCOUNT_DAYS_TABLE = 'Calendario de desc.',
  DISCOUNT_DATES_TABLE = 'Desc. por fecha',
  OPERATION_DAYS_TABLE = 'Calendario de op.',
  CORPORATE_USERS_TABLE = 'Usuarios Corp.',
  PENDING_USERS_TABLE = 'Inv. de usuarios',
  ORIGINS_TABLE = 'Orígenes',
  CORPORATIVE_PRODUCTS_TABLE = 'Productos Corp.',
  CORPORATIVE_OBJECTS_TABLE = 'Objetos Corp.',
  REQUESTS_TABLE = 'Solicitudes',
  PICKINGS_TABLE = 'Búsquedas',
  REGISTER_OBJECTS_TABLE = 'Registrar Objetos',
  PHOTOGRAPH_OBJECTS_TABLE = 'Fotografiar Obj.',
  VEHICLES_TABLE = 'Vehículos',
  TRANSACTIONS_TABLE = 'Transacciones',
  PROVIDERS_TABLE = 'Proveedores',
  SHIPPERS_TABLE = 'Transportistas',
  CORPORATIVE_LOCATIONS_TABLE = 'Ubicaciones Corp.',
  CORPORATIVE_DEPOSITS_TABLE = 'Depósitos Corp.',
  STORAGE_DEPOSITS_TABLE = 'Depósitos',
  MOVEMENTS_TABLE = 'Movimientos',
  PAYMENTS_TABLE = 'Reglas',
  PAYMENTS_HISTORY_TABLE = 'Actividad',
  SUBSCRIPTIONS_TABLE = 'Abonos',
  PRICE_BANDS_TABLE = 'Franjas de precios',
  DEVELOPMENT_TABLE = 'Desarrollo',
  DASHBOARD_TABLE = 'Métricas'
}

export enum UsersRolesEnum {
  ADMIN = 'Administrador',
  ROOT = 'Root'
}
