import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import transportActionCreators from '../actions/transport'
import Component from '../components/Transport'
import actions from '../../../components/Address/actions'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { SERVICE_TYPE } from '../constants'
import CalendarActionCreators from '../actions/calendar'

const Container = () => {
  const dispatch = useDispatch()
  const { transport, userSearch } = useSelector((state) => state.NewRemoval)
  const { address: googleAddress } = useSelector((state) => state.Components.address)
  const { street: newStreet, number: newNumber } = googleAddress
  const userCountry = useCountryInfo(userSearch.user?.Country)

  const transportActions = bindActionCreators(transportActionCreators, dispatch)
  const { setSelectedCalendarDate } = bindActionCreators(CalendarActionCreators, dispatch)
  const addressActions = bindActionCreators(actions, dispatch)

  const handleSaveAddress = () => {
    transportActions.saveAddress()
  }

  useEffect(() => {
    transportActions.getNoTransportChargeReasons()
  }, [])

  useEffect(() => {
    if (!transport.fetchedTransportModes) transportActions.getTransportModeByService(SERVICE_TYPE)
  }, [transport.fetchedTransportModes])

  const handleSetTransportMode = (serviceType: string, transportMode: string) => {
    transportActions.setTransportMode(serviceType, transportMode)
    setSelectedCalendarDate(null)
  }

  const props = {
    transport,
    transportActions,
    userID: userSearch.user?.RealID || 0,
    fromRemoval: true,
    newStreet,
    newNumber,
    addressActions,
    userCountry,
    handleSaveAddress,
    handleSetTransportMode
  }

  return <Component {...props} />
}

export default Container
