import { list } from './list'
import { audit } from './audit'
import { CancelAPI } from './Cancel'
import { getById } from './getById'
import { RemovalsAPI } from './Removals'
import { DepositAPI } from './Deposit'
import { ModifyAPI } from './Modify'
import { DateServiceAPI } from './DateService'
import { CollectRemoval } from './collectRemoval'
import { ServiceAddressAPI } from './ServiceAddress'
import { getOperationById } from './getOperationById'

export const OperationAPI = {
  getById,
  list,
  audit,
  payRemoval: CollectRemoval,
  Modify: ModifyAPI,
  Removals: RemovalsAPI,
  Cancel: CancelAPI,
  Deposits: DepositAPI,
  DateService: DateServiceAPI,
  serviceAddress: ServiceAddressAPI,
  getOperationById
}
