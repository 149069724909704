import { Truck } from '@/icons'
import { NavIconsEnum } from '../Nav/types'
import { RecientVisitNameEnum } from './types'
import { Permissions } from '../UserLogged/permissions'

export const RecientVisitsData = {
  products: {
    title: RecientVisitNameEnum.PRODUCTS_TABLE,
    url: '/products',
    icon: NavIconsEnum.SOCIAL_DROPBOX,
    permission: Permissions.VerProductos
  },
  attributes: {
    title: RecientVisitNameEnum.ATTRIBUTES_TABLE,
    url: '/attributes',
    icon: NavIconsEnum.SOCIAL_DROPBOX,
    permission: Permissions.VerProductos
  },
  categories: {
    title: RecientVisitNameEnum.CATEGORIES_TABLE,
    url: '/categories',
    icon: NavIconsEnum.GRID,
    permission: Permissions.VerCategorias
  },
  flags: {
    title: RecientVisitNameEnum.FLAGS_TABLE,
    url: '/flags',
    icon: NavIconsEnum.FLAG,
    permission: Permissions.VerFlags
  },
  services: {
    title: RecientVisitNameEnum.SERVICES_TABLE,
    url: '/services',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerTiposDeServicios
  },
  discounts: {
    title: RecientVisitNameEnum.DISCOUNTS_TABLE,
    url: '/discounts',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerDescuentos
  },
  discountDays: {
    title: RecientVisitNameEnum.DISCOUNT_DAYS_TABLE,
    url: '/discountDays',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerDescuentos
  },
  discountDates: {
    title: RecientVisitNameEnum.DISCOUNT_DATES_TABLE,
    url: '/discountDates',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerDescuentos
  },
  operationDays: {
    title: RecientVisitNameEnum.OPERATION_DAYS_TABLE,
    url: '/operation_days',
    icon: NavIconsEnum.CALENDAR,
    permission: Permissions.VerCalendarioDeOperaciones
  },
  corporateUsers: {
    title: RecientVisitNameEnum.CORPORATE_USERS_TABLE,
    url: '/corporateUsers',
    icon: NavIconsEnum.USER,
    permission: Permissions.VerUsuariosCuentaCorporativa
  },
  pendingUsers: {
    title: RecientVisitNameEnum.PENDING_USERS_TABLE,
    url: '/pendingUsers',
    icon: NavIconsEnum.ENVELOPE,
    permission: Permissions.VisualizarMisInvitaciones
  },
  leads: {
    title: RecientVisitNameEnum.LEADS_TABLE,
    url: '/leads',
    icon: NavIconsEnum.SPEECH,
    permission: Permissions.VerOportunidades
  },
  users: {
    title: RecientVisitNameEnum.USERS_TABLE,
    url: '/users',
    icon: NavIconsEnum.USER,
    permission: Permissions.VerUsuarios
  },
  proposals: {
    title: RecientVisitNameEnum.PROPOSALS_TABLE,
    url: '/proposals',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerCotizaciones
  },
  complaints: {
    title: RecientVisitNameEnum.COMPLAINTS_TABLE,
    url: '/complaints',
    icon: NavIconsEnum.LIST,
    permission: Permissions.ListarReclamos
  },
  agents: {
    title: RecientVisitNameEnum.AGENTS_TABLE,
    url: '/agents',
    icon: NavIconsEnum.EARPHONES_ALT,
    permission: Permissions.VerAgentes
  },
  origins: {
    title: RecientVisitNameEnum.ORIGINS_TABLE,
    url: '/origins',
    icon: NavIconsEnum.MAGNET,
    permission: Permissions.VerOrigenes
  },
  objects: {
    title: RecientVisitNameEnum.OBJECTS_TABLE,
    url: '/objects',
    icon: NavIconsEnum.DIAMOND,
    permission: Permissions.VerObjetos
  },
  corporativeProducts: {
    title: RecientVisitNameEnum.CORPORATIVE_PRODUCTS_TABLE,
    url: '/corporative/products',
    icon: NavIconsEnum.SOCIAL_DROPBOX,
    permission: Permissions.VerProductosCorporativosBO
  },
  corporativeObjects: {
    title: RecientVisitNameEnum.CORPORATIVE_OBJECTS_TABLE,
    url: '/corporative/objects',
    icon: NavIconsEnum.DIAMOND,
    permission: Permissions.VerObjetosCorporativos
  },
  requests: {
    title: RecientVisitNameEnum.REQUESTS_TABLE,
    url: '/requests',
    icon: NavIconsEnum.GRID,
    permission: Permissions.VerSolicitudesGenericas
  },
  salesRequest: {
    title: RecientVisitNameEnum.SALES_REQUEST_TABLE,
    url: '/salesRequest',
    icon: NavIconsEnum.GRID,
    permission: Permissions.VerSolicitudDeVentas
  },
  donations: {
    title: RecientVisitNameEnum.DONATION_TABLE,
    url: '/donations',
    icon: NavIconsEnum.GRID,
    permission: Permissions.VerSolicitudDeVentas
  },
  deposits: {
    title: RecientVisitNameEnum.DEPOSITS_TABLE,
    url: '/deposits',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerIngresos
  },
  pickings: {
    title: RecientVisitNameEnum.PICKINGS_TABLE,
    url: '/pickings',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerBusquedas
  },
  removals: {
    title: RecientVisitNameEnum.REMOVAL_TABLE,
    url: '/removals',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerDevoluciones
  },
  registerObjects: {
    title: RecientVisitNameEnum.REGISTER_OBJECTS_TABLE,
    url: '/register_objects',
    icon: NavIconsEnum.NOTE,
    permission: Permissions.VerRegistrarObjetos
  },
  photographObjects: {
    title: RecientVisitNameEnum.PHOTOGRAPH_OBJECTS_TABLE,
    url: '/photograph_objects',
    icon: NavIconsEnum.CAMERA,
    permission: Permissions.VerFotografiarObjetos
  },
  depositsValidation: {
    title: RecientVisitNameEnum.DEPOSIT_VALIDATION,
    url: '/depositsValidation',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerValidarIngresos
  },
  removalsValidation: {
    title: RecientVisitNameEnum.REMOVAL_VALIDATION,
    url: '/removalsValidation',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerValidarDevoluciones
  },
  vehicles: {
    title: RecientVisitNameEnum.VEHICLES_TABLE,
    url: '/vehicles',
    icon: Truck,
    permission: Permissions.ABMVehiculos
  },
  transactions: {
    title: RecientVisitNameEnum.TRANSACTIONS_TABLE,
    url: '/transactions',
    icon: NavIconsEnum.CALCULATOR,
    permission: Permissions.VerTransacciones
  },
  trips: {
    title: RecientVisitNameEnum.TRIPS_TABLE,
    url: '/trips',
    icon: NavIconsEnum.PLANE,
    permission: Permissions.VerViajes
  },
  providers: {
    title: RecientVisitNameEnum.PROVIDERS_TABLE,
    url: '/providers',
    icon: Truck,
    permission: Permissions.VerViajes
  },
  shippers: {
    title: RecientVisitNameEnum.SHIPPERS_TABLE,
    url: '/shippers',
    icon: Truck,
    permission: Permissions.ABMTransportistas
  },
  corporativeLocations: {
    title: RecientVisitNameEnum.CORPORATIVE_LOCATIONS_TABLE,
    url: '/corporative/locations',
    icon: NavIconsEnum.LOCATION_PIN,
    permission: Permissions.VerUbicacionesBO
  },
  corporativeDeposits: {
    title: RecientVisitNameEnum.CORPORATIVE_DEPOSITS_TABLE,
    url: '/corporative/deposits',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerDepositosCorp
  },
  locations: {
    title: RecientVisitNameEnum.LOCATIONS,
    url: '/locations',
    icon: NavIconsEnum.LOCATION_PIN,
    permission: Permissions.VerDepositos
  },
  storageDeposits: {
    title: RecientVisitNameEnum.STORAGE_DEPOSITS_TABLE,
    url: '/storageDeposits',
    icon: NavIconsEnum.LIST,
    permission: Permissions.VerTodosLosDepositos
  },
  movements: {
    title: RecientVisitNameEnum.MOVEMENTS_TABLE,
    url: '/movements',
    icon: NavIconsEnum.BOOK_OPEN,
    permission: Permissions.VerMovimientos
  },
  billing: {
    title: RecientVisitNameEnum.BILLING,
    url: '/billing',
    icon: NavIconsEnum.DOCS,
    permission: Permissions.VerFacturacion
  },
  payments: {
    title: RecientVisitNameEnum.PAYMENTS_TABLE,
    url: '/payments',
    icon: NavIconsEnum.TAG,
    permission: Permissions.VerReglas
  },
  paymentsHistory: {
    title: RecientVisitNameEnum.PAYMENTS_HISTORY_TABLE,
    url: '/payments_history',
    icon: NavIconsEnum.NOTEBOOK,
    permission: Permissions.VerActividad
  },
  billingPayment: {
    title: RecientVisitNameEnum.BILLING_PAYMENTS,
    url: '/billing_payment',
    icon: NavIconsEnum.DOCS,
    permission: Permissions.ConsultarDeudasTerceros
  },
  subscriptions: {
    title: RecientVisitNameEnum.SUBSCRIPTIONS_TABLE,
    url: '/subscriptions',
    icon: NavIconsEnum.DOCS,
    permission: Permissions.VerFacturacion
  },
  priceBands: {
    title: RecientVisitNameEnum.PRICE_BANDS_TABLE,
    url: '/priceBands',
    icon: NavIconsEnum.MENU,
    permission: Permissions.VerFranjasDePrecios
  }
}
