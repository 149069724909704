import create from './create'
import { getAddress } from './getAddress'
import { listByUser } from './listByUser'
import { save } from './save'

export const AddressAPI = {
  listByUser,
  save,
  getAddress,
  create
}
