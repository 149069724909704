import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import commonActions from './actions/common'
import Component from './component'
import paymentMpActions from './actions/payment-mp'
import userActions from './actions/users'
import { useHistory, useParams } from 'react-router'
import transportActions from './actions/transport'
import objectsActions from './actions/objects'
import paymentCBUActions from './actions/payment-cbu'
import { useFeatureManager } from './../featureManager/utils'
import { FeatureType } from './../featureManager/types'
import EditModeActionCreators from './actions/editMode'
import { getMainAddress, setMainAddress } from './utils/transport'
import { calculateObjectToRemoveValues, formatObjectsForRemovalFromRemoval } from './utils/objects'
import { AdminParams } from '../../projectApi/TransactionHandler/Operation/Removals/admin'
import { useGetObjectsToRemove } from './utils/cost'
import { useObjectsToRemoveTotals } from './hooks'
import { PaymentTypeRemoval } from '../../projectApi/TransactionHandler/Operation/Removals/update'
import { StringParam, useQueryParams } from 'use-query-params'

const Container = () => {
  const newRemovalState = useSelector((state) => state.NewRemoval)
  const calendarState = useSelector((state) => state.NewRemoval.calendar)
  const transportState = useSelector((state) => state.NewRemoval.transport)
  const editModeState = useSelector((state) => state.NewRemoval.editMode)
  const objectsState = useSelector((state) => state.NewRemoval.objects)
  const { userSearch, common, paymentMp, modalReasons, cost } = newRemovalState
  const { objectsToRemove, objectsMinCost } = objectsState
  const { spreedlyPMID, spreedlyPM, operationId } = paymentMp
  const { user, userColppyInfo } = userSearch
  const dispatch = useDispatch()
  const { editRemoval, getRemovalData, openEditMode } = bindActionCreators(EditModeActionCreators, dispatch)
  const { createDraftRemoval, payDraftRemoval } = bindActionCreators(paymentMpActions, dispatch)

  const objectsToRemoveValues = useGetObjectsToRemove(objectsToRemove)

  const { total, totalMinCost } = useObjectsToRemoveTotals(
    objectsToRemoveValues,
    objectsMinCost,
    transportState.cost ? transportState.cost + transportState.tollCostInCents / 100 : transportState.cost,
    userColppyInfo?.saldo
  )

  const useParsedParams = (): { removalId: number } => {
    const { removalId } = useParams<{ removalId: string }>()

    return { removalId: parseInt(removalId) }
  }

  const { removalId } = useParsedParams()

  const history = useHistory()
  const [query] = useQueryParams({ editAddress: StringParam })

  const isEditAddress = query.editAddress === 'true'
  const spreedlyFeature = useFeatureManager(FeatureType.SPREEDLY_PAYMENT)

  const handleEditMode = () => {
    if (userSearch.user?.RealID) openEditMode({ active: true, userId: userSearch.user?.RealID, isDraft: true })
  }

  useEffect(() => {
    if (!editModeState && !user) history.push('/removals')

    if (editModeState.editMode && !user) dispatch(userActions.getUser(editModeState.userId))
    if (editModeState.editMode && user) {
      getRemovalData({ operationId: removalId })
      dispatch(
        spreedlyFeature
          ? paymentMpActions.getUserSpreedlyPM({ userId: editModeState.userId })
          : paymentMpActions.getUserCards(editModeState.userId)
      )
      dispatch(objectsActions.getObjects(editModeState.userId))
      dispatch(paymentCBUActions.getCbus(editModeState.userId))
      dispatch(transportActions.getAddresses(editModeState.userId))
    }
  }, [user])

  useEffect(() => {
    if (transportState.fetchedAddresses) {
      if (editModeState.editMode && transportState.fetchedRemovalData) {
        if (transportState.addresses.length > 0) {
          const mainAddress = transportState.address_id
            ? setMainAddress(transportState.address_id, transportState.addresses)
            : getMainAddress(transportState.addresses)

          dispatch(transportActions.setAddressID(mainAddress, false))
        }
      }

      if (!editModeState.editMode) {
        if (transportState.addresses.length > 0) {
          const mainAddress = getMainAddress(transportState.addresses)
          dispatch(transportActions.setAddressID(mainAddress))
        }
      }
    }
  }, [transportState.fetchedAddresses, transportState.fetchedRemovalData])

  useEffect(() => {
    if (editModeState.editMode && objectsState.fetchedObjects && transportState.fetchedRemovalData)
      dispatch(objectsActions.setObjectsFromRemoval(editModeState.isDraft))
  }, [objectsState.fetchedObjects, transportState.fetchedRemovalData])

  const handleCreateDraftRemoval = (): Promise<boolean> => {
    const { timeIntervals, unavailableDates } = calendarState

    if (!timeIntervals.selected?.id || !timeIntervals.selected?.slotId) return Promise.resolve(false)

    const date = unavailableDates.selected

    const formattedDate = date?.toISOString() || ''
    const objectsToReturn = Object.keys(objectsToRemove).map((x) => {
      const objectToRemoveValues = calculateObjectToRemoveValues(objectsToRemove[x])
      return {
        id: objectsToRemove[x].objectDetails.RealID,
        objectId: x,
        floorsByStairsCost: objectToRemoveValues.stairs,
        packagingCost: objectToRemoveValues.packaging,
        assemblyCost: objectToRemoveValues.assembly
      }
    })

    const minCostInCents = Math.round(totalMinCost * 100)

    const params: AdminParams = {
      objects: objectsToReturn,
      minCost: minCostInCents,
      transportCost: transportState.cost,
      tollCostInCents: transportState.tollCostInCents,
      applyDiscount: cost.applyDiscount,
      discountCode: cost.discountCode,
      isDraft: true,
      userId: user?.RealID || 0,
      date: formattedDate,
      timeSlotId: timeIntervals.selected?.slotId,
      addressId: transportState.address_id,
      notifyUser: false,
      transportModeTypeId: transportState.transportModes.find((x) => x.name === transportState.selectedTransport)?.id,
      totalDiscountPercentage: 0,
      ...(transportState.noTransportCharge && { noTransportChargeReasonId: transportState.noTransportChargeReason }),
      removalReason: {
        id: Number(modalReasons.reasonSelected),
        description:
          modalReasons.removalReasons.list.find((x) => x.id === modalReasons.reasonSelected)?.description || ''
      },
      timeIntervalId: timeIntervals.selected?.id
    }
    // @ts-ignore
    return createDraftRemoval(params)
  }

  const handleEditRemoval = async (paymentType?: PaymentTypeRemoval): Promise<boolean> => {
    const { timeIntervals, unavailableDates } = calendarState
    const date = unavailableDates.selected

    if (!timeIntervals.fullString || !timeIntervals.selected?.slotId) return false

    const formattedDate = date?.toISOString()

    const result = await editRemoval({
      operationId: removalId || operationId,
      body: {
        serviceDate: formattedDate,
        type: transportState.selectedTransport,
        address: transportState.address_id,
        transportCost: transportState.cost,
        tollCostInCents: transportState.tollCostInCents,
        paymentType,
        removalObjects: formatObjectsForRemovalFromRemoval(objectsState.objectsToRemove),
        timeSlotId: timeIntervals.selected?.slotId,
        timeInterval: timeIntervals.fullString
      }
    })

    return Boolean(result)
  }

  const handlePayRemoval = (): Promise<boolean> => {
    // @ts-ignore
    return payDraftRemoval({
      amountInCents: common.paymentBy === 'offline' ? 0 : total < 0 ? 0 : Math.round(total * 100),
      operationId: removalId || operationId,
      spreedlyPmId: common.paymentBy === 'offline' ? 0 : spreedlyPMID,
      userToken: spreedlyPM.find((x) => x.id === spreedlyPMID)?.token || ''
    })
  }

  const props = {
    userSearchState: userSearch,
    commonState: common,
    paymentMpState: paymentMp,
    editState: editModeState,
    editRemoval: handleEditRemoval,
    finish: bindActionCreators(commonActions.finish, dispatch),
    saveRemoval: bindActionCreators(paymentMpActions.saveRemoval, dispatch) as unknown as () => Promise<boolean>,
    openModalResume: bindActionCreators(commonActions.openModalResume, dispatch),
    createDraftRemoval: handleCreateDraftRemoval,
    handlePayRemoval,
    handleEditMode,
    isEditAddress
  }

  return <Component {...props} />
}

export default Container
