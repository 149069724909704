import { Moment } from 'moment'
import {
  GET_TIMEINTERVALS_FAILURE,
  GET_TIMEINTERVALS_REQUEST,
  GET_TIMEINTERVALS_SUCCESS,
  GET_TIMESLOTS_FAILURE,
  GET_TIMESLOTS_REQUEST,
  GET_TIMESLOTS_SUCCESS,
  GET_UNAVAILABLE_DATES_FAILURE,
  GET_UNAVAILABLE_DATES_REQUEST,
  GET_UNAVAILABLE_DATES_SUCCESS,
  GetTimeIntervalsFailure,
  GetTimeIntervalsRequest,
  GetTimeIntervalsSuccess,
  GetTimeslotsFailure,
  GetTimeslotsRequest,
  GetTimeslotsSuccess,
  GetUnavailableDatesFailure,
  GetUnavailableDatesRequest,
  GetUnavailableDatesSuccess,
  SET_AVAILABLE_DATE,
  SET_TIMESLOT,
  SET_TIME_INTERVALS,
  SetAvailableDate,
  SetTimeInterval,
  SetTimeslot
} from '../types/calendar'
import { AppThunk } from '../../../store'
import { API } from '../../../projectApi'

const CalendarActionCreators = {
  getUnavailableDates: (): AppThunk => async (dispatch) => {
    const request: GetUnavailableDatesRequest = {
      type: GET_UNAVAILABLE_DATES_REQUEST
    }

    dispatch(request)

    try {
      const DAYS_ONWARDS = 90

      const { unavailableDates } = await API.Timeslot.timeslot.dates({ daysOnwards: DAYS_ONWARDS })
      if (!unavailableDates) throw new Error('Error al obtener las fechas no disponibles')

      const success: GetUnavailableDatesSuccess = {
        type: GET_UNAVAILABLE_DATES_SUCCESS,
        payload: { unavailableDates }
      }

      dispatch(success)
    } catch (error) {
      const failure: GetUnavailableDatesFailure = {
        type: GET_UNAVAILABLE_DATES_FAILURE
      }

      dispatch(failure)
    }
  },
  setAvailableDate:
    (availableDate: Moment): AppThunk =>
    (dispatch) => {
      const setter: SetAvailableDate = {
        type: SET_AVAILABLE_DATE,
        payload: { availableDate }
      }

      dispatch(setter)

      const dateInSeconds = Number(availableDate.format('X'))
      dispatch(CalendarActionCreators.getTimeslots({ dateInSeconds, selectFirstTimeslot: true }))
    },
  getTimeslots:
    ({ dateInSeconds, selectFirstTimeslot }: { dateInSeconds: number; selectFirstTimeslot: boolean }): AppThunk =>
    async (dispatch) => {
      const request: GetTimeslotsRequest = {
        type: GET_TIMESLOTS_REQUEST
      }

      dispatch(request)

      try {
        const { timeslots } = await API.Timeslot.timeslot.timeslots({ dateInSeconds })
        if (!timeslots) throw new Error('Error al obtener los horarios disponibles')

        const success: GetTimeslotsSuccess = {
          type: GET_TIMESLOTS_SUCCESS,
          payload: { timeslots, selectFirstTimeslot }
        }

        dispatch(success)
        const timeslotID = timeslots[0]?.id
        if (selectFirstTimeslot && timeslotID)
          dispatch(CalendarActionCreators.getTimeIntervals({ timeslotId: timeslotID }))
      } catch (error) {
        const failure: GetTimeslotsFailure = {
          type: GET_TIMESLOTS_FAILURE
        }

        dispatch(failure)
      }
    },
  setTimeslot:
    (timeslot: number): AppThunk =>
    (dispatch) => {
      const setter: SetTimeslot = {
        type: SET_TIMESLOT,
        payload: { timeslot }
      }
      dispatch(setter)
    },
  getTimeIntervals:
    ({ timeslotId }: { timeslotId: number }): AppThunk =>
    async (dispatch) => {
      const request: GetTimeIntervalsRequest = {
        type: GET_TIMEINTERVALS_REQUEST
      }

      dispatch(request)

      try {
        const { timeIntervals } = await API.Timeslot.timeslot.timeIntervals({ timeslotId })
        if (!timeIntervals) throw new Error('Error al obtener las fechas no disponibles')

        const success: GetTimeIntervalsSuccess = {
          type: GET_TIMEINTERVALS_SUCCESS,
          payload: { timeIntervals }
        }

        dispatch(success)
      } catch (error) {
        const failure: GetTimeIntervalsFailure = {
          type: GET_TIMEINTERVALS_FAILURE
        }

        dispatch(failure)
      }
    },
  setTimeInterval: (timeInterval: number): SetTimeInterval => {
    return {
      type: SET_TIME_INTERVALS,
      payload: { timeInterval }
    }
  }
}

export default CalendarActionCreators
