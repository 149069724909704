import { editObject } from './editObject'
import { setLocation } from './setLocation'
import { changes } from './changes'
import { metrics } from './metrics'
import { CorporateObjectAPI } from './Object'

export const CorporateAPI = {
  editObject,
  setLocation,
  changes,
  metrics,
  Object: CorporateObjectAPI
}
