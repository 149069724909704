import * as React from 'react'
const ChartIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54}
    height={54}
    fill="none"
    stroke="#FFF"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="icon icon-tabler icons-tabler-outline icon-tabler-chart-histogram"
    {...props}>
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M3 3v18h18M20 18v3M16 16v5M12 13v8M8 16v5" />
    <path d="M3 11c6 0 5-5 9-5s3 5 9 5" />
  </svg>
)
export default ChartIcon
