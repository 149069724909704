import { apiPrivate } from '@/api'
import { URL_BASE_PROFILE } from '@/endpoints'

interface PostalCode {
  PostalCode: string
  Allowed: boolean
}

export interface Address {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedAt: string | null
  Original: string
  Address: string
  Number: string
  Floor: string
  Apartment: string
  MainAddress: boolean
  Latitude: number
  Longitude: number
  PostalCode: PostalCode
  InDeliveryRange: boolean
  City: string
  Province: string
  Comment: string
  Origin: string
  Country: string
}

type Response = {
  description: Address[]
}

export function getAddress(userId: number): Promise<Response> {
  const params = { user_id: userId }
  return apiPrivate
    .get(URL_BASE_PROFILE + '/admin/address', { params })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
