import { Address } from '@/projectApi/UserProfile/Admin/Address/getAddress'
import { RemovalReason } from '@/projectApi/TransactionHandler/Operation/Removals/reasons'
import { Removal } from '../../../projectApi/TransactionHandler/Operation/Removals/common'
import { Address as CreateAddressResponse } from '@/projectApi/UserProfile/Admin/Address/create'

import { RemovalsDetailObject } from '../../../projectApi/TransactionHandler/Operation/Removals/details'
import { RemovalsSearchableParams } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import { CategoryFilter, DateRangeFilter, SearchFilter, Sort } from '../../../utils/searchFilterUtils'

export type RemovalsTableSortKey = 'id'
export type RemovalsTableSort = Sort<RemovalsTableSortKey>

export type RemovalsTableDateRangeFilter = DateRangeFilter<RemovalsTableDateRangeKey>

export type RemovalsTableCategoryFilterKey = 'origin' | 'status' | 'removalReasons'
export type RemovalsTableCategoryFilter = CategoryFilter<RemovalsTableCategoryFilterKey>

export type RemovalsTableSearchFilterKey = keyof RemovalsSearchableParams
export type RemovalsTableSearchFilter = SearchFilter<RemovalsTableSearchFilterKey>

export enum RemovalsTableSearchKey {
  ID = 'id',
  USER_ID = 'userId',
  IS_FULL_REMOVAL = 'isFullRemoval'
}

export enum RemovalsTableDateRangeKey {
  CREATED_AT = 'createdAt',
  TRANSPORT_DATETIME = 'transportDatetime'
}

export type RemovalsTableState = {
  removals: Removal[]
  details: RemovalsDetailObject[]
  loading: boolean
  loadingObjects: boolean
  sendingCSV: boolean
  error: string
  pagination: Pagination
  sort: RemovalsTableSort
  dateRangeFilters: RemovalsTableDateRangeFilter[]
  activeViewButton: ViewTab | null
  categoryFilter: RemovalsTableCategoryFilter
  searchFilters: RemovalsTableSearchFilter[]
  quantityClientsWithFullRemovals: number
  reasonsList: RemovalReason[]
  addresses: Address[]
  openModalAddress: boolean
  transportCost: number
  newAddress: CreateAddressResponse | null
}

export type Pagination = {
  total: number
  page: number
  limit: number
}

export enum ViewTab {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow'
}

export const CLEAR_STATE = 'REMOVALS/REMOVALS_TABLE/CLEAR_STATE'
export interface ClearState {
  type: typeof CLEAR_STATE
}

export const SET_VIEW_BUTTON_ACTIVE = 'REMOVALS/REMOVALS_TABLE/SET_VIEW_BUTTON_ACTIVE'
export interface SetViewButtonActive {
  type: typeof SET_VIEW_BUTTON_ACTIVE
  payload: {
    activeViewButton: ViewTab | null
  }
}

export const GET_REMOVALS_REQUEST = 'REMOVALS/REMOVALS_TABLE/GET_REMOVALS_REQUEST'
export const GET_REMOVALS_SUCCESS = 'REMOVALS/REMOVALS_TABLE/GET_REMOVALS_SUCCESS'
export const GET_REMOVALS_FAILURE = 'REMOVALS/REMOVALS_TABLE/GET_REMOVALS_FAILURE'

export interface GetRemovalsRequest {
  type: typeof GET_REMOVALS_REQUEST
  payload: {
    newPage: number
    pageSize: number
    sort?: RemovalsTableSort
    dateRangeFilters?: RemovalsTableDateRangeFilter[]
    categoryFilter?: RemovalsTableCategoryFilter
    searchFilters?: RemovalsTableSearchFilter[]
  }
}

export interface GetRemovalsSuccess {
  type: typeof GET_REMOVALS_SUCCESS
  payload: {
    removals: Removal[]
    total: number
    quantityClientsWithFullRemovals: number
  }
}

export interface GetRemovalsFailure {
  type: typeof GET_REMOVALS_FAILURE
  payload: {
    error: string
  }
}

export type GetRemovalsAction = GetRemovalsRequest | GetRemovalsSuccess | GetRemovalsFailure

export const GET_REMOVAL_DETAILS_REQUEST = 'REMOVALS/GET_REMOVAL_DETAILS_REQUEST'
export const GET_REMOVAL_DETAILS_SUCCESS = 'REMOVALS/GET_REMOVAL_DETAILS_SUCCESS'
export const GET_REMOVAL_DETAILS_FAILURE = 'REMOVALS/GET_REMOVAL_DETAILS_FAILURE'

export interface GetRemovalDetailsRequest {
  type: typeof GET_REMOVAL_DETAILS_REQUEST
}

export interface GetRemovalDetailsSuccess {
  type: typeof GET_REMOVAL_DETAILS_SUCCESS
  payload: {
    objects: RemovalsDetailObject[]
  }
}

export interface GetRemovalDetailsFailure {
  type: typeof GET_REMOVAL_DETAILS_FAILURE
  payload: { error: string }
}

export type GetRemovalDetailAction = GetRemovalDetailsRequest | GetRemovalDetailsSuccess | GetRemovalDetailsFailure

export const SEND_CSV_REQUEST = 'REMOVALS/REMOVALS_TABLE/SEND_CSV_REQUEST'
export const SEND_CSV_SUCCESS = 'REMOVALS/REMOVALS_TABLE/SEND_CSV_SUCCESS'
export const SEND_CSV_FAILURE = 'REMOVALS/REMOVALS_TABLE/SEND_CSV_FAILURE'

export interface SendCSVRequest {
  type: typeof SEND_CSV_REQUEST
}

export interface SendCSVSuccess {
  type: typeof SEND_CSV_SUCCESS
}

export interface SendCSVFailure {
  type: typeof SEND_CSV_FAILURE
}

export type SendCSVActions = SendCSVRequest | SendCSVSuccess | SendCSVFailure

export enum GetAddressEnum {
  GET_REMOVAL_ADDRESSES_REQUEST = 'REMOVALS/REMOVALS_TABLE/GET_REMOVAL_ADDRESS_REQUEST',
  GET_REMOVAL_ADDRESSES_SUCCESS = 'REMOVALS/REMOVALS_TABLE/GET_REMOVAL_ADDRESS_SUCCESS',
  GET_REMOVAL_ADDRESSES_FAILURE = 'REMOVALS/REMOVALS_TABLE/GET_REMOVAL_ADDRESS_FAILURE'
}

export interface GetRemovalAddressRequest {
  type: GetAddressEnum.GET_REMOVAL_ADDRESSES_REQUEST
}

export interface GetRemovalAddressSuccess {
  type: GetAddressEnum.GET_REMOVAL_ADDRESSES_SUCCESS
  payload: {
    address: Address[]
  }
}

export interface GetRemovalAddressFailure {
  type: GetAddressEnum.GET_REMOVAL_ADDRESSES_FAILURE
  payload: { error: string }
}

export enum SetOpenModalAddressEnum {
  SET_OPEN_MODAL_ADDRESS = 'REMOVALS/REMOVALS_TABLE/SET_OPEN_MODAL_ADDRESS'
}

export interface SetOpenModalAddress {
  type: SetOpenModalAddressEnum.SET_OPEN_MODAL_ADDRESS
  payload: {
    openModalAddress: boolean
  }
}

export enum FetchCostEnum {
  FETCH_COST_REQUEST = 'REMOVALS/REMOVALS_TABLE/FETCH_COST_REQUEST',
  FETCH_COST_SUCCESS = 'REMOVALS/REMOVALS_TABLE/FETCH_COST_SUCCESS',
  FETCH_COST_FAILURE = 'REMOVALS/REMOVALS_TABLE/FETCH_COST_FAILURE'
}

export interface FetchCostRequest {
  type: FetchCostEnum.FETCH_COST_REQUEST
}

export interface FetchCostSuccess {
  type: FetchCostEnum.FETCH_COST_SUCCESS
  payload: {
    cost: number
    where: string
  }
}

export interface FetchCostFailure {
  type: FetchCostEnum.FETCH_COST_FAILURE
  payload: { error: string }
}

export enum UpdateRemovalAddressEnum {
  UPDATE_REMOVAL_ADRESS_REQUEST = 'REMOVALS/REMOVALS_TABLE/UPDATE_REMOVAL_ADRESS_REQUEST',
  UPDATE_REMOVAL_ADRESS_SUCCESS = 'REMOVALS/REMOVALS_TABLE/UPDATE_REMOVAL_ADRESS_SUCCESS',
  UPDATE_REMOVAL_ADRESS_FAILURE = 'REMOVALS/REMOVALS_TABLE/UPDATE_REMOVAL_ADRESS_FAILURE'
}

export interface UpdateRemovalAddressRequest {
  type: UpdateRemovalAddressEnum.UPDATE_REMOVAL_ADRESS_REQUEST
}

export interface UpdateRemovalAddressSuccess {
  type: UpdateRemovalAddressEnum.UPDATE_REMOVAL_ADRESS_SUCCESS
}

export interface UpdateRemovalAddressFailure {
  type: UpdateRemovalAddressEnum.UPDATE_REMOVAL_ADRESS_FAILURE
}

export enum CreateAddress {
  CREATE_ADDRESS_REQUEST = 'REMOVALS/REMOVALS_TABLE/CREATE_ADDRESS_REQUEST',
  CREATE_ADDRESS_SUCCESS = 'REMOVALS/REMOVALS_TABLE/CREATE_ADDRESS_SUCCESS',
  CREATE_ADDRESS_FAILURE = 'REMOVALS/REMOVALS_TABLE/CREATE_ADDRESS_FAILURE'
}

export interface CreateAddressRequest {
  type: CreateAddress.CREATE_ADDRESS_REQUEST
}

export interface CreateAddressSuccess {
  type: CreateAddress.CREATE_ADDRESS_SUCCESS
  payload: {
    address: CreateAddressResponse
  }
}

export interface CreateAddressFailure {
  type: CreateAddress.CREATE_ADDRESS_FAILURE
  payload: {
    error: string
  }
}

export type CreateAddressAction = CreateAddressRequest | CreateAddressSuccess | CreateAddressFailure

export enum GetRemovalReasons {
  GET_REMOVAL_REASONS_REQUEST = 'REMOVALS/REMOVALS_TABLE/GET_REMOVAL_REASONS_REQUEST',
  GET_REMOVAL_REASONS_SUCCESS = 'REMOVALS/REMOVALS_TABLE/GET_REMOVAL_REASONS_SUCCESS',
  GET_REMOVAL_REASONS_FAILURE = 'REMOVALS/REMOVALS_TABLE/GET_REMOVAL_REASONS_FAILURE'
}

export interface GetRemovalReasonsRequest {
  type: typeof GetRemovalReasons.GET_REMOVAL_REASONS_REQUEST
}

export interface GetRemovalReasonsSuccess {
  type: typeof GetRemovalReasons.GET_REMOVAL_REASONS_SUCCESS
  payload: {
    reasonsList: RemovalReason[]
  }
}

export interface GetRemovalReasonsFailure {
  type: typeof GetRemovalReasons.GET_REMOVAL_REASONS_FAILURE
  payload: { messageError: string }
}

type GetRemovalReasonsActions = GetRemovalReasonsRequest | GetRemovalReasonsSuccess | GetRemovalReasonsFailure

export type RemovalsActions =
  | GetRemovalsAction
  | GetRemovalDetailAction
  | SetViewButtonActive
  | ClearState
  | SendCSVActions
  | GetRemovalAddressRequest
  | GetRemovalAddressSuccess
  | GetRemovalAddressFailure
  | SetOpenModalAddress
  | FetchCostRequest
  | FetchCostSuccess
  | FetchCostFailure
  | UpdateRemovalAddressRequest
  | UpdateRemovalAddressSuccess
  | UpdateRemovalAddressFailure
  | CreateAddressAction
  | GetRemovalReasonsActions
