import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import EditObjectModal from './edit'
import { SortDirection } from '../../../../components/DynamicTable/types/types'
import ObjectsTableActionCreators from '../actionCreators/objectsTable'
import ObjectsTable, { ObjectsTableProps } from '../components/objectsTable'
import {
  ObjectTableSearchFilterKey,
  ObjectsSearchFilter,
  ObjectsTableCategoryFilter,
  ObjectsTableSearchKey,
  ObjectsTableSort,
  ObjectsTableSortKey,
  Pagination
} from '../types/objectsTable'
import QRModalActionCreators from '../../../../components/QRModal/QRModalActions'
import EditObjectsActionCreators from '../actionCreators/edit'
import ReportsActionCreators from '../../../Reports/actions'
import { useHistory } from 'react-router'
import { searchFiltersToParams } from '../../../../utils/searchFilterUtils'
import markPhotoAsMainActionCreators from '../../../Objects/actions/markPhotoAsMain'
import removePhotoActionCreators from '../../../Objects/actions/removePhoto'
import DeletePhotoModal from '../../../Objects/containers/markPhotoAsMain'
import MarkPhotoAsMainModal from '../../../Objects/containers/removePhoto'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import CarouselModal from '../../../../components/CarouselModal/containers/CarouselModal'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { removeNullishValues, updateMultipleSearchFilters } from '../../../../components/DynamicTable/queryParamsUtils'
import { Role } from '../../../../projectApi/AccountManager/Roles/getByParentId'
import { CountryIdCode } from '../../../../components/CountrySelector/constants/constants'
import ObjectsBulkTransferActionCreator from '../actionCreators/bulkTransfer'
import BulkTransferContainer from './bulkTransfer'
import ObjectsMetrics from '../components/objectsMetrics'
import { Permissions } from '../../../UserLogged/permissions'
import { Edit, List, Trash } from '@/icons'
import { MenuAction } from '@/components/actionMenu/baseMenu'
import { FormattedObject } from '@/projectApi/ObjectAdministration/common'
import deleteObjectActionsCreator from '../actionCreators/deleteObject'
import DeleteObjectModalContainer from './DeleteObjectModal'

type LocationState = {
  withoutRefresh?: boolean
}

const ObjectsTableContainer = () => {
  const countryCode = CountryIdCode.ARGENTINA
  const tableState = useSelector((root) => root.CorporativeObjects.table)
  const { role } = useSelector((state) => state.UserLogged.userProfile)
  const isRoleCorporative = role?.id === Role.CORPORATIVE
  const userId = localStorage.getItem('real_id') || ''
  const parentId = localStorage.getItem('parent-id')

  const { requestingReport, lastObjectReports } = useSelector((root) => root.Reports)
  const history = useHistory<LocationState>()

  const dispatch = useDispatch()
  const {
    getObjects,
    toggleExactLocationCode,
    getStatusObjects,
    clearState,
    getMetricsObjects,
    toggleAllObjects,
    toggleSelectedObject
  } = bindActionCreators(ObjectsTableActionCreators, dispatch)
  const openQRModal = bindActionCreators(QRModalActionCreators.openQRModal, dispatch)
  const { selectObject } = bindActionCreators(EditObjectsActionCreators, dispatch)
  const requestCorporativeObjectsReport = bindActionCreators(
    ReportsActionCreators.requestCorporativeObjectsReport,
    dispatch
  )
  const { setOpenBulkTransferModal, getDeposits } = bindActionCreators(ObjectsBulkTransferActionCreator, dispatch)
  const selectMainPhoto = bindActionCreators(markPhotoAsMainActionCreators.selectMainPhoto, dispatch)
  const selectPhoto = bindActionCreators(removePhotoActionCreators.selectPhoto, dispatch)
  const deleteObjectActions = bindActionCreators(deleteObjectActionsCreator, dispatch)

  const QueryTypes = {
    page: NumberParam,
    id: StringParam,
    sortField: StringParam,
    sortDirection: StringParam,
    identificationCode: StringParam,
    product: StringParam,
    status: StringParam,
    objectSituation: StringParam,
    locationCode: StringParam,
    ownerId: StringParam,
    owner: StringParam,
    exactLocationCode: StringParam,
    location: StringParam
  }

  const [query, setQuery] = useQueryParams(QueryTypes)

  const emptyPagination = { ...tableState.pagination, page: 1 }
  const withoutRefresh = history.location.state?.withoutRefresh

  const handleGetObjects = (newParams: {
    pagination?: Pagination
    searchFilters?: ObjectsSearchFilter[]
    categoryFilter?: ObjectsTableCategoryFilter
    sort?: ObjectsTableSort
    silentLoading?: boolean
  }) => {
    const actualParams = {
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      sort: newParams.sort || tableState.sort,
      silentLoading: newParams.silentLoading
    }
    getObjects(actualParams)

    const newQuery = {
      page: actualParams.pagination.page,
      sortDirection: actualParams.sort.direction,
      sortField: actualParams.sort.field,
      status: actualParams.categoryFilter.status[0],
      objectSituation: actualParams.categoryFilter.objectSituation.join(),
      ...searchFiltersToParams(actualParams.searchFilters, true)
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const handleGetMetrics = (newParams: {
    searchFilters?: ObjectsSearchFilter[]
    categoryFilter?: ObjectsTableCategoryFilter
  }) => {
    getMetricsObjects({
      searchFilters: newParams.searchFilters || tableState.searchFilters,
      categoryFilter: newParams.categoryFilter || tableState.categoryFilter,
      ...(parentId && { ownerId: parseInt(parentId) }),
      ...(isRoleCorporative && { ownerId: parseInt(userId) })
    })
  }

  useEvents(
    [
      Events.Objects.OBJECT_PHOTO_MARKED_AS_MAIN,
      Events.Objects.OBJECT_PHOTO_DELETED,
      Events.Objects.OBJECT_EDITED,
      Events.CorporateObjects.TRANSFER_OBJECTS
    ],
    () => handleGetObjects({ silentLoading: true })
  )
  /**
   * When we have a redirection to a screen that has a table and have to filter with some parameters, we have to do some tricks to make the event work
   *  - Add a timeout when then event is dispatch, because we can't catch it before the consumer is initialized
   *  - Add something to identify that we don't have to call the getEntity action when entering the screen to not overlap results (in this case, we are using withoutRefresh parameter inside history state)
   */
  useEvents(Events.Objects.OBJECT_REDIRECT_WITH_FILTER, (newParams) => {
    handleGetObjects(newParams)
    history.push('objects', { withoutRefresh: false })
  })

  useEffect(() => {
    getStatusObjects()
    return () => {
      clearState()
    }
  }, [])

  useEffect(() => {
    const searchFilters = updateMultipleSearchFilters(tableState.searchFilters, [
      { key: ObjectTableSearchFilterKey.ID, text: query.id },
      { key: ObjectTableSearchFilterKey.IDENTIFICATION_CODE, text: query.identificationCode },
      { key: ObjectTableSearchFilterKey.PRODUCT, text: query.product },
      { key: ObjectTableSearchFilterKey.LOCATION_CODE, text: query.locationCode },
      { key: ObjectTableSearchFilterKey.OWNER_ID, text: (isRoleCorporative && userId) || query.ownerId },
      { key: ObjectTableSearchFilterKey.OWNER, text: query.owner },
      { key: ObjectTableSearchFilterKey.EXACT_LOCATION_CODE, text: query.exactLocationCode },
      { key: ObjectTableSearchFilterKey.LOCATION, text: query.location }
    ])
    if (!withoutRefresh)
      handleGetObjects({
        pagination: { ...emptyPagination, page: query.page || tableState.pagination.page },
        searchFilters,
        categoryFilter: {
          ...tableState.categoryFilter,
          objectSituation: query.objectSituation ? [query.objectSituation] : tableState.categoryFilter.objectSituation,
          status: query.status ? [query.status] : tableState.categoryFilter.status
        },
        sort:
          query.sortField && query.sortDirection
            ? {
                ...tableState.sort,
                field: query.sortField as ObjectsTableSortKey,
                direction: query.sortDirection as SortDirection
              }
            : tableState.sort
      })
    handleGetMetrics({
      searchFilters,
      categoryFilter: {
        ...tableState.categoryFilter,
        objectSituation: query.objectSituation ? [query.objectSituation] : tableState.categoryFilter.objectSituation,
        status: query.status ? [query.status] : tableState.categoryFilter.status
      }
    })
  }, [countryCode])

  const handlePageChange = (newPage: number) => {
    handleGetObjects({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: ObjectsTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetObjects({ searchFilters: newSearchFilters, pagination: emptyPagination })
    handleGetMetrics({ searchFilters: newSearchFilters })
  }

  const handleCategoryFilter = (newCategoryFilter: ObjectsTableCategoryFilter) => {
    handleGetObjects({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
    handleGetMetrics({ categoryFilter: newCategoryFilter })
  }

  const handleSort = (newSort: ObjectsTableSort) => {
    handleGetObjects({ sort: newSort })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = tableState.searchFilters.map((filter) => {
      if (filter.key === ObjectTableSearchFilterKey.OWNER_ID && isRoleCorporative) {
        return filter
      }
      if (
        filter.key === ObjectTableSearchFilterKey.EXACT_LOCATION_CODE ||
        filter.key === ObjectTableSearchFilterKey.LOCATION
      ) {
        return { ...filter, text: 'true' }
      }
      return { ...filter, text: '' }
    })

    const resetSort: ObjectsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination

    handleGetObjects({
      pagination: resetPagination,
      sort: resetSort,
      searchFilters: resetSearchFilters,
      categoryFilter: { status: [], objectSituation: [] }
    })
    handleGetMetrics({ searchFilters: resetSearchFilters, categoryFilter: { status: [], objectSituation: [] } })
  }

  const handleRequestReport = () => {
    const params = {
      ...(tableState.selectedObjects.length > 0 && { objectIds: tableState.selectedObjects.map((obj) => obj.realId) }),
      countryId: countryCode
    }
    requestCorporativeObjectsReport({
      lastObjectReports,
      params
    })
  }

  const handleOpenTransferModal = () => {
    getDeposits()
    setOpenBulkTransferModal(true)
  }

  const hasPermissionToDelete = !!role?.permissions?.some(
    (permission) => permission.description === Permissions.ModificarObjeto
  )

  const tableActions: MenuAction<FormattedObject>[] = [
    {
      label: 'Modificar objeto',
      icon: <Edit />,
      onClick: selectObject
    },
    {
      label: 'Ver historial de cambios',
      icon: <List />,
      onClick: ({ realId }) => history.push(`/corporative/objectChanges/${realId}`)
    },
    {
      label: 'Eliminar objeto',
      icon: <Trash />,
      disabled: (object) => object?.deletedAt !== null || !hasPermissionToDelete,
      onClick: ({ realId }) => deleteObjectActions.setModalVisibility({ visible: true, objectId: realId })
    }
  ]

  const props: ObjectsTableProps = {
    objects: tableState.objects,
    selectedObjects: tableState.selectedObjects,
    loadingObjects: tableState.loadingObjects,
    error: tableState.error,
    objectStatus: tableState.objectStatus,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    searchFilters: tableState.searchFilters,
    categoryFilter: tableState.categoryFilter,
    tableActions,
    sort: tableState.sort,
    requestingReport,
    handleMarkPhotoAsMain: selectMainPhoto,
    handleRemovePhoto: selectPhoto,
    handleRequestReport,
    handleOpenQRCodeModal: openQRModal,
    handleSort,
    handleResetFilters,
    handleSearch,
    handleCategoryFilter,
    toggleExactLocationCode,
    handleToggleSelectedObject: toggleSelectedObject,
    handleToggleAllObjects: toggleAllObjects,
    handleOpenTransferModal
  }

  return (
    <>
      <ObjectsMetrics metrics={tableState.metrics} fetchingMetrics={tableState.loadingMetrics} />
      <ObjectsTable {...props} />
      <EditObjectModal />
      <DeletePhotoModal />
      <MarkPhotoAsMainModal />
      <CarouselModal />
      <BulkTransferContainer />
      <DeleteObjectModalContainer />
    </>
  )
}

export default ObjectsTableContainer
