import React, { useEffect } from 'react'
import DeleteObjectModal, { DeleteObjectModalProps } from '../components/DeleteObjectModal'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import deleteObjectActionsCreator from '../actionCreators/deleteObject'

const DeleteObjectModalContainer = () => {
  const dispatch = useDispatch()
  const { objectId, visible, reasons, selectedReason, explanation, fetchingDeleteReasons, deletingObject } =
    useSelector((state) => state.ObjectsV2.deleteModal)
  const { setModalVisibility, getDeleteObjectReasons, setExplanation, setReasonId, deleteObject } = bindActionCreators(
    deleteObjectActionsCreator,
    dispatch
  )

  useEffect(() => {
    if (visible) getDeleteObjectReasons()
  }, [visible])

  const onCancel = () => {
    setModalVisibility({ visible: false })
  }

  const onOk = () => {
    if (!objectId || !selectedReason || !explanation) return
    deleteObject({ objectId, explanation, reasonId: selectedReason.id })
  }

  const props: DeleteObjectModalProps = {
    visible,
    fetchingReasons: fetchingDeleteReasons,
    deletingObject,
    reasons,
    selectedReason,
    explanation,
    onCancel,
    onOk,
    setExplanation,
    setSelectedReasonId: setReasonId
  }

  return <DeleteObjectModal {...props} />
}

export default DeleteObjectModalContainer
