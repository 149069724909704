import { ITEM_TYPES_VALUES } from '@/common/operations/constants'
import { RemovalsDetailObject } from '../../projectApi/TransactionHandler/Operation/Removals/details'
import { ObjectsForFetchCost } from '../NewRemoval/utils/objects'

export const getIdentificationCode = (obj: RemovalsDetailObject) => {
  const found = obj.identificationCodes.find((idcode) => idcode.actual)
  if (!found) return '-'

  return found.code
}

export const formatObjectsForFetchCost = (objects: RemovalsDetailObject[]): ObjectsForFetchCost => {
  const data = objects.map((obj) => ({
    type: ITEM_TYPES_VALUES.PRODUCT,
    product_id: obj.product.guid,
    amount: 1
  }))
  return { data }
}
