import React, { useMemo } from 'react'
import { Button, Col, Icon, Row, Spin, Tooltip } from 'antd'
import paymentMPActionCreators from '../actions/payment-mp'
import InputGroup from '../../../components/InputGroup'
import moment from 'moment'
import { formatMoneyWithCountry, formatNumber } from '../../../utils/formatNumber'
import { SummaryContainerProps } from '../containers/Summary'
import { UserState } from '../reducers/users'
import { ObjectsState } from '../reducers/objects'
import { useGetObjectsToRemove, useObjectsToRemoveTotals } from '../hooks'
import { PaymentScreens, Steps } from '../constants'
import { PAYMENT_BY_VALUES } from '../../../common/operations/constants'
import styled from 'styled-components'
import UserIcon from '../utils/UserIcon.svg'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import { PaymentMpState } from '../reducers/payment-mp'
import { TransportState } from '../reducers/transport'
import { useFeatureManager } from '../../featureManager/utils'
import { FeatureType } from '../../featureManager/types'
import { CalendarState } from '../types/calendar'
import { PaymentTypeRemoval } from '../../../projectApi/TransactionHandler/Operation/Removals/update'

const styles = {
  backButton: {
    marginRight: '16px'
  },
  container: {
    paddingTop: 10,
    paddingLeft: 20,
    border: 'none'
  },
  containerTitle: {
    fontSize: 18,
    color: '#000000',
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 30
  },
  title: {
    fontSize: 18,
    color: '#000000',
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10
  },
  secondaryTitle: {
    fontSize: 16,
    color: '#000000',
    fontWeight: 600,
    marginRight: 5
  },
  info: {
    fontSize: 16,
    fontWeight: 300,
    color: 'grey'
  },
  infoResume: {
    fontSize: 16,
    fontWeight: 300,
    color: 'grey',
    display: 'inline'
  },
  secondaryInfo: {
    fontSize: 14,
    fontWeight: 300,
    color: '#595959'
  },
  subtotalContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'spaceEvenly',
    marginBottom: 30,
    paddingLeft: 25,
    paddingRight: 25
  },
  subtotalTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#5D58F7',
    margin: 0,
    marginTop: 10
  },
  subtotal: {
    fontSize: 20,
    fontWeight: 700,
    color: '#000',
    marginBottom: 0
  },
  spinner: {
    marginTop: 16
  },
  rowButton: {
    marginTop: 16,
    justifyContent: 'center',
    display: 'flex'
  },
  personalInfo: {
    lineHeight: 1,
    marginBottom: 10,
    paddingLeft: 25,
    paddingRight: 25
  },
  resume: {
    borderTop: 2,
    backgroundColor: '#FAFAFA',
    lineHeight: 2,
    paddingBottom: 15,
    paddingLeft: 25,
    paddingRight: 25
  },
  extras: {
    lineHeight: 2,
    backgroundColor: '#FAFAFA',
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25
  },
  payment: {
    lineHeight: 2,
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25
  },
  detailInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 16,
    fontWeight: 300,
    color: 'grey'
  },
  icon: {
    fontSize: 16,
    color: '#5D58F7',
    marginRight: 20
  },
  span: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
  },
  errorText: {
    color: 'red',
    margin: 0,
    padding: '16px',
    fontSize: '16px'
  },
  positiveText: {
    color: '#52c41a'
  }
}

const StyledTooltip = styled(Icon)`
  font-size: 14px;
  color: #5d58f7;
  position: absolute;
  right: 30px;
`
const StyledIcon = styled(Icon)`
  font-size: 16px;
  color: #5d58f7;
  margin-right: 15px;
`
const StyledIconTitle = styled(Icon)`
  font-size: 20px;
  color: #5d58f7;
  margin-right: 10px;
`
interface SummaryProps extends SummaryContainerProps {
  calendarState: CalendarState
  commonState: any
  userSearchState: UserState
  objectsState: ObjectsState
  transportState: TransportState
  paymentMPState: PaymentMpState
  paymentCBUState: any
  paymentMPActions: typeof paymentMPActionCreators
  step: number
  setStep: (step: number) => void
  save: () => void
  pay: () => void
  payAsFree: (paymentTypeRemoval: PaymentTypeRemoval) => void
  isEditAddress: boolean
}

const moneyFormat = { decimals: 2, withThousandSeparator: true }

const Summary = (props: SummaryProps) => {
  const {
    paymentScreen,
    setPaymentScreen,
    calendarState,
    commonState,
    userSearchState,
    objectsState,
    transportState,
    editMode,
    step,
    setStep,
    loading,
    paymentMPState,
    paymentCBUState,
    save,
    paymentMPActions,
    pay,
    payAsFree,
    isEditAddress
  } = props
  const { saveCard, saveSpreedlyCard, cleanErrors, cleanCreditCardFields } = paymentMPActions
  const { objectsToRemove, objectsMinCost, loadingMinCost } = objectsState
  const { user, userColppyInfo } = userSearchState
  const { loadingSaveCard } = paymentMPState
  const spreedlyFeature = useFeatureManager(FeatureType.SPREEDLY_PAYMENT)
  const userCountry = useCountryInfo(user?.Country)
  const { currency } = userCountry

  const objectsToRemoveValues = useGetObjectsToRemove(objectsToRemove)

  const { totalWeight, totalVolume, totalStairs, totalPackaging, totalAssembly, totalMinCost, total, subtotal } =
    useObjectsToRemoveTotals(
      objectsToRemoveValues,
      objectsMinCost,
      transportState.cost ? transportState.cost + transportState.tollCostInCents / 100 : transportState.cost,
      userColppyInfo?.saldo
    )

  const paymentMethods = useMemo(() => {
    const paymentsMethods = []
    if (paymentMPState.cards.length > 0) paymentsMethods.push('TC')
    if (paymentCBUState.cbus.length > 0) paymentsMethods.push('CBU')
    return paymentsMethods.toString().replace(',', ', ') || ''
  }, [paymentMPState.cards, paymentCBUState.cbus])

  const getButtonLabel = () => {
    if (!editMode && step === Steps.FINISH_SUMMARY) return 'Ir a pagar'
    if (paymentScreen === PaymentScreens.NEW_CARD) return 'Guardar tarjeta'
    if (
      paymentMPState.spreedlyPMID === 0 &&
      commonState.paymentBy !== PAYMENT_BY_VALUES.OFFLINE &&
      step === Steps.PAYMENT
    )
      return 'Agregar Tarjeta'
    if (paymentMPState.spreedlyPMID === 0 && commonState.paymentBy === PAYMENT_BY_VALUES.OFFLINE) return 'Finalizar'
    if (step === Steps.PAYMENT) return 'Pagar Devolución'
    if (editMode && step === Steps.FINISH_SUMMARY) return 'Editar devolución'
    return 'Siguiente'
  }

  const onBackButtonClick = () => {
    cleanErrors()
    if (paymentScreen === PaymentScreens.NEW_CARD) {
      cleanCreditCardFields()
      setPaymentScreen(PaymentScreens.PAYMENT_SELECTION)
    } else if (editMode && step === Steps.FINISH_SUMMARY) {
      setStep(step - 2)
    } else {
      setStep(step - 1)
    }
  }

  const onButtonClick = async () => {
    if (step === Steps.FINISH_SUMMARY) {
      save()
    } else if (paymentScreen === PaymentScreens.NEW_CARD) {
      // @ts-ignore
      const success: boolean = spreedlyFeature ? await saveSpreedlyCard() : await saveCard()
      if (success) {
        setPaymentScreen(PaymentScreens.PAYMENT_SELECTION)
        setStep(Steps.PAYMENT)
      }
    } else if (
      step === Steps.PAYMENT &&
      ((commonState.paymentBy === PAYMENT_BY_VALUES.CREDIT_CARD_MP && paymentMPState.card_id === 0) ||
        (commonState.paymentBy === PAYMENT_BY_VALUES.SPREEDLY && paymentMPState.spreedlyPMID === 0))
    ) {
      setPaymentScreen(PaymentScreens.NEW_CARD)
    } else if (step === Steps.ADDRESS && editMode) {
      setStep(step + 1)
    } else if (
      step === Steps.PAYMENT &&
      commonState.paymentBy === PAYMENT_BY_VALUES.SPREEDLY &&
      paymentMPState.spreedlyPMID !== 0
    ) {
      pay()
    } else if (step === Steps.PAYMENT && commonState.paymentBy === 'offline') {
      payAsFree('free')
    } else {
      setStep(step + 1)
    }
  }

  const calendarError =
    Boolean(calendarState.unavailableDates.errorMessage) ||
    Boolean(calendarState.timeIntervals.errorMessage) ||
    !(moment().diff(calendarState.datetime, 'days') <= 0)
  const calendarLoading = calendarState.timeIntervals.loading

  const buttonDisabled = () => {
    switch (step) {
      case Steps.SELECTION:
        return !(objectsToRemoveValues.length > 0) || Boolean(transportState.errorCost)
      case Steps.EXTRAS:
        return !(objectsToRemoveValues.length > 0)
      case Steps.ADDRESS:
        return (
          (transportState.requireAddressDestination && !transportState.address_id) ||
          calendarLoading ||
          calendarError ||
          !calendarState.datetime?.isValid() ||
          Boolean(transportState.errorCost) ||
          !calendarState.selectedRemovalDate?.date.isValid() ||
          !transportState.selectedTransport
        )
      case Steps.PAYMENT:
        return (
          paymentScreen === PaymentScreens.NEW_CARD &&
          Object.values(paymentMPState.errorCardFields).some((value) => value)
        )
    }
  }

  return (
    <InputGroup style={styles.container} title="">
      <Row>
        <Col span={24}>
          <Row style={styles.personalInfo}>
            <p style={styles.containerTitle}>
              <StyledIconTitle type="user" />
              Cliente - ID {user ? user.RealID : '-'}{' '}
            </p>
            {user && (
              <>
                <p style={styles.info}>
                  <img src={UserIcon} alt="usr" style={{ width: 18, marginRight: 10 }} />
                  {user.Name || '-'}
                  <Tooltip title="Nombre del cliente">
                    <StyledTooltip type="info-circle" />
                  </Tooltip>
                </p>
                <ItemDataUser iconType="phone" tooltipTitle="Teléfono" content={user.Phone} />
                <ItemDataUser iconType="mail" tooltipTitle="Correo electrónico" content={user.Email} />
                <ItemDataUser
                  iconType="idcard"
                  tooltipTitle="Documento nacional de identidad"
                  content={`${user.IdentificationType.Type} ${user.DNI}`}
                />
                <ItemDataUser iconType="wallet" tooltipTitle="Métodos de pago registrados" content={paymentMethods} />
              </>
            )}
          </Row>
          <hr style={{ marginBottom: 0 }} />
          <Row style={styles.resume}>
            <p style={styles.title}>
              <StyledIconTitle type="profile" />
              Resumen
            </p>
            <div style={styles.detailInfo}>
              <div>Volumen acumulado</div>
              <div>
                {formatNumber(totalVolume)} m<sup>3</sup>
              </div>
            </div>
            <div style={styles.detailInfo}>
              <div>Peso acumulado</div>
              <div>{formatNumber(totalWeight)} kg</div>
            </div>
            <div style={styles.detailInfo}>
              <div>Flete</div>
              <div>
                {formatMoneyWithCountry(
                  currency,
                  transportState?.cost
                    ? transportState?.cost + transportState.tollCostInCents / 100
                    : transportState?.cost,
                  moneyFormat
                )}
              </div>
            </div>
          </Row>
          <Row style={styles.extras}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: 25,
                marginBottom: 10
              }}>
              <div>
                <p style={styles.secondaryTitle}>Extras</p>
              </div>
              <div
                style={{
                  borderBottom: '1px dotted #979797',
                  width: '100%'
                }}></div>
            </div>
            <div style={styles.detailInfo}>
              <div>Escaleras</div>
              <div>{formatMoneyWithCountry(currency, totalStairs, moneyFormat)}</div>
            </div>
            <div style={styles.detailInfo}>
              <div>Embalaje</div>
              <div>{formatMoneyWithCountry(currency, totalPackaging, moneyFormat)}</div>
            </div>
            <div style={styles.detailInfo}>
              <div>Armado</div>
              <div>{formatMoneyWithCountry(currency, totalAssembly, moneyFormat)}</div>
            </div>
            <Tooltip title="Importe que se cobra cuando el cliente no dejó el tiempo mínimo los objetos depositados">
              <div style={styles.detailInfo}>
                <div>Tiempo mín. almacenado</div>
                <div>{formatMoneyWithCountry(currency, totalMinCost, moneyFormat)}</div>
              </div>
            </Tooltip>
          </Row>
          <Row style={styles.extras}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: 25,
                marginBottom: 10
              }}>
              <div>
                <p style={styles.secondaryTitle}>Colppy</p>
              </div>
              <div
                style={{
                  borderBottom: '1px dotted #979797',
                  width: '100%'
                }}></div>
            </div>
            {!userColppyInfo?.saldoPagar && (
              <Tooltip title="Saldo a favor registrado en Colppy">
                <div style={styles.detailInfo}>
                  <div>Saldo a favor</div>
                  <div style={{ ...styles.positiveText }}>
                    {formatMoneyWithCountry(currency, Math.abs(userColppyInfo?.saldo || 0), moneyFormat)}
                  </div>
                </div>
              </Tooltip>
            )}
          </Row>
          <hr style={{ marginTop: 0 }} />
          <Row style={styles.subtotalContainer}>
            <div style={styles.detailInfo}>
              <div>
                <p style={styles.subtotalTitle}>Subtotal a abonar</p>
              </div>
              <div>
                <p style={styles.subtotal}>{formatMoneyWithCountry(currency, subtotal, moneyFormat)}</p>
              </div>
            </div>
            {!userColppyInfo?.saldoPagar && (
              <>
                <div style={styles.detailInfo}>
                  <div>
                    <p style={styles.subtotalTitle}>Saldo a favor</p>
                  </div>
                  <div>
                    <p style={styles.subtotal}>
                      {formatMoneyWithCountry(currency, Math.abs(userColppyInfo?.saldo || 0), moneyFormat)}
                    </p>
                  </div>
                </div>

                <div style={styles.detailInfo}>
                  <div>
                    <p style={styles.subtotalTitle}>Total</p>
                  </div>
                  <div>
                    <p style={styles.subtotal}>
                      {formatMoneyWithCountry(currency, total < 0 ? 0 : total, moneyFormat)}
                    </p>
                  </div>
                </div>
              </>
            )}
            {(transportState.loadingCost || loadingMinCost) && <Spin style={styles.spinner} />}
            {transportState.errorCost && (
              <p style={{ ...styles.errorText, textAlign: 'center' }}>Error al calcular el precio del flete.</p>
            )}
          </Row>
          <Row style={styles.rowButton}>
            {step !== Steps.SELECTION && (
              <Button
                loading={loading || loadingSaveCard}
                style={styles.backButton}
                type="default"
                onClick={onBackButtonClick}
                disabled={isEditAddress && step === Steps.ADDRESS}>
                Volver atrás
              </Button>
            )}
            <Button
              loading={loading || loadingSaveCard}
              onClick={onButtonClick}
              disabled={buttonDisabled()}
              type="primary">
              {getButtonLabel()}
            </Button>
          </Row>
        </Col>
      </Row>
    </InputGroup>
  )
}

export default Summary

export const ItemDataUser = ({
  content,
  iconType,
  tooltipTitle
}: {
  content: string
  iconType: string
  tooltipTitle: string
}) => {
  return (
    <p style={styles.info}>
      <StyledIcon type={iconType} theme="filled" />
      {content || ''}
      <Tooltip title={tooltipTitle}>
        <StyledTooltip type="info-circle" />
      </Tooltip>
    </p>
  )
}
