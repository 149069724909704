import { Reason } from '@/projectApi/ObjectAdministration/ObjectDeleteReasons/search'

export type DeleteObjectModalState = {
  reasons: Reason[]
  selectedReason?: Reason
  explanation: string
  objectId?: number
  visible: boolean
  deletingObject: boolean
  fetchingDeleteReasons: boolean
}

export enum SettersDeleteObjectModalTypes {
  SET_MODAL_VISIBILITY = 'OBJECTS/CORPORATE/DELETE_OBJECT/SET_MODAL_VISIBILITY',
  SET_REASON_ID = 'OBJECTS/CORPORATE/DELETE_OBJECT/SET_REASON_ID',
  SET_EXPLANATION = 'OBJECTS/CORPORATE/DELETE_OBJECT/SET_EXPLANATION'
}

export interface SetModalVisibility {
  type: SettersDeleteObjectModalTypes.SET_MODAL_VISIBILITY
  payload: {
    visible: boolean
    objectId?: number
  }
}

export interface SetReasonId {
  type: SettersDeleteObjectModalTypes.SET_REASON_ID
  payload: {
    reasonId: number
  }
}

export interface SetExplanation {
  type: SettersDeleteObjectModalTypes.SET_EXPLANATION
  payload: {
    explanation: string
  }
}

type SettersDeleteObjectModalActions = SetModalVisibility | SetReasonId | SetExplanation

export enum DeleteObjectTypes {
  DELETE_OBJECT_REQUEST = 'OBJECTS/CORPORATE/DELETE_OBJECT_REQUEST',
  DELETE_OBJECT_SUCCESS = 'OBJECTS/CORPORATE/DELETE_OBJECT_SUCCESS',
  DELETE_OBJECT_FAILURE = 'OBJECTS/CORPORATE/DELETE_OBJECT_FAILURE'
}

export interface DeleteObjectRequest {
  type: DeleteObjectTypes.DELETE_OBJECT_REQUEST
}

export interface DeleteObjectSuccess {
  type: DeleteObjectTypes.DELETE_OBJECT_SUCCESS
}

export interface DeleteObjectFailure {
  type: DeleteObjectTypes.DELETE_OBJECT_FAILURE
}

type DeleteObjectActions = DeleteObjectRequest | DeleteObjectSuccess | DeleteObjectFailure

export enum GetDeleteObjectReasonsTypes {
  GET_DELETE_OBJECT_REASONS_REQUEST = 'OBJECTS/CORPORATE/GET_DELETE_OBJECT_REASONS_REQUEST',
  GET_DELETE_OBJECT_REASONS_SUCCESS = 'OBJECTS/CORPORATE/GET_DELETE_OBJECT_REASONS_SUCCESS',
  GET_DELETE_OBJECT_REASONS_FAILURE = 'OBJECTS/CORPORATE/GET_DELETE_OBJECT_REASONS_FAILURE'
}

export interface GetDeleteObjectReasonsRequest {
  type: GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_REQUEST
}

export interface GetDeleteObjectReasonsSuccess {
  type: GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_SUCCESS
  payload: {
    reasons: Reason[]
  }
}

export interface GetDeleteObjectReasonsFailure {
  type: GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_FAILURE
}

export type GetDeleteObjectReasonsActions =
  | GetDeleteObjectReasonsRequest
  | GetDeleteObjectReasonsSuccess
  | GetDeleteObjectReasonsFailure

export type DeleteObjectModalActions =
  | SettersDeleteObjectModalActions
  | DeleteObjectActions
  | GetDeleteObjectReasonsActions
