import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { logVisit } from '@/sections/Home/utils'
import { RecientVisitsData } from '@/sections/Home/constants'
import { RecientVisitNameEnum } from '@/sections/Home/types'

const useRouteLogger = () => {
  const location = useLocation()
  const prevPathRef = useRef<string | null>(null)

  useEffect(() => {
    const currentPath = location.pathname

    if (currentPath !== prevPathRef.current) {
      const routeData = Object.entries(RecientVisitsData).find(([_, data]) => data.url === currentPath)

      if (routeData) {
        const [_, { title, permission }] = routeData
        logVisit(title as RecientVisitNameEnum, currentPath, permission)
      }

      prevPathRef.current = currentPath
    }
  }, [location])

  return null
}

export default useRouteLogger
