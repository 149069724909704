// Icons: https://simplelineicons.github.io/
import { Permissions } from '../UserLogged/permissions'
import routes from '../../routes'
import { Either } from '../../utils/utilityTypes'
import { DepositIcon, Truck } from '../../icons'
import { NavIconsEnum } from './types'

export enum NavSelectionType {
  SELECTED = 'selected',
  HAS_SELECTED_SUBITEM = 'hasSelectedSubitem',
  NOT_SELECTED = 'notSelected'
}

export type NavItem = {
  name: string
  permission?: Permissions
  hidden?: boolean
} & Either<{ url: keyof typeof routes }, { subitems?: NavItem[] }> &
  Either<{ icon: string }, { svg?: React.ReactNode }>

export const navItems = ({ corporative }: { corporative: boolean }): NavItem[] => [
  {
    name: 'Configuración',
    icon: NavIconsEnum.WRENCH,
    subitems: [
      {
        name: 'Productos',
        url: '/products',
        icon: NavIconsEnum.SOCIAL_DROPBOX,
        permission: Permissions.VerProductos
      },
      {
        name: 'Atributos',
        url: '/attributes',
        icon: NavIconsEnum.SOCIAL_DROPBOX,
        permission: Permissions.VerProductos
      },
      {
        name: 'Categorías',
        url: '/categories',
        icon: NavIconsEnum.GRID,
        permission: Permissions.VerCategorias
      },
      {
        name: 'Flags',
        url: '/flags',
        icon: NavIconsEnum.FLAG,
        permission: Permissions.VerFlags
      },
      {
        name: 'Tipos de servicios',
        url: '/services',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerTiposDeServicios
      },
      {
        name: 'Descuentos',
        url: '/discounts',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerDescuentos
      },
      {
        name: 'Calendario de descuentos',
        url: '/discountDays',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerDescuentos
      },
      {
        name: 'Descuentos por fecha',
        url: '/discountDates',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerDescuentos
      },

      {
        name: 'Calendario de operaciones',
        url: '/operation_days',
        icon: NavIconsEnum.CALENDAR,
        permission: Permissions.VerCalendarioDeOperaciones
      }
    ]
  },
  {
    name: 'Gestión de usuarios',
    icon: 'icon-user',
    subitems: [
      {
        name: 'Usuarios Corporativos',
        url: '/corporateUsers',
        icon: NavIconsEnum.USER,
        permission: Permissions.VerUsuariosCuentaCorporativa
      },
      {
        name: 'Invitaciones de usuarios',
        icon: NavIconsEnum.ENVELOPE,
        url: '/pendingUsers',
        permission: Permissions.VisualizarMisInvitaciones
      }
    ]
  },
  {
    name: 'Comercial',
    icon: NavIconsEnum.SPEECH,
    subitems: [
      {
        name: 'Oportunidades',
        icon: NavIconsEnum.SPEECH,
        url: '/leads',
        permission: Permissions.VerOportunidades
      },
      {
        name: 'Usuarios',
        url: '/users',
        icon: NavIconsEnum.USER,
        permission: Permissions.VerUsuarios
      },
      {
        name: 'Cotizaciones',
        url: '/proposals',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerCotizaciones
      },
      {
        name: 'Reclamos',
        url: '/complaints',
        icon: NavIconsEnum.LIST,
        permission: Permissions.ListarReclamos
      },
      {
        name: 'Agentes',
        icon: NavIconsEnum.EARPHONES_ALT,
        url: '/agents',
        permission: Permissions.VerAgentes
      },
      {
        name: 'Orígenes',
        icon: NavIconsEnum.MAGNET,
        url: '/origins',
        permission: Permissions.VerOrigenes
      }
    ]
  },
  {
    name: 'Objetos',
    url: '/objects',
    icon: NavIconsEnum.DIAMOND,
    permission: Permissions.VerObjetos
  },
  {
    name: 'Productos',
    url: '/corporative/products',
    icon: NavIconsEnum.SOCIAL_DROPBOX,
    permission: Permissions.VerProductosCorporativosBO
  },
  {
    name: 'Objetos',
    url: '/corporative/objects',
    icon: NavIconsEnum.DIAMOND,
    permission: Permissions.VerObjetosCorporativos
  },
  {
    name: 'Solicitudes',
    url: '/requests',
    icon: NavIconsEnum.GRID,
    permission: Permissions.VerSolicitudesGenericas
  },
  {
    name: 'Operaciones',
    icon: NavIconsEnum.LIST,
    subitems: [
      {
        name: 'Ingresos',
        url: '/deposits',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerIngresos
      },
      {
        name: 'Búsquedas',
        url: '/pickings',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerBusquedas
      },
      {
        name: 'Devoluciones',
        url: '/removals',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerDevoluciones
      }
    ]
  },
  {
    name: 'Pendiente OPS',
    icon: NavIconsEnum.HOURGLASS,
    subitems: [
      {
        name: 'Registrar Objetos',
        url: '/register_objects',
        icon: NavIconsEnum.NOTE,
        permission: Permissions.VerRegistrarObjetos
      },
      {
        name: 'Fotografiar Objetos',
        url: '/photograph_objects',
        icon: NavIconsEnum.CAMERA,
        permission: Permissions.VerFotografiarObjetos
      },
      {
        name: 'Validar ingresos',
        url: '/depositsValidation',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerValidarIngresos
      },
      {
        name: 'Validar devoluciones',
        url: '/removalsValidation',
        icon: NavIconsEnum.LIST,
        permission: Permissions.VerValidarDevoluciones
      }
    ]
  },
  {
    name: 'Transporte',
    svg: Truck,
    subitems: [
      {
        name: 'Vehículos',
        url: '/vehicles',
        svg: Truck,
        permission: Permissions.ABMVehiculos
      },
      {
        name: 'Transacciones',
        url: '/transactions',
        icon: NavIconsEnum.CALCULATOR,
        permission: Permissions.VerTransacciones
      },
      {
        name: 'Viajes',
        url: '/trips',
        icon: NavIconsEnum.PLANE,
        permission: Permissions.VerViajes
      },
      {
        name: 'Proveedores',
        url: '/providers',
        svg: Truck,
        permission: Permissions.VerViajes
      },
      {
        name: 'Transportistas',
        url: '/shippers',
        svg: Truck,
        permission: Permissions.ABMTransportistas
      }
    ]
  },
  {
    name: 'Gestión de depósitos',
    svg: DepositIcon,
    subitems: corporative
      ? [
          {
            name: 'Ubicaciones',
            icon: NavIconsEnum.LOCATION_PIN,
            url: '/corporative/locations',
            permission: Permissions.VerUbicacionesBO
          },
          {
            name: 'Depósitos',
            svg: DepositIcon,
            url: '/corporative/deposits',
            permission: Permissions.VerDepositosCorp
          }
        ]
      : [
          {
            name: 'Ubicaciones',
            icon: NavIconsEnum.LOCATION_PIN,
            url: '/locations',
            permission: Permissions.VerDepositos
          },
          {
            name: 'Depósitos',
            svg: DepositIcon,
            url: '/storageDeposits',
            permission: Permissions.VerTodosLosDepositos
          }
        ]
  },
  {
    name: 'Movimientos',
    url: '/movements',
    icon: NavIconsEnum.BOOK_OPEN,
    permission: Permissions.VerMovimientos
  },
  {
    name: 'Cobranza',
    icon: 'icon-wallet',
    subitems: [
      {
        name: 'Facturación',
        url: '/billing',
        icon: NavIconsEnum.DOCS,
        permission: Permissions.VerFacturacion
      },
      {
        name: 'Reglas',
        url: '/payments',
        icon: NavIconsEnum.TAG,
        permission: Permissions.VerReglas
      },
      {
        name: 'Actividad',
        url: '/payments_history',
        icon: NavIconsEnum.NOTEBOOK,
        permission: Permissions.VerActividad
      },
      {
        name: 'Facturas Colppy',
        icon: NavIconsEnum.DOCS,
        url: '/billing_payment',
        permission: Permissions.ConsultarDeudasTerceros
      },
      {
        name: 'Abonos',
        url: '/subscriptions',
        icon: NavIconsEnum.DOCS,
        permission: Permissions.VerFacturacion
      }
    ]
  },
  {
    name: 'Franjas de precios',
    icon: NavIconsEnum.MENU,
    url: '/priceBands',
    permission: Permissions.VerFranjasDePrecios
  },
  {
    name: 'Desarrollo',
    icon: NavIconsEnum.GAME_CONTROLLER,
    url: '/development'
  },
  {
    name: 'Métricas',
    icon: NavIconsEnum.CHART,
    url: '/dashboard'
  }
]
