import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProposalsTableActionCreators from '../actions/proposals'
import SendProposalActionCreators from '../actions/sendByMail'
import { ProposalsTable, ProposalsTableProps } from '../component/proposals'
import {
  Pagination,
  ProposalsTableCategoryFilter,
  ProposalsDateRangeFilter,
  ProposalsSearchFilter,
  ProposalsTableDateRangeKey,
  ProposalsTableSearchKey,
  ProposalsTableSort,
  DEFAULT_PROPOSAL_CATEGORY_FILTERS
} from '../types/proposals'
import { SortDirection } from '../../../components/DynamicTable/types/types'
import { EnvelopeIcon, List, TextFile } from '../../../icons'
import leadsTableActions from '../../Leads/actions/table'
import { useHistory } from 'react-router'
import { Operation } from '../../../projectApi/TransactionHandler/Proposals/common'
import { PROPOSAL_MODES } from '../../../common/operations/constants'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { STATUS_NAMES } from '../constants'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import {
  removeNullishValues,
  updateMultipleDateRangeFilters,
  updateMultipleSearchFilters
} from '../../../components/DynamicTable/queryParamsUtils'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import { ProposalListSortKey } from '../../../projectApi/TransactionHandler/Proposals/list'
import { Moment } from 'moment'
import ProposalsMetrics from '../component/ProposalsMetrics'
import ProposalMetricsActionsCreator from '../actions/metrics'

const queryParams = {
  id: StringParam,
  prospect_id: StringParam,
  page: NumberParam,
  sortDirection: StringParam,
  sortField: StringParam,
  createdAt: StringParam,
  createdTo: StringParam
}

const ProposalsTableContainer = () => {
  const dispatch = useDispatch()

  const proposalsTable = useSelector((state) => state.Proposals.table)
  const countrySelected = useSelector((state) => state.CountrySelector.countrySelected)
  const proposalMetrics = useSelector((state) => state.Proposals.metrics)
  const { pagination, searchFilters, Column } = proposalsTable

  const countrySelectorState = useSelector((state) => state.CountrySelector)
  const { countries } = countrySelectorState
  const { config } = useSelector((state) => state.Leads.table)
  const sendEmailActions = bindActionCreators(SendProposalActionCreators, dispatch)
  const { openSendByEmail } = sendEmailActions
  const getLeadsConfig = bindActionCreators(leadsTableActions.getLeadsConfig, dispatch)
  const history = useHistory()

  const emptyPagination = { ...pagination, page: 1 }

  const { clearState, getProposals, getProposalPDF, sendProposalsCSV } = bindActionCreators(
    ProposalsTableActionCreators,
    dispatch
  )
  const { getMetrics } = bindActionCreators(ProposalMetricsActionsCreator, dispatch)

  const [query, setQuery] = useQueryParams(queryParams)

  useEffect(() => {
    getLeadsConfig()
  }, [])

  const handleGetProposals = (params: {
    pagination?: Pagination
    Column?: ProposalsTableSort
    searchFilters?: ProposalsSearchFilter[]
    newRangeFilters?: ProposalsDateRangeFilter[]
    categoryFilter?: ProposalsTableCategoryFilter
  }) => {
    const actualParams = {
      pagination: params.pagination || pagination,
      Column: params.Column || Column,
      searchFilters: params.searchFilters || proposalsTable.searchFilters,
      dateRangeFilters: params.newRangeFilters || proposalsTable.dateRangeFilters,
      categoryFilter: params.categoryFilter || proposalsTable.categoryFilter
    }
    getProposals(actualParams)
    const newQuery = {
      page: actualParams.pagination.page,
      ...searchFiltersToParams(actualParams.searchFilters, true),
      ...dateRangeFiltersToParams(actualParams.dateRangeFilters, undefined, true),
      sortDirection: actualParams.Column.direction,
      sortField: actualParams.Column.field
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const handleSearch = (key: ProposalsTableSearchKey, newValue: string) => {
    const newSearchFilters = proposalsTable.searchFilters.map((filter: any) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetProposals({ searchFilters: newSearchFilters, pagination: emptyPagination })
  }

  const handlePageChange = (newPage: number) => {
    handleGetProposals({ pagination: { ...pagination, page: newPage } })
  }

  const handleSort = (newSort: ProposalsTableSort) => {
    handleGetProposals({ Column: newSort })
  }

  const handleGetProposalsMetrics = ({ newRangeFilters }: { newRangeFilters?: ProposalsDateRangeFilter[] }) => {
    const currentPeriod = newRangeFilters || proposalsTable.dateRangeFilters
    const createdAt = currentPeriod.find((dateRange) => dateRange.key === 'createdAt') ?? undefined

    getMetrics({
      dateFrom: createdAt?.startDate ?? undefined,
      dateTo: createdAt?.endDate ?? undefined
    })
  }

  const handleRangePicker = (key: ProposalsTableDateRangeKey, startDate?: Moment, endDate?: Moment) => {
    const newRangeFilters = proposalsTable.dateRangeFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            startDate: startDate || null,
            endDate: endDate || null
          }
        : filter
    )
    handleGetProposals({ newRangeFilters, pagination: emptyPagination })
    handleGetProposalsMetrics({ newRangeFilters })
  }

  const handleResetFilters = () => {
    const resetSearchFilters = proposalsTable.searchFilters.map((filter) => ({ ...filter, text: '' }))
    const resetDateRangeFilters = proposalsTable.dateRangeFilters.map((filter) => ({
      ...filter,
      startDate: null,
      endDate: null
    }))
    const resetSort: ProposalsTableSort = { direction: SortDirection.DESC, field: 'id' }
    const resetPagination = emptyPagination

    handleGetProposals({
      pagination: resetPagination,
      Column: resetSort,
      searchFilters: resetSearchFilters,
      newRangeFilters: resetDateRangeFilters,
      categoryFilter: DEFAULT_PROPOSAL_CATEGORY_FILTERS
    })
    getMetrics({})
  }

  useEffect(() => {
    handleGetProposals({
      pagination: { ...emptyPagination, page: query.page || emptyPagination.page },
      Column: {
        direction: (query.sortDirection as SortDirection) || SortDirection.DESC,
        field: (query.sortField as ProposalListSortKey) || 'id'
      },
      newRangeFilters: updateMultipleDateRangeFilters(
        proposalsTable.dateRangeFilters,
        [
          {
            key: 'createdAt',
            startDate: query.createdAt,
            endDate: query.createdTo
          }
        ],
        true
      ),
      searchFilters: updateMultipleSearchFilters(searchFilters, [
        {
          key: 'id',
          text: query.id
        },
        {
          key: 'prospect_id',
          text: query.prospect_id
        }
      ])
    })

    getMetrics({})

    return () => {
      clearState()
    }
  }, [countrySelected])

  const handleOnClickDetails = (row: Operation) => {
    history.push(`/proposalDetail/${row.prospectId}/${row.id}?mode=${PROPOSAL_MODES.VIEW_PROPOSAL}`)
  }

  const handleSendProposalsCSV = () => {
    const LIMIT = 50
    const actualParams = {
      Limit: LIMIT,
      Offset: (pagination.page - 1) * pagination.pageSize,
      Order: Column.direction,
      Column: Column.field,
      searchFilters: proposalsTable.searchFilters
    }
    sendProposalsCSV(actualParams)
  }

  const handleCategoryFilter = (newCategoryFilter: ProposalsTableCategoryFilter) => {
    handleGetProposals({ categoryFilter: newCategoryFilter, pagination: emptyPagination })
  }

  const actions: MenuAction<Operation>[] = [
    {
      label: 'Ver cotización',
      icon: <List />,
      onClick: (row: Operation) => {
        handleOnClickDetails(row)
      }
    },
    {
      label: 'Descargar cotización',
      icon: <TextFile />,
      onClick: (row: Operation) => {
        getProposalPDF({ proposalId: row.id })
      },
      disabled: (row: Operation | undefined) => row?.status === STATUS_NAMES.BORRADOR
    },
    {
      label: 'Enviar cotización',
      icon: <EnvelopeIcon />,
      onClick: (row: Operation) => {
        const proposalId = row.id.toString()
        openSendByEmail({ proposalId })
      },
      disabled: (row: Operation | undefined) => row?.status === STATUS_NAMES.BORRADOR
    }
  ]

  const metricProps = {
    metrics: proposalMetrics.quantityMetrics,
    fetchingMetrics: proposalMetrics.fetchingMetrics,
    handleGetProposals
  }

  const props: ProposalsTableProps = {
    sendEmailActions,
    proposals: proposalsTable.proposals,
    actions,
    config,
    column: proposalsTable.Column,
    handleSort,
    handleSearch,
    handleRangePicker,
    searchFilters: proposalsTable.searchFilters,
    dateRangeFilters: proposalsTable.dateRangeFilters,
    loadingProposals: proposalsTable.loadingProposals,
    handleResetFilters,
    handleCategoryFilter,
    categoryFilter: proposalsTable.categoryFilter,
    countries,
    pagination: {
      currentPage: pagination.page,
      pageSize: pagination.pageSize,
      total: pagination.total,
      onPageChange: handlePageChange
    },
    handleSendProposalsCSV,
    sendingCSV: proposalsTable.sendingCSV
  }
  return (
    <>
      <ProposalsMetrics {...metricProps} />
      <ProposalsTable {...props} />
    </>
  )
}

export default ProposalsTableContainer
