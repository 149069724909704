export enum RemovalEventsEnum {
  GET_USER = 'REMOVAL/NEW_REMOVAL_GET_USER',
  ADD_SPREEDLY_PM = 'REMOVAL/NEW_REMOVAL_ADD_SPREEDLY_PM',
  REMOVAL_CANCELLED = 'REMOVAL/REMOVAL_CANCELLED',
  ADDRESS_CREATED = 'REMOVAL/ADDRESS_CREATED'
}

export type RemovalEvents = {
  [RemovalEventsEnum.GET_USER]: (params: { id: number }) => void
  [RemovalEventsEnum.ADD_SPREEDLY_PM]: () => void
  [RemovalEventsEnum.REMOVAL_CANCELLED]: () => void
  [RemovalEventsEnum.ADDRESS_CREATED]: () => void
}
