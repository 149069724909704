import React from 'react'
import { Checkbox, Input, Spin } from 'antd'
import { isEmpty } from 'ramda'
import { formatMoneyWithCountry, formatNumber } from '../../../utils/formatNumber'
import { useCountryInfo } from '../../../components/CountrySelector/hooks/hooks'
import newProposal from '../reducers'
import costActions from '../actions/cost'
import styles from './Summary.module.scss'
import { CollapsablePanel } from '../../../components/CollapsablePanel/CollapsablePanel'
import clsx from 'clsx'
import { Button } from '../../../components/Button/Button'
import { AddressState } from '../../../components/Address/reducer'
import { moneyFormat } from '../constants'
import { TotalPrices } from '../containers/Summary'
import { PROPOSAL_MODES } from '../../../common/operations/constants'
import { formatPrice } from '@/packages/localization/utils/formatPrice'

const Price = ({ currency = '$', loading = false, price = 0, perMonth = false, negative = false }) => (
  <div>
    {loading && (
      <span className={styles.spinner}>
        <Spin size="small" />
      </span>
    )}
    {formatMoneyWithCountry(currency, price || 0, moneyFormat)}
    {perMonth && '/mes'}
  </div>
)

type SummaryProps = {
  globalDisabled: boolean
  addressReducer: AddressState
  newProposalReducer: ReturnType<typeof newProposal>
  costActions: typeof costActions
  handleDownloadPDFAtViewProposal: () => void
  save: () => void
  totalPrices: TotalPrices
  mode: string
  downloadPDF: boolean
  loadingPDF: boolean
  setDownloadPDF: (value: boolean) => void
  invalidDateFields: boolean
}

const Summary = (props: SummaryProps) => {
  const {
    globalDisabled,
    newProposalReducer,
    totalPrices,
    save,
    loadingPDF,
    handleDownloadPDFAtViewProposal,
    downloadPDF,
    setDownloadPDF,
    invalidDateFields
  } = props
  const { cost, items, transport, prospect, proposals, flags } = newProposalReducer

  const {
    error: errorAddress,
    address: { street, number }
  } = props.addressReducer
  const { setDiscountCode, fetchDiscount } = props.costActions
  const { loadingRecalculatePrice } = items
  const { loading: loadingDiscountCode, discountCode, discount, error: errorDiscountCode } = cost
  const validDiscount = discount?.valid
  const { selectedDeposit, transportDetail } = transport

  const {
    totalItems,
    totalVolume,
    totalWeight,
    totalExtras,
    spaceCost,
    totalForPackaging,
    totalForDisassemble,
    totalForFloors,
    transportCost,
    discount: discountAmount,
    extraAssistantTotal,
    additionalCostAmount,
    total
  } = totalPrices

  const proposalCountry = useCountryInfo(prospect.selectedProspect?.countryCode)

  const { loadingSave, error, selectedProposal } = proposals

  const validAddress =
    (!isEmpty(street) && !isEmpty(number) && isEmpty(errorAddress)) || !transport.requireAddressOrigin
  const isInfoValid = validAddress && Boolean(selectedDeposit)

  const isDisabled = !isInfoValid || invalidDateFields

  const summaryLines: Omit<SummaryLineProps, 'currency'>[] = [
    {
      label: 'Ítems',
      value: formatNumber(totalItems, 0),
      price: false
    },
    {
      label: 'Volumen acumulado',
      value: formatNumber(totalVolume),
      price: false,
      measure: 'm³'
    },
    {
      label: 'Peso acumulado',
      value: formatNumber(totalWeight),
      price: false,
      measure: 'kg'
    },
    {
      label: 'Mensualidad',
      value: spaceCost,
      loading: loadingRecalculatePrice,
      bold: true
    }
  ]

  const extraSummaryLines: Omit<SummaryLineProps, 'currency'>[] = [
    {
      label: 'Embalaje',
      value: totalForPackaging
    },
    {
      label: 'Desarmado',
      value: totalForDisassemble
    },
    {
      label: 'Escaleras (Pisos)',
      value: totalForFloors
    },
    {
      label: 'Asistentes',
      value: extraAssistantTotal
    },
    {
      label: 'Adicional manual',
      value: additionalCostAmount
    }
  ]

  const extraTransportSummaryLines: Omit<SummaryLineProps, 'currency'>[] = [
    {
      label: 'Peaje',
      value: formatPrice({
        value: transportCost ? selectedProposal?.tollCostInCents || flags.tollCostInCents : 0,
        isInCents: true
      }),
      price: true
    },
    {
      label: 'Distancia',
      value: formatNumber(transportDetail?.distanceKm),
      price: false,
      measure: 'km'
    },
    {
      label: 'Volumen total de objetos',
      value: formatNumber(transportDetail?.volumeM3),
      price: false,
      measure: 'm³'
    },
    {
      label: 'Peso total de objetos',
      value: formatNumber(transportDetail?.maxWeightM),
      price: false,
      measure: 'kg'
    },
    {
      label: 'Altura objeto más alto',
      value: formatNumber(transportDetail?.maxHeightM),
      price: false,
      measure: 'm'
    },
    {
      label: 'Largo objeto más largo',
      value: formatNumber(transportDetail?.maxLengthInM),
      price: false,
      measure: 'm'
    },
    {
      label: 'Ancho objeto más ancho',
      value: formatNumber(transportDetail?.maxWidthM),
      price: false,
      measure: 'm'
    },
    {
      label: transportDetail?.description || '',
      value: '',
      price: false
    }
  ]

  const hideDiscount = globalDisabled && !validDiscount

  return (
    <div className={styles.summaryContainer}>
      <div className={styles.summaryLabel}>Resumen</div>
      <div className={styles.summaryContent}>
        {summaryLines.map((summaryLine) => (
          <SummaryLine key={summaryLine.label} {...summaryLine} currency={proposalCountry.currency} />
        ))}
        <CollapsablePanel
          withoutBorder
          withoutPadding
          header={<SummaryLine bold label="Extras" value={totalExtras} currency={proposalCountry.currency} />}>
          {extraSummaryLines.map((summaryLine) => (
            <SummaryLine key={summaryLine.label} {...summaryLine} currency={proposalCountry.currency} />
          ))}
        </CollapsablePanel>
        {transportCost ? (
          <CollapsablePanel
            withoutBorder
            withoutPadding
            header={<SummaryLine bold label={'Flete'} value={transportCost} currency={proposalCountry.currency} />}>
            {extraTransportSummaryLines.map((summaryLine) => (
              <SummaryLine key={summaryLine.label} {...summaryLine} currency={proposalCountry.currency} />
            ))}
          </CollapsablePanel>
        ) : (
          <SummaryLine bold label={'Flete'} value={transportCost} currency={proposalCountry.currency} />
        )}

        {validDiscount && (
          <SummaryLine label="Descuento" value={discountAmount} currency={proposalCountry.currency} negative />
        )}
        {!hideDiscount && (
          <>
            <div className={styles.label}>¡Código de descuento</div>
            <div className={styles.discountContainer}>
              <Input
                placeholder="Código de descuento"
                disabled={validDiscount}
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                onPressEnter={() => fetchDiscount(discountCode)}
              />
              {!validDiscount && (
                <Button
                  className={styles.discountButton}
                  compact
                  mode="outlined"
                  label="Buscar"
                  loading={loadingDiscountCode}
                  onClick={() => fetchDiscount(discountCode)}
                />
              )}
            </div>
          </>
        )}
        <div className={styles.errorContainer}>
          {errorDiscountCode !== '' ? (
            <span className={styles.error}>{errorDiscountCode}</span>
          ) : (
            validDiscount && <span className={styles.validDiscount}>Código válido!</span>
          )}
        </div>
      </div>
      <div className={styles.summaryContent}>
        <div className={clsx(styles.lineContent, styles.bold, styles.totalContainer)}>
          <div>Total presupuesto</div>
          <span className={styles.total}>
            <Price price={total} currency={proposalCountry.currency} />
          </span>
        </div>
        {props.mode === PROPOSAL_MODES.CREATE_PROPOSAL && (
          <Checkbox checked={downloadPDF} onChange={(option) => setDownloadPDF(option.target.checked)}>
            Descargar PDF
          </Checkbox>
        )}
        {!globalDisabled && (
          <div className={styles.saveButtonContainer}>
            <Button
              className={styles.saveButton}
              label="Guardar cotización"
              loading={loadingSave}
              disabled={isDisabled}
              onClick={() => save()}
            />
            <div className={styles.errorContainer}>{error !== '' && <span className={styles.error}>{error}</span>}</div>
          </div>
        )}
        {props.mode === PROPOSAL_MODES.VIEW_PROPOSAL && (
          <div className={styles.saveButtonContainer}>
            <Button
              loading={loadingPDF}
              disabled={loadingPDF}
              className={styles.saveButton}
              label="Descargar PDF"
              onClick={handleDownloadPDFAtViewProposal}
            />
          </div>
        )}
      </div>
    </div>
  )
}

type SummaryLineProps = {
  label: string
  value: number | string
  currency: string
  loading?: boolean
  perMonth?: boolean
  bold?: boolean
  price?: boolean
  measure?: string
  negative?: boolean
}

const SummaryLine = ({
  label,
  bold,
  value,
  currency,
  loading,
  perMonth,
  price = true,
  measure,
  negative
}: SummaryLineProps) => {
  return (
    <div className={clsx(styles.lineContent, bold && styles.bold)}>
      <div>{label}</div>
      <div className={(negative && styles.negative) || ''}>
        {price && typeof value === 'number' ? (
          <Price loading={loading} currency={currency} price={value} perMonth={perMonth} negative={negative} />
        ) : (
          <span>
            {value}
            {measure && ` ${measure}`}
          </span>
        )}
      </div>
    </div>
  )
}

export default Summary
