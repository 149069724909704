import { Modal } from 'antd'
import React, { useEffect } from 'react'
import styles from './ChangeAddressModal.module.scss'
import { GeocodeResult } from 'use-places-autocomplete'
import Address from '../../../components/Address/container'
import { CountryIdCode } from '@/components/CountrySelector/constants/constants'
import { Removal } from '@/projectApi/TransactionHandler/Operation/Removals/common'
import { Info } from '@/icons'
type ChangeAddressModalProps = {
  open: boolean
  onClose: () => void
  currentRemoval: Removal | null
  handleGetCostByAddress: (address: string, countryId: CountryIdCode) => void
  newTransportCost: number
  handleUpdateAddress: ({
    userId,
    removalId,
    transportCost
  }: {
    userId: number
    removalId: number
    transportCost: number
  }) => void
}

export const ChangeAddressModal: React.FC<ChangeAddressModalProps> = ({
  onClose,
  open,
  currentRemoval,
  handleGetCostByAddress,
  newTransportCost,
  handleUpdateAddress
}) => {
  const [currentPrice, setCurrentPrice] = React.useState(0)

  const onAddressChange = (suggest: GeocodeResult) => {
    const w = `${suggest.geometry.location.lat()},${suggest.geometry.location.lng()}`
    if (currentRemoval) {
      handleGetCostByAddress(w, currentRemoval.countryCode)
    }
  }

  const handleConfirm = () => {
    if (currentRemoval) {
      handleUpdateAddress({
        userId: currentRemoval.user.id,
        removalId: currentRemoval.id,
        transportCost: newTransportCost > currentRemoval.transportCost ? newTransportCost : currentRemoval.transportCost
      })
    }
  }

  useEffect(() => {
    if (currentRemoval) {
      setCurrentPrice(newTransportCost - currentRemoval.transportCost)
    }
  }, [currentRemoval, newTransportCost])

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      forceRender={true}
      okText="Cambiar dirección"
      width={'40%'}
      onOk={handleConfirm}>
      <div>
        <h3>Cambio de dirección</h3>
        <p className={styles.currentAddressLabel}>
          <span>Dirección actual:</span> {currentRemoval?.address.addressString}
        </p>
      </div>
      <Address onAddressChange={onAddressChange} country={CountryIdCode.ARGENTINA} />
      {currentPrice > 0 && (
        <p className={styles.alertPrice}>
          <Info fill="#056e22" />
          Con el cambio de dirección se debe abonar la diferencia de: ${currentPrice}
        </p>
      )}
    </Modal>
  )
}
