/* eslint-disable camelcase */
import { CamelCasedPropertiesDeep } from 'type-fest'
import { apiPrivate } from '../../../api'
import { URL_BASE_CATEGORIES } from '../../../endpoints'
import { AxiosResponse } from 'axios'
import { AllowedCountriesCodes } from '../../../sections/NewProduct/types/productCosts'
import { APISolution } from '../Solutions/list'

interface APIProduct {
  guid: string
  product_id: number
  price: number
  name: string
  packaging_cost: number
  disassembly_cost: number
  cost_per_floor: number
  unpackaging_cost: number
  assembly_cost: number
  deposit_cost_per_floor_in_cents: number
  ref_id: number
  eligible_for_sale: boolean
  country_id: AllowedCountriesCodes
  max_amount_avowed_in_cents: number
  percentage_avowed: number
  max_refund_amount_in_cents: number
  automatic_deposit_cost_per_floor: number
  automatic_cost_per_floor: number
  eligible_for_donation: boolean
  eligible_for_recycled: boolean
  eligible_for_rent: boolean
  photo: APIPhoto | null
}

interface APIServiceType {
  id: string
  operation_type: string
  type: string
  multiplier: number
  display_order: number
}

interface APICategory {
  id: number
  description: string
}

interface APIResponse {
  id: number
  guid: string
  weight_in_gr: number
  length_in_cm: number
  width_in_cm: number
  height_in_cm: number
  multiplier: number
  products_by_country: APIProduct[]
  catalog_category: CatalogCategoryAPI
  service_type: APIServiceType
  categories: APICategory[]
  solutions: APISolution[]
  min_volume_deviation: number
  max_volume_deviation: number
  volume_in_cm3: number
  deprecated: boolean
  packaging_time_in_minutes: number
  unpackaging_time_in_minutes: number
  show_in_search: boolean
  photo_min: number
}

interface CatalogCategoryAPI {
  catalog_name: string
  external_id: string
  id: string
  name: string
  photo_url: string
}

type APIPhoto = {
  url: string
  key: string
}

export type Product = CamelCasedPropertiesDeep<APIResponse>
export type CatalogCategory = CamelCasedPropertiesDeep<CatalogCategoryAPI>

type Response = Product

export function getById({ id }: { id: number }): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_CATEGORIES}/product/${id}/v2`, { params: { country_code: undefined } })
    .then((response: AxiosResponse<APIResponse>) => {
      if (!response.data) throw new Error('Hubo un problema para obtener el producto')

      const camelResponse: Response = {
        categories: response.data.categories.map((category) => ({
          id: category.id,
          description: category.description
        })),
        catalogCategory: {
          catalogName: response.data.catalog_category.catalog_name,
          externalId: response.data.catalog_category.external_id,
          id: response.data.catalog_category.id,
          name: response.data.catalog_category.name,
          photoUrl: response.data.catalog_category.photo_url
        },
        deprecated: response.data.deprecated,
        guid: response.data.guid,
        id: response.data.id,
        multiplier: response.data.multiplier,
        heightInCm: response.data.height_in_cm,
        lengthInCm: response.data.length_in_cm,
        maxVolumeDeviation: response.data.max_volume_deviation,
        minVolumeDeviation: response.data.min_volume_deviation,
        packagingTimeInMinutes: response.data.packaging_time_in_minutes,
        productsByCountry: response.data.products_by_country.map((product) => ({
          countryId: product.country_id,
          assemblyCost: product.assembly_cost,
          costPerFloor: product.cost_per_floor,
          depositCostPerFloorInCents: product.deposit_cost_per_floor_in_cents,
          disassemblyCost: product.disassembly_cost,
          eligibleForSale: product.eligible_for_sale,
          guid: product.guid,
          name: product.name,
          packagingCost: product.packaging_cost,
          price: product.price,
          productId: product.product_id,
          refId: product.ref_id,
          unpackagingCost: product.unpackaging_cost,
          maxAmountAvowedInCents: product.max_amount_avowed_in_cents,
          maxRefundAmountInCents: product.max_refund_amount_in_cents,
          percentageAvowed: product.percentage_avowed,
          automaticCostPerFloor: product.automatic_cost_per_floor,
          automaticDepositCostPerFloor: product.automatic_deposit_cost_per_floor,
          eligibleForDonation: product.eligible_for_donation,
          eligibleForRecycled: product.eligible_for_recycled,
          eligibleForRent: product.eligible_for_rent,
          photo: product.photo
        })),
        serviceType: {
          displayOrder: response.data.service_type.display_order,
          id: response.data.service_type.id,
          multiplier: response.data.service_type.multiplier,
          operationType: response.data.service_type.operation_type,
          type: response.data.service_type.type
        },
        solutions: response.data.solutions,
        showInSearch: response.data.show_in_search,
        photoMin: response.data.photo_min,
        unpackagingTimeInMinutes: response.data.unpackaging_time_in_minutes,
        volumeInCm3: response.data.volume_in_cm3,
        weightInGr: response.data.weight_in_gr,
        widthInCm: response.data.width_in_cm
      }

      return camelResponse
    })
}
