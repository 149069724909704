import React, { useEffect } from 'react'
import LeadsTable from '../LeadsTable'
import actionsCreators from '../actions/table'
import { useDispatch, useSelector } from 'react-redux'
import moment, { Moment } from 'moment'
import { equals, forEachObjIndexed, isEmpty } from 'ramda'
import optionsModalActionCreators from '../actions/optionsModal'
import actionsModalActionCreators from '../actions/actionsModal'
import reasonsModalActionCreators from '../actions/reasonsModal'
import modalActionsCreators from '../actions/modalLeads'
import metricsActionsCreators from '../actions/metricsActions'
import ModalLeads from './ModalLeads'
import DeleteLead from './delete'
import OptionsModal from './OptionsModal'
import ActionsModal from './ActionsModal'
import ReasonsModal from './ReasonsModal'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Edit, History, SignIn } from '../../../icons'
import { LIMIT_REPORT, filterFunnelStatesNames, FunnelState } from '../constants'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import StatusCard from '../../../components/StatusCard'
import styles from './table.module.scss'
import StatCard from '../../../components/StatCard'
import { formatNumber } from '../../../utils/formatNumber'
import { TableActionBar } from '../../../components/TableActionBar'
import Select from '../../../components/Select'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import { Prospect, ProspectFilterCategories, ProspectParams } from '../entities'
import { BaseOption } from '../../../components/Select/Select'
import { CategoryFilter, SearchFilter } from '../../../utils/searchFilterUtils'
import { Sorter } from '../../../components/DynamicTable/utils/entities'
import { ParamsFilter } from '../types/MetricsLeads'
import { ArrayParam, DelimitedNumericArrayParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { removeNullishValues } from '../../../components/DynamicTable/queryParamsUtils'

moment.locale('es')

type Operator = {
  divisor: number
  unidad: string
  decimal?: number
}

const Container = () => {
  const history = useHistory()
  const table = useSelector((state) => state.Leads.table)
  const metrics = useSelector((state) => state.Leads.metrics.metrics)
  const { countries, countrySelected } = useSelector((state) => state.CountrySelector)

  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    userId: StringParam,
    createdAtFromV2: StringParam,
    createdAtToV2: StringParam,
    updatedAtFrom: StringParam,
    updatedAtTo: StringParam,
    updateStatusAtFrom: StringParam,
    updateStatusAtTo: StringParam,
    name: StringParam,
    phone: StringParam,
    email: StringParam,
    source: DelimitedNumericArrayParam,
    contactMediumId: DelimitedNumericArrayParam,
    prospectTypeId: DelimitedNumericArrayParam,
    agent: StringParam,
    agentIds: DelimitedNumericArrayParam,
    provider: ArrayParam,
    status: DelimitedNumericArrayParam,
    offset: NumberParam,
    limit: NumberParam,
    sortField: StringParam,
    sortDirection: StringParam,
    winDateFrom: StringParam,
    winDateTo: StringParam
  })

  const {
    config,
    fetchedConfig,
    prospects,
    loadingProspects,
    loadingConfig,
    sendingReport,
    params,
    searchFilters,
    sorter,
    filter,
    pagination,
    filterBar
  } = table

  const dispatch = useDispatch()

  const actions = bindActionCreators(actionsCreators, dispatch)
  const optionsModalActions = bindActionCreators(optionsModalActionCreators, dispatch)
  const actionsModalActions = bindActionCreators(actionsModalActionCreators, dispatch)
  const reasonsModalActions = bindActionCreators(reasonsModalActionCreators, dispatch)
  const modalActions = bindActionCreators(modalActionsCreators, dispatch)
  const metricsActions = bindActionCreators(metricsActionsCreators, dispatch)

  const {
    getProspects,
    getLeadsConfig,
    setFilter,
    setSearchFilters,
    setFunnelStateFilterBar,
    cleanFilters,
    setSorter,
    setPagination,
    getReport
  } = actions
  const { getMetrics } = metricsActions

  const onFetchProspects = () => handleGetProspects(params, { withMetrics: true })

  const handleGetProspects = (currentParams: ProspectParams, { withMetrics = true } = {}) => {
    getProspects(currentParams)
    if (withMetrics) getMetrics(currentParams)

    const camelCasedParams = camelcaseKeys(currentParams, { deep: true })
    const { column, order, ...rest } = camelCasedParams
    const newQuery = {
      ...rest,
      sortField: column,
      sortDirection: order
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  useEffect(() => {
    const { sort_field: sortField, sort_direction: sortDirection, offset, ...rest } = snakecaseKeys(query)
    const currentParams: ParamsFilter = {
      ...params,
      ...rest,
      Offset: offset || params.Offset,
      Column: sortField || params.Column,
      Order: sortDirection || params.Order
    }
    handleGetProspects(currentParams)
    setSearchFilters([
      { key: 'id', text: String(query.id || '') },
      { key: 'user_id', text: query.userId || '' },
      { key: 'phone', text: query.phone || '' },
      { key: 'email', text: query.email || '' },
      { key: 'created_at', text: query.createdAtFromV2 ? [query.createdAtFromV2, query.createdAtToV2] : [] },
      { key: 'updated_at', text: query.updatedAtFrom ? [query.updatedAtFrom, query.updatedAtTo] : [] },
      {
        key: 'update_status_at',
        text: query.updateStatusAtFrom ? [query.updateStatusAtFrom, query.updateStatusAtTo] : []
      },
      {
        key: 'win_date',
        text: query.winDateFrom ? [query.winDateFrom, query.winDateTo] : []
      }
    ])
    setFilter({
      source: query.source || [],
      status: query.status || [],
      contact_medium_id: query.contactMediumId || [],
      agent_ids: query.agentIds || [],
      provider: query.provider || [],
      prospect_type_id: query.prospectTypeId || []
    })
    setPagination({
      ...pagination,
      current: (query.offset || params.Offset) / (query.limit || params.Limit) + 1
    })
    setSorter({
      columnKey: query.sortField || params.Column,
      field: query.sortField || params.Column,
      order: query.sortDirection || params.Order
    })
    if (!fetchedConfig) getLeadsConfig()
  }, [])

  useEvents(
    [Events.Global.CHANGE_COUNTRY, Events.Leads.LEAD_CREATED, Events.Leads.LEAD_UPDATED, Events.Leads.CLEAN_FILTERS],
    onFetchProspects
  )

  const getNewFilterParams = (params: ProspectParams, filters: CategoryFilter<ProspectFilterCategories>) => {
    let formatFilters = {}
    const Offset = 0
    forEachObjIndexed((value, key) => {
      formatFilters = { ...formatFilters, [getKeyFilter(key)]: value.length > 0 ? value : value[0] }
    }, filters)

    return { ...params, ...formatFilters, Offset }
  }

  const handleSearch = (dataIndex: string, text: string) => {
    const newSearchFilters = searchFilters.map((searchFilter: SearchFilter<string>) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text
          }
        : searchFilter
    )
    const newParams = {
      ...params,
      Offset: 0,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    setSearchFilters(newSearchFilters)
    setPagination({ ...pagination, current: 1 })
    handleGetProspects(newParams, { withMetrics: false })
  }

  const handleDateRangeSearch = (
    dataIndex: string,
    startDate?: Moment,
    endDate?: Moment,
    version = '',
    format = 'YYYY-MM-DD'
  ) => {
    const formattedStartDate = startDate && moment(startDate).isValid() && startDate.startOf('day').format(format)
    const formattedEndDate = endDate && moment(endDate).isValid() && endDate.endOf('day').format(format)

    const newSearchFilters = searchFilters.map((searchFilter: SearchFilter<string>) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text: [formattedStartDate, formattedEndDate]
          }
        : searchFilter
    )
    const dateParams = {
      [`${dataIndex === 'datetime' ? 'lead_datetime' : dataIndex}_from${version && `_${version}`}`]: formattedStartDate,
      [`${dataIndex === 'datetime' ? 'lead_datetime' : dataIndex}_to${version && `_${version}`}`]: formattedEndDate
    }
    const newParams = { ...params, ...dateParams, Offset: 0 }

    setSearchFilters(newSearchFilters)
    setPagination({ ...pagination, current: 1 })
    handleGetProspects(newParams, { withMetrics: true })
  }

  const handlePagination = (newPage: number) => {
    const Offset = (newPage - 1) * params.Limit
    const newParams = { ...params, Offset }

    setPagination({ ...pagination, current: newPage })
    getProspects(newParams)
  }

  const handleSort = (newSorter: Sorter<Prospect>) => {
    setSorter(newSorter)
    setPagination({ ...pagination, current: 1 })
    getProspects({
      ...params,
      ...(newSorter.order ? { Order: newSorter.order } : {}),
      Offset: 0,
      Column: getColumn(newSorter.field)
    })
  }

  const handleFilter = (newFilters: CategoryFilter<ProspectFilterCategories>) => {
    if (!equals(newFilters, filter)) {
      const newParams = { ...getNewFilterParams(params, newFilters) }
      setFilter(newFilters)
      setPagination({ ...pagination, current: 1 })
      handleGetProspects(newParams, { withMetrics: true })
    }
  }

  const exportReport = () => {
    getReport({ ...params, limit: LIMIT_REPORT })
  }
  const getKeyFilter = (key: string) => {
    switch (key) {
      default:
        return key
    }
  }

  const getColumn = (column: string): string => {
    switch (column) {
      case 'firstContact':
        return 'first_contact_at'
      case 'firstName':
        return 'first_name'
      case 'lastName':
        return 'last_name'
      default:
        return column
    }
  }

  const leadsPagination = {
    pageSize: params.Limit,
    total: pagination.total,
    currentPage: pagination.current,
    onPageChange: handlePagination
  }

  const handleOpenDepositsView = (id: number) => {
    history.push(`/deposits?userId=${id}`)
  }

  const handleOpenProposalsView = (id: number) => {
    history.push(`/proposals?prospect_id=${id}`)
  }
  const handleOpenHistoryView = (row: Prospect) => {
    history.push(`/lead_history/${row.id}`)
  }

  const menuActions: MenuAction<Prospect>[] = [
    {
      label: 'Ver cambios',
      icon: <History />,
      onClick: (row) => handleOpenHistoryView(row)
    },
    {
      label: 'Editar oportunidad',
      icon: <Edit />,
      onClick: (row) => {
        modalActions.setEditModalOpen(true, row, countries)
      }
    },
    {
      label: 'Retiros Pactados',
      icon: <SignIn />,
      onClick: (row) => {
        handleOpenDepositsView(row.userId)
      },
      disabled: (row) => !row?.userId
    },
    {
      label: 'Cotizaciones',
      icon: <SignIn />,
      onClick: (row) => {
        handleOpenProposalsView(row.id)
      },
      disabled: (row) => {
        if (row?.proposals) {
          return Boolean(row.proposals.length === 0)
        }
        return true
      }
    }
  ]

  const optionsStatus = config.state
    ?.map((state: any) => {
      let label
      switch (state.qualified) {
        case FunnelState.PENDING:
          label = filterFunnelStatesNames.PEND
          break
        case FunnelState.SQL:
          label = filterFunnelStatesNames.SQL
          break
        case FunnelState.MQL:
          label = filterFunnelStatesNames.MQL
          break
        case FunnelState.DISCARD:
          label = filterFunnelStatesNames.DESC
          break
        default:
          label = ''
          break
      }
      return { label, value: state.stateId }
    })
    .filter((option: BaseOption<string>) => option.label !== '')

  const onSelectOption = (option: BaseOption<string>) => {
    const { value } = option
    const oldValue = filter.status[0]
    setFunnelStateFilterBar(option)
    if (oldValue === value) {
      setFunnelStateFilterBar('')
      return handleFilter({ ...filter, status: [] })
    }
    handleFilter({ ...filter, status: [value] })
  }

  const selectedCountry = getCurrentCountry(countries, countrySelected.code)

  const {
    status: { leadsToServe, leadsInProgress, leadsServed, leadsToServicePr, leadsInProgressPr, leadsServicedPr },
    mqls: { mql, mqlOverLeads, mqlDiscarded },
    sqls: { sql, sqlOverLeads, sqlOverMql, sqlM3, sqlTotal },
    rps: { rp, rpOverLeads, rpOverSql, rpOverMql, rpc, rpcPercentage, rpTotal, rpM3 }
  } = metrics

  const formatSummaryData = (number: number): string => {
    let operator: Operator = {
      divisor: 1,
      unidad: '',
      decimal: 0
    }
    switch (Math.round(number).toString().length) {
      case 4:
      case 5:
      case 6:
        operator = {
          divisor: 1000,
          unidad: 'K',
          decimal: 1
        }
        break
      case 7:
      case 8:
      case 9:
        operator = {
          divisor: 1000000,
          unidad: 'M'
        }
        break
      case 10:
      case 11:
      case 12:
        operator = {
          divisor: 1000000000,
          unidad: 'B'
        }
        break
      case 13:
      case 14:
      case 15:
        operator = {
          divisor: 1000000000000,
          unidad: 'T'
        }
        break
      default:
        operator = {
          divisor: 1,
          unidad: '',
          decimal: 0
        }
    }

    return `${formatNumber(number / operator.divisor, operator.decimal ?? 2, true)}${operator.unidad}`
  }

  return (
    <>
      <div className={styles.metricsContainer}>
        <div className={styles.statusContainer}>
          <StatusCard
            titleCard="Leads Totales"
            description={
              <>
                <b>Leads Totales:</b>
                <p>Leads totales ingresados por canales automatizados y cargados manualmente (teléfono,mail,etc)</p>
              </>
            }
            total={formatNumber(leadsToServe + leadsInProgress + leadsServed, 0, true)}
            percentage={100}
            tooltipIconColor="#FFF"
            type="infoSolid"
          />
          <StatusCard
            titleCard="Leads por Atender"
            description={
              <>
                <b>Leads por Atender:</b>
                <p>Lead pendientes de calificarse o descartarse</p>
              </>
            }
            total={formatNumber(leadsToServe, 0, true)}
            percentage={formatNumber(leadsToServicePr)}
            tooltipIconColor="#CC6266"
            type="danger"
          />
          <StatusCard
            titleCard="Leads en Curso"
            description={
              <>
                <b>Leads en Curso:</b>
                <p>Leads que están en proceso comercial activo</p>
              </>
            }
            total={formatNumber(leadsInProgress, 0, true)}
            percentage={formatNumber(leadsInProgressPr)}
          />
          <StatusCard
            titleCard="Leads Finalizados"
            description={
              <>
                <b>Leads Finalizados:</b>
                <p>Ya fueron atendidos y se encuentran en estado Ganado / Perdido / Descartado.</p>
              </>
            }
            total={formatNumber(leadsServed, 0, true)}
            percentage={formatNumber(leadsServicedPr)}
            tooltipIconColor="#1DD4B3"
            type="success"
          />
        </div>
        <div className={styles.statsContainer}>
          <StatCard>
            <StatCard.Cell
              description={
                <>
                  <b>Leads Calificados:</b>
                  <p>
                    Leads que manifiestan interés por los servicios ofrecidos por SpaceGuru o Servicio equivalente
                    prestado por otra empresa.
                  </p>
                </>
              }>
              <StatCard.Title titleCard="MQL" />
              <StatCard.MainContent content={formatNumber(mql, 0, true)} />
            </StatCard.Cell>
            <StatCard.Cell
              description={
                <>
                  <b>Tasa de Calidad de Leads:</b>
                  <p>
                    De los leads que pudimos establecer contacto, que porcentaje buscaban servicios de SpaceGuru. (No se
                    tienen en cuenta los Leads por Atender)
                  </p>
                  <br />
                  <b>Leads Descartados:</b>
                  <p>
                    Leads que no podemos contactar por falta de respuesta, datos incorrecto o bien buscan servicios que
                    no ofrece SpaceGuru.
                  </p>
                </>
              }>
              <StatCard.Percentage stat="MQL" from="Leads" percentage={formatNumber(mqlOverLeads * 100)} />
              <StatCard.Percentage stat="Descartados" percentage={formatNumber(mqlDiscarded * 100, 2, true)} />
            </StatCard.Cell>
          </StatCard>
          <StatCard>
            <StatCard.Cell
              description={
                <>
                  <b>SQLs (Sales Qualified Leads):</b>
                  <p>Cantidad de Leads a los cuales se les realizó al menos una cotización.</p>
                </>
              }>
              <StatCard.Title titleCard="SQL" />
              <StatCard.MainContent content={formatNumber(sql, 0, true)} />
              <StatCard.Summary
                description={
                  <>
                    <p>
                      Total metro cuadrado: <b>{formatNumber(sqlM3, 0, true)}</b>
                    </p>
                    <p>
                      Total por sql: <b>{formatNumber(sqlTotal, 0, true)}</b>
                    </p>
                  </>
                }
                className="dWidth"
                summary={`(${formatSummaryData(sqlM3)} m³ - ${formatSummaryData(sqlTotal)})`}
              />
            </StatCard.Cell>
            <StatCard.Cell
              description={
                <>
                  <b>Tasa de Interés por Servicios SG:</b>
                  <p>
                    Porcentaje de leads presupuestados, sobre el total de leads que se estableción contacto. (No se
                    tienen en cuenta los Leads por Atender)
                  </p>
                  <br />
                  <b>Tasa de Interés por Servicios SG - Pura:</b>
                  <p>
                    Porcentaje de leads presupuestados, sobre el total de leads Calificados. (No se tienen en cuenta los
                    Leads por Atender ni los Descartados)
                  </p>
                </>
              }>
              <StatCard.Percentage stat="SQL" from="Leads" percentage={formatNumber(sqlOverLeads * 100)} />
              <StatCard.Percentage stat="SQL" from="MQL" percentage={formatNumber(sqlOverMql * 100)} />
            </StatCard.Cell>
          </StatCard>
          <StatCard>
            <StatCard.Cell
              description={
                <>
                  <b>SQLs Ganados:</b>
                  <p>{`Lead que cierra un RP (Retiro Pactado). Abona seña y pasa su oportunidad a "Convertida".`}</p>
                </>
              }>
              <StatCard.Title titleCard="Ganados RP" />
              <StatCard.MainContent content={formatNumber(rp, 0, true)} />
              <StatCard.Summary
                description={
                  <>
                    <p>
                      Total metro cuadrado: <b>{formatNumber(rpM3, 0, true)}</b>
                    </p>
                    <p>
                      Total por rp validado: <b>{formatNumber(rpTotal, 0, true)}</b>
                    </p>
                  </>
                }
                className="dWidth"
                summary={`(${formatSummaryData(rpM3)} m³ - ${formatSummaryData(rpTotal)})`}
              />
            </StatCard.Cell>
            <StatCard.Cell
              description={
                <>
                  <b>Tasa de Conversión:</b>
                  <p>
                    Porcentaje de conversión a clientes sobre los leads que logramos contacto. (No se tienen en cuenta
                    los Leads por Atender)
                  </p>
                  <br />
                  <b>Tasa de Cierre - Efectividad:</b>
                  <p>Porcentaje de conversión a clientes sobre las oportunidades presupuestadas (SQLs)</p>
                </>
              }>
              <StatCard.Percentage stat="RP" from="Leads" percentage={formatNumber(rpOverLeads * 100)} />
              <StatCard.Percentage stat="RP" from="SQL" percentage={formatNumber(rpOverSql * 100)} />
              <StatCard.Percentage stat="RP" from="MQL" percentage={formatNumber(rpOverMql * 100)} />
            </StatCard.Cell>
          </StatCard>
          <StatCard.Cell
            description={
              <>
                <b>RPs Cancelados:</b>
                <p>
                  Cliente que confirma, se pacta el retiro, abona seña y por algún motivo no se realiza el RP. No
                  ingresan al depósito o nave sus objetos.
                </p>
                <br />
                <b>Tasa de Cancelamiento:</b>
                <p>Porcentaje que permite conocer cuantos RP cencelaron sobre los RPs confirmados.</p>
              </>
            }>
            <StatCard.Title titleCard="RP C" />
            <StatCard.MainContent content={formatNumber(rpc, 0, true)} />
            <StatCard.Percentage percentage={formatNumber(rpcPercentage * 100)} />
          </StatCard.Cell>
        </div>
      </div>
      <div className={styles.actionsBarContainer}>
        <TableActionBar>
          <TableActionBar.Wrapper>
            <TableActionBar.ButtonAction variant="csv" onClickButton={exportReport} disabled={sendingReport} />
            <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={cleanFilters} />
            <div style={{ borderLeft: '1.5px solid #e1e6ef ' }} />
            <TableActionBar.Filters titleFilter="Filtrar por">
              <Select
                className={styles.selectFilters}
                placeholder="Estados"
                options={optionsStatus}
                selected={filterBar.funnelState}
                onSelect={(option) => onSelectOption(option)}
              />
            </TableActionBar.Filters>
          </TableActionBar.Wrapper>

          <TableActionBar.ButtonAction
            titleButton="Crear oportunidad"
            onClickButton={() => modalActions.setCreateModalOpen(true, selectedCountry)}
          />
        </TableActionBar>
      </div>
      <LeadsTable
        prospects={prospects}
        countries={countries}
        searchFilters={searchFilters}
        handleSearch={handleSearch}
        sorter={sorter}
        handleSort={handleSort}
        handleFilter={handleFilter}
        handleRangePicker={handleDateRangeSearch}
        categoryFilter={filter}
        loading={loadingProspects || loadingConfig}
        pagination={leadsPagination}
        actions={menuActions}
        OptionsModalOpen={(leadId: number) => optionsModalActions.setOpenModal(true, leadId)}
        ActionsModalOpen={(leadId, leadFunnelState, interestReasonId) =>
          actionsModalActions.setOpenModal(true, leadId, leadFunnelState, interestReasonId)
        }
        ReasonsModalOpen={(leadId) => reasonsModalActions.setOpenModal(true, leadId)}
        setReactivateLead={(leadId) =>
          optionsModalActions.updateProspect({ id: leadId, transit: { reactivateDiscard: true } })
        }
        config={config}
      />
      <DeleteLead />
      <OptionsModal />
      <ActionsModal />
      <ReasonsModal />
      <ModalLeads />
    </>
  )
}

export default Container
