import { apiPrivate } from '@/api'
import { URL_BASE_PROFILE } from '@/endpoints'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'

export interface CreateAddressParams {
  user_id: number
  address: string
  number: string
  floor: string
  apartment: string
  postal_code: string
  original: string
  province: string
  city: string
}

interface PostalCode {
  PostalCode: string
  Allowed: boolean
}

interface DescriptionAPI {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedAt: string | null
  Original: string
  Address: string
  Number: string
  Floor: string
  Apartment: string
  MainAddress: boolean
  Latitude: number
  Longitude: number
  PostalCode: PostalCode
  InDeliveryRange: boolean
  City: string
  Province: string
  Comment: string
  Origin: string
  Country: string
}

export type Address = CamelCasedPropertiesDeep<DescriptionAPI>

interface Response {
  description: DescriptionAPI
  success: string
}

export default function create(params: CreateAddressParams): Promise<Address> {
  const formData = new FormData()
  formData.append('user_id', String(params.user_id))
  formData.append('address', params.address)
  formData.append('number', params.number)
  formData.append('floor', params.floor)
  formData.append('apartment', params.apartment)
  formData.append('postal_code', params.postal_code)
  if (params.original) formData.append('original', params.original)
  formData.append('province', params.province)
  formData.append('city', params.city)
  return apiPrivate
    .post(`${URL_BASE_PROFILE}/admin/address`, formData)
    .then((response: AxiosResponse<Response>) => {
      if (!response.data) throw new Error('No se pudo obtener las direcciones')

      const camelCaseResponse = camelcaseKeys(response.data.description, { deep: true })

      return camelCaseResponse
    })
    .catch((error) => {
      throw new Error('Failed to create address: ' + error.message)
    })
}
