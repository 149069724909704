/* eslint-disable camelcase */
import { SortDirection } from '../../../../components/DynamicTable/types/types'
import { apiPrivate } from '../../../../api'
import { URL_BASE_TRANSACTIONS } from '../../../../endpoints'
import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { APIIdentificationCode, APIRemoval, Photo, Removal } from './common'
import { Moment } from 'moment'
import { convertToMoment, formatDates } from '../../../utils'

export enum OperationStatus {
  CONFIRMED = 'confirmed',
  CREATED = 'created',
  CANCELLED = 'cancelled',
  DEPOSITED = 'deposited',
  VALIDATED = 'validated',
  PENDING_PAYMENT = 'pending_payment',
  AUTHORIZED = 'authorized',
  CLOSED_TOTALLY = 'closed_totally',
  CLOSED_PARTIALLY = 'closed_partially',
  IN_TRIP = 'in_trip',
  ASSIGNED_TO_TRIP = 'assigned_to_trip',
  RETURNED = 'returned',
  PROGRAMMED = 'programmed',
  PROGRAMMED_M = 'programmed_m',
  DRAFT = 'draft'
}

export enum OperationPaymentType {
  CREDIT_CARD_MP = 'Tarjeta de credito',
  OFFLINE = 'Pago Offline',
  CBU = 'CBU'
}

export type EnabledFormats = 'csv'

export type APIUser = {
  id: number
  last_name: string
  name: string
}

export type FormattedObject = {
  id: number
  status: string
  productName: string
  productId: number
  photos: Photo
  label: APIIdentificationCode
  m3: number
}

interface APIResponse {
  description: {
    operations: APIRemoval[]
    quantity: number
    quantity_clients_with_full_removals: number
  }
  success: boolean
}

interface Response {
  total: number
  operations: Removal[]
  quantityClientsWithFullRemovals: number
}

export type RemovalsSearchableParams = {
  id?: number
  userId?: number
  isFullRemoval?: boolean
}

export interface ListParams extends RemovalsSearchableParams {
  limit: number
  offset: number
  column?: string
  order?: SortDirection
  transportDatetimeFrom?: Moment
  transportDatetimeTo?: Moment
  createdAtFrom?: Moment
  createdAtTo?: Moment
  status?: string
  origin?: string
  format?: EnabledFormats
  withMetricQuantityFullRemovals: boolean
  reasonId?: string
}

const emptyCSVResponse = { operations: [], total: 0, quantityClientsWithFullRemovals: 0 }

export function list(params: ListParams): Promise<Response> {
  const {
    column,
    limit,
    offset,
    order,
    transportDatetimeTo,
    transportDatetimeFrom,
    status,
    id,
    userId,
    createdAtFrom,
    createdAtTo,
    origin,
    format,
    isFullRemoval,
    withMetricQuantityFullRemovals,
    reasonId
  } = params

  const axiosParams = {
    Column: column,
    Limit: limit,
    Offset: offset,
    Order: order,
    format,
    status,
    id,
    user_id: userId,
    origin,
    is_full_removal: isFullRemoval,
    with_metric_quantity_full_removals: withMetricQuantityFullRemovals,
    reason_id: reasonId,
    ...formatDates({
      dates: {
        transport_datetime_from: transportDatetimeFrom,
        transport_datetime_to: transportDatetimeTo,
        created_at_from: createdAtFrom,
        created_at_to: createdAtTo
      }
    })
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operation/removal`, { params: axiosParams })
    .then((response: AxiosResponse<APIResponse>) => {
      if (format === 'csv') return emptyCSVResponse
      if (!response.data.description) throw new Error('Hubo un problema al obtener las devoluciones')
      const camelOperation = camelcaseKeys(response.data.description.operations, { deep: true }).map((removal) => ({
        ...removal,
        address: {
          id: removal.address.id,
          addressString: removal.address.addressString,
          apartment: removal.address.apartment,
          city: removal.address.city,
          comment: removal.address.comment,
          floor: removal.address.floor,
          number: removal.address.number,
          postalCode: removal.address.postalCode,
          province: removal.address.province,
          street: removal.address.street
        },
        cardLastDigits: removal.cardLastDigits,
        cardType: removal.cardType,
        createdAt: convertToMoment(removal.createdAt),
        countryCode: removal.countryCode,
        datetime: convertToMoment(removal.datetime),
        discountCode: removal.discountCode,
        discountId: removal.discountId,
        fullAddressString: removal.fullAddressString,
        guid: removal.guid,
        id: removal.id,
        operationNumber: removal.operationNumber,
        paymentType: removal.paymentType,
        removalAuth: {
          ...removal.removalAuth,
          dateAuthRemoval: convertToMoment(removal.removalAuth.dateAuthRemoval)
        },
        status: removal.status,
        transaction: {
          ...removal.transaction,
          id: removal.transaction.id,
          transactionStatusId: removal.transaction.transactionStatusId
        },
        transportCost: removal.transportCost,
        objects: removal.objects?.map((obj) => ({
          ...obj,
          operationId: obj.operationID,
          objectId: obj.objectID
        })),
        objectCount: removal.objectCount,
        objectFoundCount: removal.objectFoundCount,
        objectNotFoundCount: removal.objectNotFoundCount,
        objectReturnedCount: removal.objectReturnedCount,
        objectReturnedM3: removal.objectReturnedM3,
        objectToReturnCount: removal.objectToReturnCount,
        objectToReturnM3: removal.objectToReturnM3,
        objectTotalM3: removal.objectTotalM3,
        user: removal.user,
        agentName: removal.agentName,
        origin: removal.origin
      }))
      return {
        operations: camelOperation,
        total: response.data.description.quantity,
        quantityClientsWithFullRemovals: response.data.description.quantity_clients_with_full_removals
      }
    })
    .catch((err) => {
      throw err
    })
}
