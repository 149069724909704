import React, { useMemo, useState } from 'react'
import styles from './singleRemoval.module.scss'
import InfoBanner, { Item } from '../../../components/infoBanner'
import SmallTabs, { Tab } from '../../../components/smallTabs'
import { Check, ExclamationIcon } from '../../../icons'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column } from '../../../components/DynamicTable/types/types'
import { SingleRemovalState } from '../types/singleRemoval'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { formatNumber } from '../../../utils/formatNumber'
import { STATUS_VALUES } from '../../../common/operationsTable/constants'
import clsx from 'clsx'
import { Tooltip } from 'antd'
import { Country } from '../../../components/CountrySelector/constants/constants'
import { CopyButton } from '../../../components/CopyButton'
import SummaryViewCard from './SummaryView'
import { RemovalObject } from '../../../projectApi/TransactionHandler/Removals/details'

enum ViewTab {
  OBJECTS = 'objects'
}

type SingleRemovalProps = SingleRemovalState & {
  countries: Country[]
  removalId: number
  error: string
}

const SingleRemoval: React.FC<SingleRemovalProps> = ({ removalDetails, loading, countries, removalId }) => {
  const [activeTab] = useState<ViewTab>(ViewTab.OBJECTS)
  const { operationCountryCode, objects, transportCost, objectsM3, discountCode, baseInfo, minCost, tollCostInCents } =
    removalDetails
  const currencySymbol = useMemo(
    () => countries.find((c) => c.code === operationCountryCode)?.currency,
    [operationCountryCode]
  )

  const columns: Column<RemovalObject>[] = [
    {
      key: 'objectId',
      label: 'ID'
    },
    {
      key: 'object',
      label: 'ID - Producto',
      minWidth: '150px',
      alignment: 'left',
      renderDataCell: ({ object: { product } }) => (
        <span style={{ textAlign: 'left' }}>
          {product.realId} - {product.prodByCountry.name}
        </span>
      )
    },
    {
      key: 'floorsByStairsCost',
      label: <span style={{ textAlign: 'center' }}>Escaleras</span>,
      renderDataCell: ({ floorsByStairsCost }) => {
        return formatNumber(floorsByStairsCost)
      }
    },
    {
      key: 'packagingCost',
      label: <span style={{ textAlign: 'center' }}>Embalaje</span>,
      renderDataCell: ({ packagingCost }) => {
        return formatNumber(packagingCost)
      }
    },
    {
      key: 'assemblyCost',
      label: <span style={{ textAlign: 'center' }}>Armado</span>,
      renderDataCell: ({ assemblyCost }) => {
        return formatNumber(assemblyCost)
      }
    }
  ]

  if (loading)
    return (
      <div className={styles.placeholder}>
        <LoadingIndicator />
      </div>
    )
  if (!removalId)
    return (
      <div className={styles.placeholder}>
        <span>Hubo un error buscando la información.</span>
      </div>
    )

  const validated = baseInfo.status === STATUS_VALUES.VALIDATED
  const getBadge = () => {
    const { text, Icon, tooltip } = {
      text: validated ? 'Validada' : 'No validada',
      tooltip: validated
        ? 'Esta operación ha sido validada y los valores de los objetos ya han sido confirmados.'
        : 'Dado que esta operación aún no ha sido validada, los valores de los objetos no han sido confirmados.',
      Icon: validated ? <Check width="14px" height="14px" fill="#969696" /> : <ExclamationIcon />
    }

    return (
      <Tooltip title={tooltip} placement={'bottom'}>
        <div className={clsx(styles.validationBadge, validated && styles.validated)}>
          {Icon}
          <span>{text}</span>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={styles.singleRemoval}>
      <InfoBanner title="Detalle">
        <Item heading="ID Cliente" value={baseInfo.id} />
        <Item heading="Nombre" value={baseInfo.user.name} />
        <Item heading="Apellido" value={baseInfo.user.lastName} />
        <Item
          heading="F. Operación"
          value={baseInfo.createdAt ? baseInfo.createdAt.format('dd DD[-]MM[-]YY[ ]HH[:]mm') : '-'}
        />
        <Item
          heading="F. Servicio"
          value={baseInfo.datetime ? baseInfo.datetime.format('dd DD[-]MM[-]YY[ ]HH[:]mm') : '-'}
        />
        <Item heading="Dirección completa" value={baseInfo.fullAddress}>
          <CopyButton textToCopy={baseInfo.fullAddress} tooltipText="Copiado!" />
        </Item>
      </InfoBanner>

      <div className={styles.tabBar}>
        <SmallTabs activeTabId={activeTab} onChange={() => {}}>
          <Tab id={ViewTab.OBJECTS}>Objetos</Tab>
        </SmallTabs>
        {getBadge()}
      </div>
      <div className={styles.overview}>
        <SummaryViewCard
          quotedTransportCost={transportCost + (tollCostInCents ?? 0) / 100}
          currencySymbol={currencySymbol}
          minCost={minCost}
          discountCode={discountCode}
          objects={objects}
          totalM3={objectsM3}
        />
      </div>
      <DynamicTable
        columns={columns}
        rows={objects}
        loading={loading}
        keyExtractor={(entity) => entity.objectId}
        customClassNames={{
          table: styles.customTableProps
        }}
        customStrings={{
          emptyState: 'No se encontraron objetos cargados.'
        }}
      />
    </div>
  )
}

export default SingleRemoval
