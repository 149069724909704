import {
  DeleteObjectTypes,
  GetDeleteObjectReasonsTypes,
  SetExplanation,
  SetModalVisibility,
  SetReasonId,
  SettersDeleteObjectModalTypes
} from '../types/deleteObject'
import { AppThunk } from '@/store'
import { handleErrorsWithAction } from '@/utils/HandleErrors'
import { API } from '@/projectApi'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '@/utils/notifications'
import ObjectsTableActionCreators from './objectsTable'

const deleteObjectActionsCreator = {
  setModalVisibility: ({ visible, objectId }: { visible: boolean; objectId?: number }): SetModalVisibility => ({
    type: SettersDeleteObjectModalTypes.SET_MODAL_VISIBILITY,
    payload: {
      visible,
      objectId
    }
  }),

  setExplanation: (explanation: string): SetExplanation => ({
    type: SettersDeleteObjectModalTypes.SET_EXPLANATION,
    payload: {
      explanation
    }
  }),

  setReasonId: (reasonId: number): SetReasonId => ({
    type: SettersDeleteObjectModalTypes.SET_REASON_ID,
    payload: {
      reasonId
    }
  }),

  getDeleteObjectReasons: (): AppThunk => async (dispatch) => {
    dispatch({
      type: GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_REQUEST
    })

    const toastId = setToastLoading('Cargando motivos de exclusion')

    try {
      const { reasons } = await API.ObjectAdministration.DeleteReasons.search()

      dispatch({
        type: GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_SUCCESS,
        payload: {
          reasons
        }
      })
      setToastSuccessUpdate(toastId, { render: 'Motivos de exclusión cargados correctamente' })
    } catch (error) {
      setToastErrorUpdate(toastId, {
        render: handleErrorsWithAction(error, GetDeleteObjectReasonsTypes.GET_DELETE_OBJECT_REASONS_FAILURE, dispatch)
      })
    }
  },

  deleteObject:
    ({ objectId, explanation, reasonId }: { objectId: number; explanation: string; reasonId: number }): AppThunk =>
    async (dispatch, getState) => {
      dispatch({ type: DeleteObjectTypes.DELETE_OBJECT_REQUEST })
      const toastId = setToastLoading('Eliminando objeto')

      try {
        await API.ObjectAdministration.Corporate.Object.delete({
          objectId,
          description: explanation,
          deletedReasonId: reasonId
        })

        const { pagination, categoryFilter, searchFilters, sort } = getState().CorporativeObjects.table

        dispatch({ type: DeleteObjectTypes.DELETE_OBJECT_SUCCESS })
        dispatch(ObjectsTableActionCreators.getObjects({ pagination, categoryFilter, searchFilters, sort }))
        setToastSuccessUpdate(toastId, { render: 'Objeto eliminado correctamente' })
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: handleErrorsWithAction(error, DeleteObjectTypes.DELETE_OBJECT_FAILURE, dispatch)
        })
      }
    }
}

export default deleteObjectActionsCreator
