import { apiPrivate } from '@/api'
import { URL_BASE_OBJECTS } from '@/endpoints'
import { AxiosResponse } from 'axios'

export interface APIResponse {
  paging: Paging
  results: Reason[]
}

export interface Paging {
  total: number
  limit: number
  offset: number
}

export interface Reason {
  id: number
  reason: string
}

interface Response {
  reasons: Reason[]
}

export function search(): Promise<Response> {
  return apiPrivate
    .get(`${URL_BASE_OBJECTS}/object-deleted-reasons/search`)
    .then((response: AxiosResponse<APIResponse>) => {
      return { reasons: response.data.results }
    })
    .catch((err) => {
      throw err
    })
}
