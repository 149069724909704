import moment from 'moment'
import { TimeInterval } from '../types/calendar'
import { pad } from '@/sections/Deposits/component/subComponents/utils'

export const calculateDaysAgo = (date: string): string => {
  const today = moment()
  const startDate = moment(date)
  const daysDifference = today.diff(startDate, 'days')

  if (daysDifference <= 60) {
    return `Hace ${daysDifference} días`
  } else if (daysDifference > 60) {
    const monthsDifference = today.diff(startDate, 'months')
    return `Hace ${monthsDifference} meses`
  }

  return ''
}

export const getTimeIntervalLabel = (timeInterval?: TimeInterval) => {
  if (!timeInterval) return ''
  return timeInterval.start + ':' + pad('') + ' - ' + timeInterval.end + ':' + pad('')
}
