import { Reducer } from 'redux'
import {
  GENERAL_DATA_CLEAN_STATE,
  GeneralDataAction,
  GeneralDataState,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_PRODUCT_BY_ID_FAILURE,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_SUCCESS,
  SET_CATEGORY_SELECTED,
  SET_DIMENSIONS_HEIGHT,
  SET_DIMENSIONS_LENGTH,
  SET_DIMENSIONS_WEIGHT,
  SET_DIMENSIONS_WIDTH,
  SET_DISASSEMBLE_TIME,
  SET_IS_PRODUCT_SEARCHABLE,
  SET_MAX_DEVIATION,
  SET_MIN_DEVIATION,
  SET_MIN_PHOTOS,
  SET_MULTIPLIER,
  SET_PACKAGING_TIME,
  SET_SOLUTIONS_SELECTED,
  GET_SOLUTIONS_FAILURE,
  GET_SOLUTIONS_REQUEST,
  GET_SOLUTIONS_SUCCESS,
  SET_PRODUCT_SUGGESTED,
  SET_OPEN_DETAIL_MODAL,
  GetProductSuggestionEnum,
  GetCatalogByProductEnum,
  GetProductByDescriptionEnum
} from '../types/GeneralData'
import {
  CREATE_NEW_PRODUCT_BY_CATALOG_FAILURE,
  CREATE_NEW_PRODUCT_BY_CATALOG_REQUEST,
  CREATE_NEW_PRODUCT_BY_CATALOG_SUCCESS,
  CREATE_NEW_PRODUCT_FAILURE,
  CREATE_NEW_PRODUCT_REQUEST,
  CREATE_NEW_PRODUCT_SUCCESS,
  SET_EDIT_MODE,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS
} from '../types/common'

const initialState: GeneralDataState = {
  categories: [],
  catalogCategory: null,
  solutions: [],
  multiplier: '',
  disassembleTime: '',
  packagingTime: '',
  dimensionsWeight: '',
  dimensionsLength: '',
  dimensionsWidth: '',
  dimensionsHeight: '',
  minDeviation: '',
  maxDeviation: '',
  minPhotos: '1',
  error: '',
  isProductSearchable: false,
  categorySelected: null,
  solutionsSelected: [],
  generalCountriesFiltered: [],
  isProductCreatedSuccessfully: false,
  isEditMode: false,
  guid: '',
  loading: false,
  loadingSaveProduct: false,
  productSuggestions: [],
  selectedProduct: '',
  isLoadingCatalogProducts: false,
  products: [],
  total: 0,
  openDetailModal: false,
  productDetail: null,
  isProductDetailLoading: false
}

export const GeneralDataReducer: Reducer<GeneralDataState, GeneralDataAction> = (
  state = initialState,
  action
): GeneralDataState => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return {
        ...state
      }

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories
      }
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case GET_SOLUTIONS_REQUEST:
      return {
        ...state
      }
    case GET_SOLUTIONS_SUCCESS:
      return {
        ...state,
        solutions: action.payload.solutions
      }
    case GET_SOLUTIONS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case SET_MULTIPLIER:
      return {
        ...state,
        multiplier: action.payload.multiplier
      }
    case SET_DISASSEMBLE_TIME:
      return {
        ...state,
        disassembleTime: action.payload.disassembleTime
      }
    case SET_PACKAGING_TIME:
      return {
        ...state,
        packagingTime: action.payload.packagingTime
      }
    case SET_DIMENSIONS_WEIGHT:
      return {
        ...state,
        dimensionsWeight: action.payload.dimensionsWeight
      }
    case SET_DIMENSIONS_LENGTH:
      return {
        ...state,
        dimensionsLength: action.payload.dimensionsLength
      }
    case SET_DIMENSIONS_WIDTH:
      return {
        ...state,
        dimensionsWidth: action.payload.dimensionsWidth
      }
    case SET_DIMENSIONS_HEIGHT:
      return {
        ...state,
        dimensionsHeight: action.payload.dimensionsHeight
      }
    case SET_MIN_DEVIATION:
      return {
        ...state,
        minDeviation: action.payload.minDeviation
      }

    case SET_MAX_DEVIATION:
      return {
        ...state,
        maxDeviation: action.payload.maxDeviation
      }
    case SET_MIN_PHOTOS:
      return {
        ...state,
        minPhotos: action.payload.minPhotos
      }

    case SET_IS_PRODUCT_SEARCHABLE:
      return {
        ...state,
        isProductSearchable: action.payload.isProductSearchable
      }

    case SET_CATEGORY_SELECTED:
      return {
        ...state,
        categorySelected: action.payload.categorySelected
      }
    case GENERAL_DATA_CLEAN_STATE:
      return initialState

    case SET_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload.isEditMode
      }
    case GET_PRODUCT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_PRODUCT_BY_ID_SUCCESS: {
      const { product } = action.payload

      return {
        ...state,
        catalogCategory: product.catalogCategory,
        categorySelected: product.categories.map((cat) => ({ value: cat.id, label: cat.description }))[0],
        solutionsSelected: product.solutions.map((sol) => ({ value: sol.id, label: sol.description })),
        dimensionsHeight: product.heightInCm.toString(),
        dimensionsLength: product.lengthInCm.toString(),
        dimensionsWeight: (product.weightInGr / 1000).toString(),
        dimensionsWidth: product.widthInCm.toString(),
        disassembleTime: product.unpackagingTimeInMinutes.toString(),
        isProductSearchable: product.showInSearch,
        maxDeviation: Math.round(product.maxVolumeDeviation * 100).toString(),
        minDeviation: Math.round(product.minVolumeDeviation * 100).toString(),
        multiplier: product.multiplier.toString(),
        packagingTime: product.packagingTimeInMinutes.toString(),
        minPhotos: product.photoMin.toString(),
        guid: product.guid,
        loading: false
      }
    }
    case GET_PRODUCT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case CREATE_NEW_PRODUCT_REQUEST:
      return {
        ...state,
        loadingSaveProduct: true
      }
    case CREATE_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingSaveProduct: false
      }
    case CREATE_NEW_PRODUCT_FAILURE:
      return {
        ...state,
        loadingSaveProduct: false,
        error: action.payload.error.toString()
      }
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loadingSaveProduct: true
      }
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingSaveProduct: false
      }
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingSaveProduct: false,
        error: action.payload.error.toString()
      }
    case SET_SOLUTIONS_SELECTED:
      return {
        ...state,
        solutionsSelected: action.payload.solutionsSelected
      }
    case GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_REQUEST:
      return {
        ...state
      }
    case GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        productSuggestions: action.payload.productSuggestions
      }
    case GetProductSuggestionEnum.GET_PRODUCT_SUGGESTIONS_FAILURE:
      return {
        ...state
      }
    case SET_PRODUCT_SUGGESTED:
      return {
        ...state,
        selectedProduct: action.payload.selectedProduct
      }
    case GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingCatalogProducts: true
      }
    case GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        total: action.payload.total,
        isLoadingCatalogProducts: false
      }
    case GetCatalogByProductEnum.GET_CATALOG_BY_PRODUCT_FAILURE:
      return {
        ...state,
        isLoadingCatalogProducts: false
      }
    case SET_OPEN_DETAIL_MODAL:
      return {
        ...state,
        openDetailModal: action.payload.openDetailModal
      }
    case GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_REQUEST:
      return {
        ...state,
        isProductDetailLoading: true
      }
    case GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_SUCCESS:
      return {
        ...state,
        productDetail: action.payload.product,
        isProductDetailLoading: false
      }
    case GetProductByDescriptionEnum.GET_PRODUCT_BY_DESCRIPTION_FAILURE:
      return {
        ...state,
        isProductDetailLoading: false
      }
    case CREATE_NEW_PRODUCT_BY_CATALOG_REQUEST:
      return {
        ...state
      }
    case CREATE_NEW_PRODUCT_BY_CATALOG_SUCCESS:
      return {
        ...state
      }
    case CREATE_NEW_PRODUCT_BY_CATALOG_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    default:
      return state
  }
}
