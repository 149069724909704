import React from 'react'
import { Steps } from '../constants'
import CreditCardIcon from '../../../icons/creditCard'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { PaymentMethod } from '../../../projectApi/PaymentCollector/PaymentMethod/search'
import styles from './menuCard.module.scss'
import StepOption from './StepOption'
import searchUserActionCreators from '../actionCreators/SearchUser'
import { Billing } from '../../../projectApi/TransactionHandler/Colppy/billingsByUserId'
import { UserCollpyWithCountry } from '../types/BillingPayment'
import { SelectedCreditCard } from '../utils'

interface MenuCardProps {
  onChangeRadio: (e: RadioChangeEvent) => void
  setStep: React.Dispatch<React.SetStateAction<Steps>>
  paymentMethod?: PaymentMethod | null
  searchUserActions: typeof searchUserActionCreators
  optionRadio: number
  step: number
  userColppyInfo: UserCollpyWithCountry | null
  selectedBillings: Billing[]
  loading: boolean
  totalToPay: number
}

const MenuCard = ({
  onChangeRadio,
  setStep,
  optionRadio,
  paymentMethod,
  searchUserActions,
  userColppyInfo,
  selectedBillings,
  loading,
  totalToPay
}: MenuCardProps) => {
  const handlePayDebts = async ({
    userId,
    billings,
    paymentSelected
  }: {
    userId: number
    billings: Billing[]
    paymentSelected: PaymentMethod
  }) => {
    const result = await searchUserActions.payBillings({
      userId,
      paymentMethodToken: paymentSelected.token,
      amountInCents: Math.round(totalToPay * 100),
      country: userColppyInfo?.country || 'ARG',
      concepts: billings.map((bill) => ({
        billingColppyId: Number(bill.billingColppyId),
        total: bill.total,
        entityId: Number(bill.billingId || bill.billingColppyId),
        entityType: bill.entityType,
        status: bill.status
      }))
    })
    if (!result) return false
    setStep(Steps.FINISH_SUMMARY)
    return true
  }

  return (
    <div>
      <h3>¿Cómo realiza el pago?</h3>
      <div>
        <Radio.Group className={styles.itemContainer} onChange={onChangeRadio} value={optionRadio}>
          {paymentMethod && (
            <>
              <StepOption
                Icon={SelectedCreditCard(paymentMethod.paymentMethod.cardType)}
                cardType={paymentMethod?.paymentMethod?.cardType?.replace('_', ' ').toUpperCase()}
                fourDigits={paymentMethod?.paymentMethod?.lastFourDigits}
                month={paymentMethod.paymentMethod?.month}
                year={paymentMethod.paymentMethod?.year.toString().slice(2)}
                option={Steps.PAYMENT}
              />
              <StepOption Icon={<CreditCardIcon />} description="Seleccionar otra tarjeta" option={Steps.SELECT_CARD} />
            </>
          )}
          <StepOption
            Icon={<CreditCardIcon />}
            description="Nueva tarjeta de crédito o débito"
            option={Steps.ADD_CARD}
          />
        </Radio.Group>
      </div>
      <div className={styles.nextPageContainer}>
        <button
          disabled={loading || !totalToPay}
          className={loading || !totalToPay ? styles.buttonDisable : ''}
          onClick={() => {
            if (Steps.PAYMENT === optionRadio) {
              if (userColppyInfo?.id && paymentMethod)
                handlePayDebts({
                  billings: selectedBillings,
                  paymentSelected: paymentMethod,
                  userId: Number(userColppyInfo.id)
                })
            } else {
              setStep(optionRadio)
            }
          }}>
          Continuar
        </button>
      </div>
    </div>
  )
}

export default MenuCard
