import React from 'react'
import styles from '../SalesRequestView.module.scss'
import notAvailableImage from '../../../Objects/images/notAvailableImage.jpg'
import { DataDisplay, DataDisplayBox } from '../subcomponents/DataDisplayBox'
import { Button } from 'antd'
import ImageTooltip from '../../../DepositsValidation/components/ImageTooltip'
import { formatNumber } from '../../../../utils/formatNumber'
import { SalesRequest } from '../../../../projectApi/Sales/SalesRequest/list'
import { SaleActions } from '../../../../projectApi/Sales/reasons'
import CurrencyInput from 'react-currency-input-field'
import clsx from 'clsx'
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps'
import { AcceptParams } from '../../../../projectApi/Sales/SalesRequest/accept'
import { CounterProposal } from './CounterProposal'
import { MAX_PRICE_LENGTH } from '../../utils'

export interface WorkingViewProps {
  isCounterProposalActive: boolean
  salesRequest: SalesRequest | null
  saleCostArg: number
  summary: DataDisplay[]
  publicationPrice: number
  setPublicationPrice: (price: string) => void
  setOpenCounterProposalView: () => void
  setCloseCounterProposalView: () => void
  handleOpenModal: (action: SaleActions) => void
  handleAcceptProposal: ({
    pvp,
    saleCost,
    saleMargin,
    existExpressSale,
    expressSaleCost,
    expressSaleMargin,
    pve
  }: AcceptParams) => void
  SALE_COST: number
  SALE_MARGIN: number
  handleCreateCounterProposal: ({
    isExpressSale,
    prc,
    pve,
    pvp,
    saleCost,
    saleMargin,
    expressSaleCost,
    expressSaleMargin
  }: {
    isExpressSale: boolean
    prc: number
    pve?: number
    pvp: number
    saleCost: number
    saleMargin: number
    expressSaleCost?: number
    expressSaleMargin?: number
  }) => void
}

export const WorkingView: React.FC<WorkingViewProps> = ({
  salesRequest,
  summary,
  saleCostArg,
  publicationPrice,
  isCounterProposalActive,
  setPublicationPrice,
  handleOpenModal,
  handleAcceptProposal,
  SALE_COST,
  SALE_MARGIN,
  handleCreateCounterProposal,
  setCloseCounterProposalView,
  setOpenCounterProposalView
}) => {
  const obj = salesRequest?.object
  const totalM3 = obj && (obj.lengthInCm * obj?.widthInCm * obj?.heightInCm) / 1_000_000

  return (
    <>
      <div className={styles.mainContent}>
        <div className={styles.product}>
          {!obj?.photoUrl ? (
            <img src={notAvailableImage} />
          ) : (
            <div className={styles.img}>
              <ImageTooltip
                src={obj.photoUrl}
                alt={''}
                maxWidth={'50vw'}
                maxHeight={'50vh'}
                imgWidth={'200px'}
                imgHeight={'200px'}
              />
            </div>
          )}
          <div>
            <p>
              <b>ID:</b> {salesRequest?.object.id}
            </p>
            <div className={styles.productDescription}>
              <span style={{ fontWeight: 600 }}>{salesRequest?.object.description}</span>
              <span>
                <b>Producto: </b>
                {salesRequest?.object.product.name}
              </span>
              <span>
                <b>
                  {obj?.widthInCm}x{obj?.heightInCm}x{obj?.lengthInCm}(cm)
                </b>{' '}
                = {totalM3 && formatNumber(totalM3)}m3
              </span>
              <span>
                <b>Coef.Multp</b> x{obj?.product.multiplier}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.costs}>
          <InputPrice
            value={salesRequest ? salesRequest.prc : 0}
            onChange={() => {}}
            label={'Precio Cliente (PRC)'}
            disabled
          />
          <InputPrice
            value={publicationPrice}
            onChange={(value) => setPublicationPrice(value || '0')}
            label={'Precio de publicación'}
          />

          <DataDisplayBox data={summary} />
        </div>
      </div>
      {isCounterProposalActive ? (
        <div>
          <CounterProposal
            setCloseCounterProposalView={setCloseCounterProposalView}
            saleCostArg={saleCostArg}
            publicationPrice={publicationPrice}
            salesRequest={salesRequest}
            handleCreateCounterProposal={handleCreateCounterProposal}
            handleAcceptProposal={handleAcceptProposal}
            SALE_COST={SALE_COST}
            SALE_MARGIN={SALE_MARGIN}
          />
        </div>
      ) : (
        <div className={styles.buttonSection}>
          <Button type="ghost" className={styles.buttonReject} onClick={() => handleOpenModal(SaleActions.REJECT)}>
            Rechazar
          </Button>
          <Button type="ghost" className={styles.buttonCounterProposal} onClick={setOpenCounterProposalView}>
            Ofertar
          </Button>
          <Button
            disabled={publicationPrice === 0}
            type="primary"
            className={clsx(styles.acceptButton, publicationPrice === 0 && styles.disabledAcceptButton)}
            onClick={() =>
              handleAcceptProposal({
                pvp: publicationPrice,
                saleCost: SALE_COST,
                saleMargin: SALE_MARGIN,
                existExpressSale: false
              })
            }>
            Aceptar
          </Button>
        </div>
      )}
    </>
  )
}

interface InputPriceProps {
  value: number | string
  label?: string
  onChange: (value?: string, name?: string, values?: CurrencyInputOnChangeValues) => void
  disabled?: boolean
}
// TODO: make a global component from this number input with storybook and generic props
export const InputPrice: React.FC<InputPriceProps> = ({ label, value, onChange, disabled }) => {
  return (
    <div style={{ width: '100%' }}>
      <p style={{ margin: '5px 0', color: '#49486E' }}>{label}</p>
      <CurrencyInput
        disabled={disabled}
        prefix="$"
        maxLength={MAX_PRICE_LENGTH}
        value={value || 0}
        defaultValue={value}
        groupSeparator={','}
        decimalSeparator={'.'}
        decimalScale={2}
        decimalsLimit={2}
        intlConfig={{ locale: 'en-US' }}
        onValueChange={onChange}
        className={clsx(styles.inputNumber, disabled && styles.inputDisabled)}
      />
    </div>
  )
}
