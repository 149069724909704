import { NavIconsEnum } from '../Nav/types'
import { Permissions } from '../UserLogged/permissions'
import { RecientVisitNameEnum } from './types'

export const logVisit = (name: string, url: string, permission: Permissions) => {
  const recentVisits = localStorage.getItem('recentVisits')
  const visits = recentVisits ? JSON.parse(recentVisits) : []
  const exists = visits.find((visit: any) => visit.url === url)
  if (exists) return
  if (visits.length >= 3) visits.pop()
  visits.unshift({ name, url, permission })
  localStorage.setItem('recentVisits', JSON.stringify(visits))
}

export const historyIconManager = (title: string): NavIconsEnum => {
  switch (title) {
    case RecientVisitNameEnum.DONATION_TABLE:
    case RecientVisitNameEnum.SALES_REQUEST_TABLE:
    case RecientVisitNameEnum.CATEGORIES_TABLE:
    case RecientVisitNameEnum.REQUESTS_TABLE:
      return NavIconsEnum.GRID

    case RecientVisitNameEnum.PRODUCTS_TABLE:
    case RecientVisitNameEnum.ATTRIBUTES_TABLE:
    case RecientVisitNameEnum.CORPORATIVE_PRODUCTS_TABLE:
      return NavIconsEnum.SOCIAL_DROPBOX

    case RecientVisitNameEnum.OBJECTS_TABLE:
    case RecientVisitNameEnum.CORPORATIVE_OBJECTS_TABLE:
      return NavIconsEnum.DIAMOND

    case RecientVisitNameEnum.REMOVAL_TABLE:
    case RecientVisitNameEnum.COMPLAINTS_TABLE:
    case RecientVisitNameEnum.PROPOSALS_TABLE:
    case RecientVisitNameEnum.REMOVAL_VALIDATION:
    case RecientVisitNameEnum.SERVICES_TABLE:
    case RecientVisitNameEnum.DISCOUNTS_TABLE:
    case RecientVisitNameEnum.DISCOUNT_DAYS_TABLE:
    case RecientVisitNameEnum.DISCOUNT_DATES_TABLE:
    case RecientVisitNameEnum.DEPOSITS_TABLE:
    case RecientVisitNameEnum.PICKINGS_TABLE:
    case RecientVisitNameEnum.DEPOSIT_VALIDATION:
    case RecientVisitNameEnum.CORPORATIVE_DEPOSITS_TABLE:
      return NavIconsEnum.LIST

    case RecientVisitNameEnum.TRIPS_TABLE:
      return NavIconsEnum.PLANE

    case RecientVisitNameEnum.USERS_TABLE:
    case RecientVisitNameEnum.CORPORATE_USERS_TABLE:
      return NavIconsEnum.USER

    case RecientVisitNameEnum.LEADS_TABLE:
      return NavIconsEnum.SPEECH

    case RecientVisitNameEnum.BILLING:
    case RecientVisitNameEnum.BILLING_PAYMENTS:
    case RecientVisitNameEnum.SUBSCRIPTIONS_TABLE:
      return NavIconsEnum.DOCS

    case RecientVisitNameEnum.ADDRESS_TABLE:
    case RecientVisitNameEnum.LOCATIONS:
    case RecientVisitNameEnum.CORPORATIVE_LOCATIONS_TABLE:
      return NavIconsEnum.LOCATION_PIN

    case RecientVisitNameEnum.AGENTS_TABLE:
      return NavIconsEnum.EARPHONES_ALT

    case RecientVisitNameEnum.FLAGS_TABLE:
      return NavIconsEnum.FLAG

    case RecientVisitNameEnum.OPERATION_DAYS_TABLE:
      return NavIconsEnum.CALENDAR

    case RecientVisitNameEnum.PENDING_USERS_TABLE:
      return NavIconsEnum.ENVELOPE

    case RecientVisitNameEnum.ORIGINS_TABLE:
      return NavIconsEnum.MAGNET

    case RecientVisitNameEnum.REGISTER_OBJECTS_TABLE:
      return NavIconsEnum.NOTE

    case RecientVisitNameEnum.PHOTOGRAPH_OBJECTS_TABLE:
      return NavIconsEnum.CAMERA

    case RecientVisitNameEnum.VEHICLES_TABLE:
    case RecientVisitNameEnum.PROVIDERS_TABLE:
    case RecientVisitNameEnum.SHIPPERS_TABLE:
      return NavIconsEnum.DIRECTION

    case RecientVisitNameEnum.TRANSACTIONS_TABLE:
      return NavIconsEnum.CALCULATOR

    case RecientVisitNameEnum.MOVEMENTS_TABLE:
      return NavIconsEnum.BOOK_OPEN

    case RecientVisitNameEnum.PAYMENTS_TABLE:
      return NavIconsEnum.TAG

    case RecientVisitNameEnum.PAYMENTS_HISTORY_TABLE:
      return NavIconsEnum.NOTEBOOK

    case RecientVisitNameEnum.PRICE_BANDS_TABLE:
      return NavIconsEnum.MENU

    case RecientVisitNameEnum.DEVELOPMENT_TABLE:
      return NavIconsEnum.GAME_CONTROLLER

    case RecientVisitNameEnum.DASHBOARD_TABLE:
      return NavIconsEnum.CHART

    default:
      return NavIconsEnum.LIST
  }
}
