import React, { useMemo } from 'react'
import { RemovalMetric } from '../types/metrics'
import { StatusCardContainer } from '../../../components/StatusCard/subComponents/StatusCardContainer'
import StatusCard, { StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import styles from './RemovalMetrics.module.scss'
import { RemovalsTableSearchFilter, RemovalsTableSearchFilterKey, RemovalsTableSearchKey } from '../types/removalsTable'
import clsx from 'clsx'
import { formatNumber } from '../../../utils/formatNumber'

export type RemovalMetricsProps = {
  metrics: RemovalMetric[]
  fetchingMetrics: boolean
  quantityClientsWithFullRemovals: number
  handleSearchFilters: (key: RemovalsTableSearchFilterKey, newValue: string) => void
  searchFilters: RemovalsTableSearchFilter[]
  loadingRemovals: boolean
}

const TooltipContent = ({ metric }: { metric: string }) => {
  const tooltipMessage =
    metric === mapOfRemovalsMetrics.total
      ? 'Incluye únicamente los estados Programada, Programada (M), Autorizada, En viaje, Devuelta'
      : `Cantidad total de las devoluciones con estado: ${
          metric === mapOfRemovalsMetrics.programmedQuantity ? `${metric} y Programada (M)` : metric
        }`

  return <span>{tooltipMessage}</span>
}

export const RemovalMetrics: React.FC<RemovalMetricsProps> = ({
  metrics,
  fetchingMetrics,
  quantityClientsWithFullRemovals,
  handleSearchFilters,
  searchFilters,
  loadingRemovals
}) => {
  const extraRemovalMetrics = 1 // quantityClientsWithFullRemovals

  const lengthSkeletons = Object.keys(mapOfRemovalsMetricsTypes).length + extraRemovalMetrics

  const isFullRemovalClicked = useMemo(
    () => searchFilters.find(({ key }) => key === RemovalsTableSearchKey.IS_FULL_REMOVAL)?.text === 'true',
    [searchFilters]
  )

  const handleClickMetric = () => {
    if (loadingRemovals) return
    handleSearchFilters(RemovalsTableSearchKey.IS_FULL_REMOVAL, 'true')
  }

  return (
    <StatusCardContainer fetchingMetrics={fetchingMetrics} lengthSkeletons={lengthSkeletons}>
      {metrics.map(({ id, metric, quantity }) => {
        if (!metric) return null
        const isReturnedQuantity = id === 'returnedQuantity'
        return (
          <StatusCard
            key={id}
            type={mapOfRemovalsMetricsTypes[id]}
            titleCard={metric}
            unit="m³"
            percentage={
              isReturnedQuantity
                ? formatNumber(metrics.find((x) => x.id === 'm3Returned')?.quantity || 0, 2, true)
                : undefined
            }
            total={formatNumber(quantity, 0, true)}
            customClassname={styles.metricsCard}
            description={
              metric === mapOfRemovalsMetrics.total || metric === mapOfRemovalsMetrics.programmedQuantity ? (
                <TooltipContent metric={metric} />
              ) : undefined
            }
            tooltipIconColor={metric === mapOfRemovalsMetrics.total ? '#FFF' : ''}
          />
        )
      })}
      <StatusCard
        type={'info'}
        titleCard={'Devoluciones totales'}
        unit="m³"
        total={quantityClientsWithFullRemovals}
        percentage={formatNumber(metrics?.find((x) => x.id === 'm3FullRemovals')?.quantity || 0, 2, true)}
        customClassname={clsx(styles.metricsCard, loadingRemovals && styles.cursorDefault)}
        clicked={isFullRemovalClicked}
        onClick={handleClickMetric}
      />
    </StatusCardContainer>
  )
}

const mapOfRemovalsMetricsTypes: { [key: string]: StatusCardTypes } = {
  total: 'infoSolid',
  returnedQuantity: 'danger',
  programmedQuantity: 'info',
  authorizedQuantity: 'success',
  onTripQuantity: 'warning'
}

export const mapOfRemovalsMetrics: { [key: string]: string } = {
  total: 'Total',
  returnedQuantity: 'Devuelta',
  programmedQuantity: 'Programada',
  authorizedQuantity: 'Autorizada',
  onTripQuantity: 'En viaje'
}
