import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { NavItem, NavSelectionType, navItems } from '../constants'
import styles from './Nav.module.scss'
import clsx from 'clsx'
import { Tooltip } from 'antd'
import { ArrowForward, CaretDown, Logout } from '../../../icons'
import { useFeatureManager } from '../../featureManager/utils'
import { FeatureType } from '../../featureManager/types'
import { Permissions } from '../../UserLogged/permissions'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'
import { useSelector } from 'react-redux'

type NavProps = {
  email: string | null
  onLogout: () => void
  hidden: boolean
  pathname: string
}

const checkPermissionItem = (permissions: Permissions[], item: NavItem): boolean =>
  // Should show item if is not have permission assigned neither subitems
  (!item.permission && !item.subitems) ||
  // If it has permissions, should show it if the user has the specific permission
  (item.permission && permissions.includes(item.permission)) ||
  // If it has subitems, it will check if at least one of the subitems meets some of the above rules
  item.subitems?.some((subItem) => checkPermissionItem(permissions, subItem)) ||
  false

const Nav: React.FC<NavProps> = ({ email, onLogout, hidden, pathname }) => {
  const [expanded, setExpanded] = useState(false)
  const [openItem, setOpenItem] = useState<string | null>(null)
  const [hiddenSection, setHiddenSection] = useState<string[]>([])
  const { role } = useSelector((state) => state.UserLogged.userProfile)
  const permissions = usePermissions()
  const parentId = localStorage.getItem('parent-id')

  const checkPermissionsItemCb: (nf: NavItem) => boolean = useCallback(
    (navItem: NavItem) => checkPermissionItem(permissions, navItem),
    [permissions]
  )

  useEffect(() => {
    setOpenItem(null)
  }, [expanded])

  const navItemList = useMemo(() => {
    const CorporativeId = 18
    const isCorporative = role?.id === CorporativeId

    return navItems({ corporative: Boolean(parentId) || isCorporative })
  }, [parentId, role?.id])

  const salesRequest = useFeatureManager(FeatureType.SEE_SALES_REQUEST_TABLE)
  const development = process.env?.REACT_APP_ENVIRONMENT === 'development'

  useEffect(() => {
    if (!salesRequest) setHiddenSection([...hiddenSection, '/salesRequest'])
    else {
      setHiddenSection(hiddenSection.filter((s) => s !== '/salesRequest'))
    }
  }, [salesRequest])

  useEffect(() => {
    if (!development) setHiddenSection([...hiddenSection, '/development'])
    else {
      setHiddenSection(hiddenSection.filter((s) => s !== '/development'))
    }
  }, [development])

  useEffect(() => {
    if (role && role.name !== 'Root') setHiddenSection([...hiddenSection, '/dashboard'])
    else {
      setHiddenSection(hiddenSection.filter((s) => s !== '/dashboard'))
    }
  }, [role])

  return (
    <nav className={clsx(styles.nav, expanded && styles.expanded, hidden && styles.hidden)}>
      <ul className={styles.links}>
        {navItemList
          .filter((item) => !hiddenSection.includes(item.url || '') && checkPermissionsItemCb(item))
          .map((item) => (
            <Item
              key={item.name}
              item={item}
              expanded={expanded}
              activePathname={pathname}
              open={openItem === item.name}
              setItemOpen={setOpenItem}
              checkPermissionsItemCb={checkPermissionsItemCb}
            />
          ))}
      </ul>
      <div className={styles.logout}>
        <span>{email}</span>
        <button id="btn-logout" name="btn-logout" onClick={onLogout}>
          Cerrar sesión
          <i>
            <Logout />
          </i>
        </button>
      </div>
      <button
        id="toggle-drawer"
        name="toggle-drawer"
        className={clsx(styles.expander, expanded && styles.expanded)}
        onClick={() => setExpanded(!expanded)}>
        <i>
          <ArrowForward />
        </i>
      </button>
    </nav>
  )
}

const getItemOrSubitemLabel = ({
  item,
  expanded,
  setItemOpen,
  open,
  activePathname
}: {
  item: NavItem
  expanded?: boolean
  setItemOpen?: (itemUrl: string | null) => void
  open?: boolean
  activePathname?: string
}) => {
  const selectedSubitem = item.subitems?.find((si) => si.url === activePathname)
  const SvgIcon = item.svg
  return (
    <div className={styles.itemLabel} onClick={() => setItemOpen && setItemOpen(open ? null : item.name)}>
      {item.svg ? (
        <i>
          {/* @ts-ignore */}
          <SvgIcon />
        </i>
      ) : (
        <i className={item.icon} />
      )}
      <span className={styles.itemName}>{item.name}</span>
      {item.subitems &&
        (expanded ? (
          <CaretDown className={clsx(styles.arrow, open && styles.arrowOpen, expanded && styles.arrowExpanded)} />
        ) : (
          <div className={clsx(styles.hasSubitem, selectedSubitem && styles.hasSelectedSubitem)} />
        ))}
    </div>
  )
}

const itemOrSubItemIsSelected = (item: NavItem, activePathname: string): NavSelectionType => {
  if (item.url && activePathname === item.url) return NavSelectionType.SELECTED
  if (item.subitems && item.subitems?.find((si) => si.url === activePathname))
    return NavSelectionType.HAS_SELECTED_SUBITEM

  return NavSelectionType.NOT_SELECTED
}

const Item: React.FC<{
  item: NavItem
  expanded: boolean
  activePathname: string
  open: boolean
  setItemOpen: (itemUrl: string | null) => void
  checkPermissionsItemCb: (navBar: NavItem) => boolean
}> = ({ item, expanded, activePathname, open, setItemOpen, checkPermissionsItemCb }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const itemIsLink = !!item.url

  useEffect(() => {
    setTooltipVisible(false)
  }, [expanded])

  const label = getItemOrSubitemLabel({ item, expanded, open, activePathname, setItemOpen })

  const topLevelItem = itemIsLink ? <NavLink to={item.url}>{label}</NavLink> : label

  const visibleSubitems = useMemo(
    () => item.subitems?.filter((subitem) => checkPermissionsItemCb(subitem)),
    [checkPermissionsItemCb, item.subitems]
  )

  const subitems = visibleSubitems && (
    <>
      <ul className={styles.subitemList}>
        {visibleSubitems.map(
          (subitem) =>
            subitem.url && (
              <li
                key={subitem.url}
                className={clsx(styles.subitem, styles[itemOrSubItemIsSelected(subitem, activePathname)])}>
                <NavLink to={subitem.url} key={subitem.url}>
                  {getItemOrSubitemLabel({ item: subitem })}
                </NavLink>
              </li>
            )
        )}
      </ul>
      {!expanded && <h4>{item.name}</h4>}
    </>
  )

  const handleTooltipVisibility = (tooltipInnerVisible: boolean) => {
    setTooltipVisible(!expanded && tooltipInnerVisible)
    if (!tooltipInnerVisible) setItemOpen(null)
  }

  return (
    <li
      className={clsx(
        styles.topLevelItem,
        tooltipVisible && styles.itemTooltipOpen,
        open && styles.open,
        styles[itemOrSubItemIsSelected(item, activePathname)]
      )}>
      <Tooltip
        title={itemIsLink ? item.name : <div className={styles.tooltipSubitems}>{subitems}</div>}
        placement={'rightTop'}
        visible={!expanded && tooltipVisible}
        mouseEnterDelay={0.02}
        mouseLeaveDelay={0.02}
        onVisibleChange={(visible) => handleTooltipVisibility(visible)}>
        {topLevelItem}
      </Tooltip>
      {expanded && subitems && (
        <div className={styles.expandedSubitems} style={{ maxHeight: open ? 340 : 0, paddingTop: open ? 6 : 0 }}>
          {subitems}
        </div>
      )}
    </li>
  )
}

export default Nav
