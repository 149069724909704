import React from 'react'
import styles from './UserTag.module.scss'
import { MailLine, UserLine } from '@/icons'
import ColorBadge from '@/components/ColorBadge'
import { useSelector } from 'react-redux'

export const UserTag = () => {
  const user = localStorage.getItem('name')
  const email = localStorage.getItem('email')
  const { role } = useSelector((state) => state.UserLogged.userProfile)

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          <p className={styles.nameContainer}>
            <span className={styles.name}>
              <UserLine />
              {user}
            </span>
            <ColorBadge backgroundColor={'#F2F2F2'} borderColor={'#4D4D4D'} extraStyles={{ minWidth: 60 }}>
              {role?.name}
            </ColorBadge>
          </p>
          <p className={styles.email}>
            <MailLine />
            {email}
          </p>
        </div>
      </div>
    </div>
  )
}
